//Created By BN 11/22/21


import React, {useState} from 'react';
import {Button, Modal, ModalHeader, ModalBody, ModalFooter, FormGroup, Label, Input, CustomInput} from 'reactstrap';
import { getUserADAccount, getRandomInt, FormatDate } from '../../resources/utility'
import Datetime from 'react-datetime';
import moment from "moment";
import Select from 'react-select';
import { IgrDataGrid, IgrTextColumn, IgrTemplateColumn } from 'igniteui-react-grids';
const WODuplicateModal = (props) => {


 

    const {
        outline,
        color,
        className,
        formName,
        message,
        hidden,
        disabled,
        buttonLabel,
        isOpen,
        toggle,
        woDataList,
        onClickYes,
      } = props;

    const [modal, setModal] = useState(false);
    
    // const toggle = () => {
    //     setModal(!modal);
    //      props.onClickNo()
        
    // }

    // const toggleNo = () => {
    //     // setModal(! modal);
    //     props.onClickNo()
    // }

    const toggleYes = () => {
        // setModal(!modal)
        props.onClickYes();
    }

 

    const onClosed = () => {

        if (props.onClosed!==undefined)
        {
            props.onClosed();
        }        
    }

  

    const onChange = (e) =>{
        props.onChangeTest(e);
    }

    const onChange2 = (fieldValue, e) =>{ //Commented out by BN 7/11
        props.onChangeTest2(moment(fieldValue));
    }
    const onChange3 = (e) =>{ //Commented out by BN 7/11
        props.onChangeTest3(e);
    }

    const onChange4 = (e) =>{ //Commented out by BN 7/11
        props.onChangeTest4(e);
    }



    return (
        <div>
    
          <Modal isOpen={isOpen} toggle={toggle} style={{ maxWidth: "90%", margin: "0 auto" }}>
            <ModalHeader toggle={toggle}>{formName}</ModalHeader>
                <ModalBody style={{ maxHeight: "80vh", overflowY: "auto" }}>
                    <FormGroup>
                    <div
                            style={{
                            backgroundColor: "#f8d7da", // Light red background
                            color: "#721c24",          // Dark red text
                            border: "1px solid #f5c6cb", // Red border
                            padding: "15px",
                            marginBottom: "20px",
                            borderRadius: "5px",
                            fontWeight: "bold",
                            fontSize: "16px",
                            textAlign: "center",
                            }}
                        >
                            {message}
                    </div>                    
                    <IgrDataGrid
                    width="100%"
                    height="60vh"
                    autoGenerateColumns={false}
                    dataSource={woDataList}
                    selectionMode="SingleRow"
                    rowHeight={25}
                    editMode={0}
                    isColumnOptionsEnabled={true}
                    isVerticalScrollEnabled={true}
                    rowLoadingIndicatorAnimationMode="fadeIn"
                    defaultColumnMinWidth={100}
                    columnVirtualizationMode="deferred"
                    rowVirtualizationMode="deferred"
                    >
                    <IgrTextColumn
                        field="woNumber"
                        headerText="WO No"
                        width="*>120"
                        // cellUpdating={this.onStatusCellUpdating}
                      />
                      <IgrTextColumn
                        field="fiixWOStatus"
                        headerText="Status"
                        width="*>200"
                        // cellUpdating={this.onStatusCellUpdating}
                      />

                      <IgrTextColumn
                        field="assetName"
                        headerText="Asset"
                        width="*>200"
                        // cellUpdating={this.onStatusCellUpdating}
                      />

                      <IgrTextColumn
                        field="qrCodeValue"
                        headerText="QR Code"
                        width="*>170"
                        // cellUpdating={this.onStatusCellUpdating}
                      />
                      <IgrTextColumn
                        field="facilityName"
                        headerText="Facility"
                        width="*>170"
                        // cellUpdating={this.onStatusCellUpdating}
                      />

                      <IgrTextColumn
                        field="woPriority"
                        headerText="Priority"
                        width="*>200"
                        // cellUpdating={this.onStatusCellUpdating}
                      />

                      <IgrTemplateColumn
                        field="maintenanceType"
                        headerText="Maintenance Type"
                        width="*>250"
                        cellUpdating={onStatusCellUpdating}
                      />

                      <IgrTextColumn
                        field="fiixIssueType"
                        headerText="Issue Type"
                        width="*>250"
                        // cellUpdating={this.onStatusCellUpdating}
                      />
                      <IgrTextColumn
                        field="workOrderDesc"
                        headerText="Description"
                        width="*>400"
                        // cellUpdating={this.onStatusCellUpdating}
                      />

                      <IgrTextColumn
                        field="assetID"
                        headerText="Asset ID"
                        width="*>170"
                        // cellUpdating={this.onStatusCellUpdating}
                      />

                      <IgrTextColumn
                        field="createdByName"
                        headerText="Requested By"
                        width="*>150"
                        // cellUpdating={this.onStatusCellUpdating}
                      />
                      <IgrTemplateColumn
                        field="reqestDateTime"
                        headerText="Request Date"
                        width="*>200"
                        cellUpdating={onStatusCellUpdating}
                      />
                      <IgrTemplateColumn
                        field="modifiedBy"
                        headerText="Modified By"
                        width="*>150"
                        cellUpdating={onStatusCellUpdating}
                      />
                      <IgrTemplateColumn
                        field="modifiedDateTime"
                        headerText="Modified Date"
                        width="*>200"
                        cellUpdating={onStatusCellUpdating}
                      />
                      <IgrTextColumn
                        field="assignedToUserName"
                        headerText="Assigned To"
                        width="*>150"
                        // cellUpdating={this.onStatusCellUpdating}
                      />
                      <IgrTextColumn
                        field="locationID"
                        headerText="Location ID"
                        width="*>150"
                        // cellUpdating={this.onStatusCellUpdating}
                      />

                      <IgrTextColumn
                        field="locationName"
                        headerText="Location"
                        width="*>170"
                        // cellUpdating={this.onStatusCellUpdating}
                      />
                      <IgrTextColumn
                        field="workdaySiteID"
                        headerText="Workday Site ID"
                        width="*>150"
                        // cellUpdating={this.onStatusCellUpdating}
                      />  
                      </IgrDataGrid>
                    </FormGroup>
                </ModalBody>
            <ModalFooter>
              <Button color="primary" onClick={onClickYes}>
                Yes
              </Button>
              <Button color="secondary" onClick={toggle}>
                No
              </Button>
            </ModalFooter>
          </Modal>
        </div>

    ) 
    

}

// Function to calculate luminance of a color
const getLuminance = (color) => {
    const rgb = color.match(/\w\w/g).map((c) => parseInt(c, 16) / 255);
    const luminance = rgb.map((c) => {
        return c <= 0.03928 ? c / 12.92 : Math.pow((c + 0.055) / 1.055, 2.4);
    }).reduce((a, b, i) => a + b * [0.2126, 0.7152, 0.0722][i], 0);
    return luminance;
  }
  
  // Define a function to get the color based on maintenance type
const  getColorForMaintenanceType = (maintenanceType) => {
    switch (maintenanceType) {
        case 'Fleet (Truck)':
            return '#1B65C4'; // Blue
        case 'Other (Valves, Fittings etc.)':
            return '#CC4140'; // Red
        case 'Cameras':
            return '#A2E09D'; // Light Green
        case 'Safety (QHSE)':
            return '#FF9900'; // Orange
        case 'Pumps':
            return '#1E8A0E'; // Green
        case 'I&E':
            return '#D2CA4E'; // Yellow
        case 'Tower Comms':
            return '#967855'; // Brown
        case 'Pipeline':
            return '#053A78'; // Dark Blue
        case 'Measurement':
            return '#386161'; // Teal
        case 'SCADA':
            return '#D36E87'; // Pink
        case 'Materials Management':
            return '#363636'; // Dark Gray
        case 'Tanks':
            return '#B2F0F7'; // Light Blue
        case 'VFD':
            return '#FFFFFF'; // White
        case 'Lightning Inspection':
            return '#FFFBC2'; // Light Yellow
        case 'Valve':
            return '#8F3499'; // Purple
        case 'Heavy Equipment':
            return '#694A0D'; // Dark Brown
        default:
            return 'transparent'; // Default color
    }
  }


const onStatusCellUpdating = (s, e) => {
    const content = e.content;
    const info = e.cellInfo;
    const item = info.rowItem;
  
    let link;
    if (content.childElementCount === 0) {
        link = document.createElement("label");
        content.style.fontFamily = "Verdana";
        content.style.fontSize = "13px";
        content.style.margin = "0px -20px -10px -12px";
        content.style.padding = "0px 10px 10px 10px";
        content.appendChild(link);
    } else {
        link = content.children[0];
        content.style.background = "transparent";
        if (s.field !== 'reqestDateTime' && s.field !== "modifiedBy" && s.field !=="modifiedDateTime") {
            const backgroundColor = getColorForMaintenanceType(item.maintenanceType);
            content.style.background = backgroundColor;
            const luminance = getLuminance(backgroundColor);
            if (luminance > 0.5) {
                link.style.color = 'black'; // Dark text on light background
            } else {
                link.style.color = 'white'; // Light text on dark background
            }
        }
    }
  
    switch (s.field) {
        case 'woNumber':
            link.textContent = item.woNumber;
            break;
        case 'fiixWOStatus':
            link.textContent = item.fiixWOStatus;
            break;
        case 'assetName':
            link.textContent = item.assetName;
            break;
        case 'qrCodeValue':
            link.textContent = item.qrCodeValue;
            break;
        case 'facilityName':
            link.textContent = item.facilityName;
            break;
        case 'woPriority':
            link.textContent = item.woPriority;
            break;
        case 'maintenanceType':
            link.textContent = item.maintenanceType;
            break;
        case 'fiixIssueType':
            link.textContent = item.fiixIssueType;
            break;
        case 'workOrderDesc':
            link.textContent = item.workOrderDesc;
            break;
        case 'assetID':
            link.textContent = item.assetID;
            break;
        case 'createdByName':
            link.textContent = item.createdByName;
            break;
        case 'modifiedBy':
          link.textContent = item.modifiedBy
          ? item.modifiedBy.replace(".", " ")
          : "";
            break;    
        case 'modifiedDateTime':
      if (item.modifiedDateTime) {
          var date = new Date(item.modifiedDateTime);
          link.textContent = `${date.toLocaleString("en-US", {
              timeZone: "America/Chicago",
          })}`;
      } else {
          link.textContent = "";
      }
      break;       
      case 'reqestDateTime':
        if (item.reqestDateTime) {
            var date = new Date(item.reqestDateTime);
            link.textContent = `${date.toLocaleString("en-US", {
                timeZone: "America/Chicago",
            })}`;
        } else {
            link.textContent = "";
        } 
        break;
        case 'locationID':
            link.textContent = item.locationID;
            break;
        case 'assignedToUserName':
            link.textContent = item.assignedToUserName;
            break;
        case 'locationName':
            link.textContent = item.locationName;
            break;
        case 'workdaySiteID':
            link.textContent = item.workdaySiteID;
            break;
        case 'supplierID':
            link.textContent = item.supplierID;
            break;
        case 'estimateCost':
            link.textContent = item.estimateCost;
            break;
        default:
    }
  }

export default WODuplicateModal;