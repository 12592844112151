//by dn 12/07/2021 Accounting-ogenergy, safety incident - first form, spill 

import React, { Component } from 'react';
//import {Route} from 'react-router';
import { HashRouter, Route } from 'react-router-dom';

import { Layout } from './components/Layout';
import { Home } from './components/Home';
import FacilityInspection from './operation/FacilityInspection';
import InspectionDetail from './operation/InspectionDetail';
import UserAdmin from './administrator/UserAdmin';
import UserGroupAdmin from './administrator/UserGroupAdmin';
import SecurityCheckpointAdmin from './administrator/SecurityCheckpointAdmin';
import TankVolume from './operation/TankVolume';
import EventDetail from './operation/EventDetail';
import FacilityTank from './operation/FacilityTank';
import FacilityCost from './operation/FacilityCost';
import FacilityOilTicket from './operation/FacilityOilTicket';
import FacilityEvent from './operation/FacilityEvent';
import Facility from './operation/Facility';
import FacilityMeter from './operation/FacilityMeter';
import OperatorDashboard from './operation/OperatorDashboard';
import MeterVolume from './operation/MeterVolume';
import MeterValidation from './operation/MeterValidation';
import InspectionDashboard from './operation/InspectionDashBoard';
import SafetyObservation from './qhse/SafetyObservation';
import StandardProcedure from './qhse/StandardProcedure';
import SafetyIncident from './qhse/SafetyIncident';
import SafetyIncJSA from './qhse/SafetyIncJSA';
import JIB from './finance/JibHeader';

import ErgonomicEvaluationRequest from './qhse/safetyIncident/ErgonomicEvaluationRequest';
import ItemMaster from './supplychain/ItemMaster';
import Warehouse from './supplychain/Warehouse';
import InventoryLoc from './supplychain/InventoryLoc';
import Receiver from './supplychain/Receiver';
import PurchaseOrder from './supplychain/PurchaseOrder';
import Supplier from './supplychain/Supplier';
import MaterialRequest from './supplychain/MaterialRequest'
import ItemInventory from './supplychain/ItemInventory'
import MaterialMovement from './supplychain/MaterialMovement'

import UserDetails from './administrator/UserDetails'

import ErrorBoundary from './components/ErrorBoundary'

import SafetyObservationDetail from './qhse/SafetyObservationDetail';

import MeterValidationDetail from './operation/MeterValidationDetail';

import Inventory from './operation/Inventory'

import './custom.css';
// import FormFirstReport from './qhse/FormFirstReport';
// import FormSpillReport from './qhse/FormSpillReport';
import SafetyIncidentHeader from './qhse/safetyIncident/SafetyIncidentHeader';

import OGEnergyLink from './accounting/OGEnergyLink';
import AFE from './operation/AFE'

//Created by BN - 2/3/22
import SecurityCheckpointHome from './administrator/SecurityCheckpointHome';
import SecurityCheckpointListView from './administrator/SecurityCheckpointListView'

import Riser from './operation/Riser'

import MaterialReturn from './supplychain/MaterialReturn'
import SWIMSDashboard from './supplychain/SWIMSDashboard'

//Add by HN 07/06/2022
import Workorder from './operation/Workorder/Workorder'

//Add by HN 11/09/2022
import ReclamInvoiceBatch from './finance/ReclamInvoiceBatch'
import InvoiceHeader from './finance/desert/InvoiceHeader';

//Add by HN 12/08/2022
import SupplierRequest from './accounting/SupplierRequest'


//Add by HN 08/23/2023
import PORequest from './supplychain/PurchaseOrder/PORequest'

//Add by BN 222112
import WOHome from './operation/Workorder/WOHome'



import MOC from './operation/MOC/MOC'

import About from './administrator/About';

//Added by HN 20230513
import { Asset } from './operation/Asset/Asset';
import AssetMap from './operation/Asset/AssetMap';

//Added by HN 05/17/2024
import LossOfUtilityPowerProcedureDetails from './operation/UtilityPowerProcedure/LossOfUtilityPowerProcedureDetails'

import ConstructionReporting from './constructionReporting/ConstructionReporting';

//Added by HN
import '../node_modules/@syncfusion/ej2-base/styles/material.css';
import '../node_modules/@syncfusion/ej2-icons/styles/material.css';
import '../node_modules/@syncfusion/ej2-inputs/styles/material.css';
import '../node_modules/@syncfusion/ej2-popups/styles/material.css';
import '../node_modules/@syncfusion/ej2-buttons/styles/material.css';
import "../node_modules/@syncfusion/ej2-calendars/styles/material.css";
import '../node_modules/@syncfusion/ej2-splitbuttons/styles/material.css';
import '../node_modules/@syncfusion/ej2-navigations/styles/material.css';
import '../node_modules/@syncfusion/ej2-layouts/styles/material.css';
import '../node_modules/@syncfusion/ej2-grids/styles/material.css';
import "../node_modules/@syncfusion/ej2-lists/styles/material.css";
import "../node_modules/@syncfusion/ej2-dropdowns/styles/material.css";

import "../node_modules/@syncfusion/ej2-react-filemanager/styles/material.css";
import "../node_modules/@syncfusion/ej2-react-calendars/styles/material.css";
import "../node_modules/@syncfusion/ej2-querybuilder/styles/material.css";
import '../node_modules/@syncfusion/ej2-richtexteditor/styles/material.css';
import "../node_modules/@syncfusion/ej2-diagrams/styles/material.css";

import WOSpotfireHome from './operation/Workorder/WOSpotfireHome'



export default class App extends Component {
  static displayName = App.name;

  constructor(props) {
    super(props)

    this.state = {
      userAppModule: [],
      searchTextValue: ""
    }
  }

  onSearchTextChange = (v, e) => {
    //console.log("Text change in App: " + v)
    this.setState({ searchTextValue: v })
    window.$searchText = v
    //console.log("window.$searchText: " + window.$searchText )
  }

  render() {
    console.log("window.location.href")
    console.log(window.location)
    let environment = window.location.href.includes('https://portal.h2obridge.com/');
    let wbrDataCenterURL = environment ? 'https://wbr-datacenter.azurewebsites.net/#/' : 'https://wbr-datacenter-test.azurewebsites.net/#/';
    let urlOrigin = (window.location.origin.includes('localhost') ? 'https://portaldev.h2obridge.com' : window.location.origin)

    return (

      <HashRouter >
        <Layout user={this.props.user}
          onSearchTextChange={this.onSearchTextChange}>
          <Route exact path='/' render={(props) => <Home {...props} user={this.props.user} />} />
          <Route path='/facility-insp' render={(props) => <FacilityInspection {...props}
            user={this.props.user} />} />
          <Route path='/facility-insp-dtl' render={(props) => <InspectionDetail {...props} user={this.props.user} />} />

          {/* <Route path='/meter-val' render={(props) => <MeterValidationDetail {...props} user={this.props.user} />} /> */}

          <Route path='/facility' render={(props) => <Facility {...props} user={this.props.user} />} />
          {/* Created by BN 9/9/21 */}
          <ErrorBoundary>
            <Route path='/users' render={(props) => <UserAdmin {...props} user={this.props.user} />} />

          </ErrorBoundary>


          <ErrorBoundary>
            <Route path='/userGroups' render={(props) => <UserGroupAdmin {...props} user={this.props.user} />} />
          </ErrorBoundary>

          <ErrorBoundary>
            <Route path='/secCheck' render={(props) => <SecurityCheckpointAdmin {...props} user={this.props.user} />} />
          </ErrorBoundary>

          <ErrorBoundary>
            <Route path='/secCheckHome' render={(props) => <SecurityCheckpointHome {...props} user={this.props.user} />} />
          </ErrorBoundary>
          {/* 
                <ErrorBoundary>
                <Route path='/secCheckListView' render={(props) => <SecurityCheckpointListView {...props} user={this.props.user} />} />
                </ErrorBoundary> */}


          <Route path='/userDetails' render={(props) => <UserDetails {...props} user={this.props.user} />} />
          {/* <Route path='/userGroups' component={UserGroupAdmin} /> */}
          {/* <Route path='/secCheck' component={SecurityCheckpointAdmin} /> */}
          <Route path='/tankVol' render={(props) => <TankVolume {...props} user={this.props.user} />} />
          <Route path='/facility-event-dtl/:Key' component={EventDetail} />
          <Route path='/facility-tank' render={(props) => <FacilityTank {...props} user={this.props.user} />} />
          <Route path='/facility-cost' render={(props) => <FacilityCost {...props} user={this.props.user} />} />
          <Route path='/facility-oil' render={(props) => <FacilityOilTicket {...props} user={this.props.user} />} />
          <Route path='/facility-event' render={(props) => <FacilityEvent {...props} user={this.props.user} />} />
          <Route path='/facility-meter' render={(props) => <FacilityMeter {...props} user={this.props.user} />} />
          <Route path='/operator-dashboard' render={(props) => <OperatorDashboard {...props} user={this.props.user} />} />
          <Route path='/safety-obs' render={(props) => <SafetyObservation {...props} user={this.props.user} />} />
          <Route path='/safety-obs-dtl' render={(props) => <SafetyObservationDetail {...props} user={this.props.user} />} />
          <Route path='/SafetyIncident' render={(props) => <SafetyIncident {...props} user={this.props.user} />} />
          <Route path='/SafetyIncJSA' render={(props) => <SafetyIncJSA {...props} user={this.props.user} />} />
          <Route path='/SafetyIncidentHeader' render={(props) => <SafetyIncidentHeader {...props} user={this.props.user} />} />
          <Route path='/ErgonomicEvaluationRequest' render={(props) => <ErgonomicEvaluationRequest {...props} user={this.props.user} />} />


          <Route path='/meter-vol' render={(props) => <MeterVolume {...props} user={this.props.user} />} />
          <Route path='/meter-val' render={(props) => <MeterValidation {...props} user={this.props.user} />} />
          {/* Created by BN 10/25/21 */}
          <Route path='/meter-val-dtl' render={(props) => <MeterValidationDetail {...props} user={this.props.user} />} />


          <Route path='/std-Proc' render={(props) => <StandardProcedure {...props} user={this.props.user} />} />
          <Route path='/OGEnergyLink' render={(props) => <OGEnergyLink {...props} user={this.props.user} />} />

          {/*  added by HN 12/02/2021*/}
          <Route path='/insp-dashboard' render={(props) => <InspectionDashboard {...props} user={this.props.user} />} />
          <Route path='/itemmaster' render={(props) => <ItemMaster {...props} user={this.props.user} />} />
          <Route path='/materialrequest' render={(props) => <MaterialRequest {...props} user={this.props.user} />} />
          <Route path='/iteminventory' render={(props) => <ItemInventory {...props} user={this.props.user} />} />
          <Route path='/materialmovement' render={(props) => <MaterialMovement {...props} user={this.props.user} />} />


          <Route path='/warehouse' render={(props) => <Warehouse {...props} user={this.props.user} />} />
          <Route path='/invLocation' render={(props) => <InventoryLoc {...props} user={this.props.user} />} />
          <Route path='/receiver' render={(props) => <Receiver {...props} user={this.props.user} />} />
          <Route path='/po' render={(props) => <PurchaseOrder {...props} user={this.props.user} />} />
          <Route path='/supplier' render={(props) => <Supplier {...props} user={this.props.user} />} />
          <Route path='/afe' render={(props) => <AFE {...props} user={this.props.user} />} />


          {/* end */}
          {/* Added by Hau Nguyen 11/18/21  */}
          <Route path='/apticket/Statements' component={() => { window.location.href = urlOrigin + '/apticket/App/Statements'; return null; }} />
          <Route path='/apticket' component={() => { window.location.href = urlOrigin + '/apticket'; return null; }} />
          <Route path='/apticket/Duplicates' component={() => { window.location.href = urlOrigin + '/apticket/App/Duplicates'; return null; }} />

          <Route path='/ReclamInvoiceBatch' render={(props) => <ReclamInvoiceBatch {...props} user={this.props.user} />} />
          <Route path='/invoiceHeader' render={(props) => <InvoiceHeader {...props} user={this.props.user} invoiceBatchKey={-1} />} />


          <Route path='/facility' component={() => { window.location.href = wbrDataCenterURL + 'facility'; return null; }} />
          <Route path='/facility-meter' component={() => { window.location.href = wbrDataCenterURL + 'meters'; return null; }} />
          {/* --------------------End----------------------- */}

          {/* Created By BN 12/7/21 */}
          <Route path='/inventory' render={(props) => <Inventory {...props} user={this.props.user} />} />

          {/* Created by BN 3/22/22 */}
          <Route path='/riser' render={(props) => <Riser {...props} user={this.props.user} />} />

          {/* Created by BN 4/04/22 */}
          <Route path='/materialreturn' render={(props) => <MaterialReturn {...props} user={this.props.user} />} />

          {/* Created by HN 4/31/22 */}
          <Route path='/swims-dashboard' render={(props) => <SWIMSDashboard {...props} user={this.props.user} />} />

          {/* Created by HN 7/06/22 */}
          <Route path='/workorder' render={(props) => <Workorder {...props} user={this.props.user} />} />

          {/* Created by HN 12/08/2022 */}
          <Route path='/supplierrequest' render={(props) => <SupplierRequest {...props} user={this.props.user} />} />

          <Route path='/jibHeader' render={(props) => <JIB {...props} user={this.props.user} />} />



          {/* Created by BN 08/23/23 */}
          <Route path='/porequest' render={(props) => <PORequest {...props} user={this.props.user} />} />

          {/*Created by BN 12/21/22 */}
          <Route path='/WOHome' render={(props) => <WOHome {...props} user={this.props.user} />} />

          {/*Created by BN 5/10/24 */}
          <Route path='/WOSpotfireHome' render={(props) => <WOSpotfireHome {...props} user={this.props.user} />} />



          {/*Created by BN 3/01/23 */}
          <Route path='/moc' render={(props) => <MOC {...props} user={this.props.user} />} />

          <Route path='/About' render={(props) => <About {...props} user={this.props.user} />} />


          {/**created by HN 05/05/2023 */}
          <Route path='/Pond' render={(props) => <Asset {...props}
            user={this.props.user}
            companyID="WBR"
            assetCatID="OP"
            assetTypeID="PND" />} />

          <Route path='/PondLvl' render={(props) => <Asset {...props}
            user={this.props.user}
            companyID="WBR"
            assetCatID="OP"
            assetTypeID="PNDLVL" />} />

          <Route path='/pondDepth' render={(props) => <Asset {...props}
            user={this.props.user}
            companyID="WBR"
            assetCatID="OP"
            assetTypeID="PNDDPTH" />} />

          <Route path='/Wellbore' render={(props) => <Asset {...props}
            user={this.props.user}
            companyID="WBR"
            assetCatID="OP"

            //assetTypeID="FAC_INSP_LI" 
            assetTypeID="WBP"

          />} />

          <Route path='/facility-lighting-insp' render={(props) => <Asset {...props}
            user={this.props.user}
            companyID="WBR"
            assetCatID="OP"
            assetTypeID="FAC_INSP_LI"

          />} />


          <Route path='/asset-map' render={(props) => <AssetMap {...props}
            user={this.props.user}
            companyID="WBR"
            assetCatID="OP"
            assetTypeID="ALL" />} />

          <Route path='/asset-all' render={(props) => <Asset {...props}
            user={this.props.user}
            companyID="WBR"
            assetCatID="OP"
            assetTypeID="ALL" />} />


          <Route path='/PreferSup' render={(props) => <Asset {...props}
            user={this.props.user}
            companyID="WBR"
            assetCatID="SC"
            assetTypeID="PSL" />} />

          <Route path='/EquipRental' render={(props) => <Asset {...props}
            user={this.props.user}
            companyID="WBR"
            assetCatID="SC"
            assetTypeID="ERT" />} />

          <Route path='/divreport' render={(props) => <Asset {...props}
            user={this.props.user}
            companyID="WBR"
            assetCatID="OP"
            assetTypeID="DVI" />} />

          <Route path='/qhse-sop' render={(props) => <Asset {...props}
            user={this.props.user}
            companyID="WBR"
            assetCatID="OP"
            assetTypeID="SOP" />} />

          <Route path='/pfbchklist' render={(props) => <Asset {...props}
            user={this.props.user}
            companyID="WBR"
            assetCatID="OP"
            assetTypeID="PFB" />} />


          <Route path='/pop' render={(props) => <Asset {...props}
            user={this.props.user}
            companyID="WBR"
            assetCatID="OP"
            assetTypeID="POP" />} />

          <Route path='/pts' render={(props) => <Asset {...props}
            user={this.props.user}
            companyID="WBR"
            assetCatID="OP"
            assetTypeID="PTS" />} />

          <Route path='/pondwqt' render={(props) => <Asset {...props}
            user={this.props.user}
            companyID="WBR"
            assetCatID="OP"
            assetTypeID="PND_WQT" />} />
          <Route path='/fieldhousing' render={(props) => <Asset {...props}
            user={this.props.user}
            companyID="WBR"
            assetCatID="SC"
            assetTypeID="HSN" />} />
          <Route path='/wtc' render={(props) => <Asset {...props}
            user={this.props.user}
            companyID="WBR"
            assetCatID="OP"
            assetTypeID="WTC" />} />
          <Route path='/pondcinv' render={(props) => <Asset {...props}
            user={this.props.user}
            companyID="WBR"
            assetCatID="OP"
            assetTypeID="PND_CINV" />} />
          <Route path='/pondjob' render={(props) => <Asset {...props}
            user={this.props.user}
            companyID="WBR"
            assetCatID="OP"
            assetTypeID="PND_JOB" />} />

          <Route path='/customercontract' render={(props) => <Asset {...props}
            user={this.props.user}
            companyID="WBR"
            assetCatID="AR"
            assetTypeID="CTR" />} />

          <Route path='/LossOfUtilityPowerProcedure' render={(props) => <Asset {...props}
            user={this.props.user}
            companyID="WBR"
            assetCatID="OP"
            assetTypeID="LPP" />} />

          <Route path='/FacilityLPF' render={(props) => <Asset {...props}
            user={this.props.user}
            companyID="WBR"
            assetCatID="OP"
            assetTypeID="LPF" />} />

          <Route path='/cominsp' render={(props) => <Asset {...props}
            user={this.props.user}
            companyID="WBR"
            assetCatID="OP"
            assetTypeID="CTI" />} />

          <Route path='/pcnt' render={(props) => <Asset {...props}
            user={this.props.user}
            companyID="WBR"
            assetCatID="OP"
            assetTypeID="PCT" />} />

          <Route path='/btk' render={(props) => <Asset {...props}
            user={this.props.user}
            companyID="WBR"
            assetCatID="OP"
            assetTypeID="BTK" />} />

          <Route path='/pni' render={(props) => <Asset {...props}
            user={this.props.user}
            companyID="WBR"
            assetCatID="OP"
            assetTypeID="PNI" />} />

          <Route path='/fqi' render={(props) => <Asset {...props}
            user={this.props.user}
            companyID="WBR"
            assetCatID="OP"
            assetTypeID="FQI" />} />

          <Route path='/insp3li' render={(props) => <Asset {...props}
            user={this.props.user}
            companyID="WBR"
            assetCatID="OP"
            assetTypeID="FAC_INSP3LI" />} />


          <Route path='/jobProfile' render={(props) => <Asset {...props}
            user={this.props.user}
            companyID="WBR"
            assetCatID="IT"
            assetTypeID="EPF" />} />

          <Route path='/lcn' render={(props) => <Asset {...props}
            user={this.props.user}
            companyID="WBR"
            assetCatID="OP"
            assetTypeID="LCN" />} />

          <Route path='/bdt' render={(props) => <Asset {...props}
            user={this.props.user}
            companyID="WBR"
            assetCatID="OP"
            assetTypeID="BDT" />} />




          {/* <Route path='/LossOfUtilityPowerProcedure'
            render={(props) => <LossOfUtilityPowerProcedureDetails {...props}
              user={this.props.user} />} /> */}




          <Route path='/emailnotificationmanager' component={() => { window.location.href = urlOrigin + '/emailnotificationmanager'; return null; }} />



          {/* <div>
                  <pre>{JSON.stringify(this.props.user,null,2)}</pre>
                </div> */}

          <Route exact path='/constructionReporting' render={(props) => <ConstructionReporting {...props} user={this.props.user} />} />
        </Layout>
      </HashRouter>

    );
  }
}


