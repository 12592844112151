/*Modified by BN 9/13/24. Fix date conversion*/
import React, { Component } from 'react';
import { Modal, ModalHeader, ModalBody, ModalFooter, DropdownItem, DropdownMenu, ButtonDropdown, DropdownToggle } from 'reactstrap';
import {
  Form, Col, Row, Button, FormGroup, Label, Input, CustomInput,
  FormFeedback, InputGroup
} from 'reactstrap';
import { TabContent, TabPane, Nav, NavItem, NavLink } from 'reactstrap';
import classnames from 'classnames';
import { apiURL, apiHeader, apiPUTHeader, apiPOSTHeader, apiDELETEHeader } from '../../resources/apiURL';
import { SummaryOperand, SummaryCalculator, DefaultSummaryResult, IDataSource, ISummaryResult } from 'igniteui-react-core';
import NumberFormat from 'react-number-format';
import TrashBin from '../../resources/trash.svg'
import '../../custom.css';
import { getUserADAccount, getRandomInt, FormatDate } from '../../resources/utility'
//import ImageViewer from '../../resources/ImageViewer';
import ConfirmModal from '../../operation/ConfirmModal'
import ConfirmMeterValModal from '../../operation/ConfirmMeterValModal';
import ConfirmFiixWOComplete from './ConfirmFiixWOComplete';
import { QrReader } from 'react-qr-reader';
import DocumentBrowserDragDrop from '../../resources/DocumentBrowserDragDrop';
import Select from 'react-select';
import Creatable from 'react-select/creatable'
import moment from "moment";
import ConfirmWithMsgModal from "../../resources/ConfirmWithMsgModal";
import { IgrDataGrid } from 'igniteui-react-grids';
import { IgrColumnSummaryDescription } from 'igniteui-react-grids'
import { IgrDataGridModule } from 'igniteui-react-grids';
import { IgrTemplateColumn } from 'igniteui-react-grids';
import { IgrTextColumn } from 'igniteui-react-grids';
import { IgrNumericColumn } from 'igniteui-react-grids';
import DateTime from 'react-datetime';
import WOComment from './WOComment';
import WOApproval from './WOApproval'
import WOApprovalComment from './WOApprovalComment';
import ApproveConfirmationModal from './ApproveConfirmationModal';
import RerouteConfirmationModal from './RerouteConfirmationModal'
import WORejectModal from './WORejectModal';
import WOApprovalHistory from './WOApprovalHistory'
import WOConfirmModal from './WOConfirmModal';
import WOOnHoldModal from './WOOnHoldModal';
import WOCompleteModal from './WOCompleteModal';
import TaskAndInstructions from './TaskAndInstructions';
import WOWIPModal from './WOWIPModal';
import WODuplicateModal from './WODuplicateModal'
var DatePicker = require("reactstrap-date-picker");


IgrDataGridModule.register();

export default class WORequest extends Component {
  static displayName = WORequest.name;
  constructor(props) {
    super(props)
    this.onStatusCellUpdating = this.onStatusCellUpdating.bind(this);
    this.onGridRef = this.onGridRef.bind(this);
    this.onLoad = this.onLoad.bind(this);
    this.toggleDropDown = this.toggleDropDown.bind(this);
    this.toggleWorkInProgressModal = this.toggleWorkInProgressModal.bind(this);
    this.onGridRowSelected = this.onGridRowSelected.bind(this);
    this.onGridRowSelected2 = this.onGridRowSelected2.bind(this);
    const urlPara = this.getURLParameters(props.location);
    this.state = {
      readOnly: true, 
      facilityKey: props.facilityKey,
      alertMessage: "",
      showAlert: false,
      msgHeader: "",
      msgBody: "",
      modalMsg: false,
      user: props.user,
      workOrderKey: urlPara.workOrderKey === null ? props.workOrderKey : urlPara.workOrderKey,
      woData: [],
      isReadOnly: false,
      activeTab: '1',
      isWOLoaded: false,
      qrValue: props.qrValue,
      locationKey: props.locationKey,
      fiixWOStatus:props.fiixWOStatus,
      modalQRCodeScanner: false,
      offLineLinkKey: (getRandomInt(10000) * -1),
      isPriority: false,
      isPriorityListLoaded: false,
      priorityList: [],
      isAssetListLoaded: false,
      assetList: [],
      assetListDefault: [],
      isFacilityListLoaded: false,
      facilityList: [],
      facilityListDefault: [],
      isMainTypeListLoaded: false,
      mainTypeList: [],
      isBasinListLoaded: false,
      basinList: [],
      assetIDConvert: [],
      // completedUser: getUserADAccount(props.user.account.userName),
      completedUser: "",
      woStatus: "",
      comments: "",
      completedHours: null,
      completedDate: moment(new Date().toISOString()), //Commented out by BN 7/11
      // completedDate: centralDate,
      isDefaultDate: false,
      // completedDate: null,
      woComment: [],
      fiixWOId: props.fiixWOId,
      cmtDate: new Date().toISOString(),
      isWOCommentLoaded: false,
      isIssueTypeListLoaded: false,
      issueTypeList: [],
      issueTypeListFiltered: [],
      isMainTypeSelected: props.isMainTypeSelected,
      isSecurityAdminLogin: false,
      isSecurityLoaded: false,
      isApprover:false,
      maintenanceTypeID: props.maintenanceTypeID,
      empList: [],
      isEmpListLoaded: false,
      woNumber: urlPara.woNumber === null ? props.woNumber : urlPara.woNumber,
      isWOReportSummaryLoaded: false,
      woReportSummary: [],
      woTaskData: [],
      isWOTaskLoaded: false,
      isFacilityBool: false,
      woApproval: [],
      isApprovalLoaded: false,
      oldMaintenanceType: null,
      approveMaintenanceTypes: ['Cameras', 'Electrical', 'Tower Comms', 'Measurement', 'SCADA', 'VFD', 'Lightning Inspection'],
      dropdownOpen:false,
      isRerouteModalOpen: false,
      isApproveModalOpen: false,
      isRejectModalOpen: false,
      isMainTypeApprovalListLoaded: false,
      mainTypeApprovalList: [],
      selMaintenance: null,
      selBasin: null,
      selFacility: null,
      fiixAssignedToUserOld: "",
      approvalComments: "",
      isWIPModalOpen: false,
      isCancelModalOpen: false,
      isOnHoldModalOpen: false,
      isCompleteModalOpen:false,
      isClosedModalOpen: false,
      woLogHistory: [],
      isWOLogHistoryLoaded: false,
      modalHistoryLog:false,
      vendorsList: [],
      isVendorsListLoaded: false,
      partsAndCostData:[],
      // woUOMData:[],
      // isWOUOMLoaded: false,
      partsAndCostDataGrid:[],
      showValidationModal: false,
      isPartsAndCostModalOpen: false,
      partsAndCostKey: null,
      costTypeKey: null,
      hideComplete: false,
      isPartsAndCostStatusLoaded: false,
      partsAndCostStatus: [],
      // woCostType:[],
      // isWOCostTypeLoaded: false,
      validDataItem: {
        woPriority: true,
        assetID: true,
        facilityID: true,
        maintenanceType: true,
        workOrderDesc: true,
        locationKey: true,
        availStartDate: true,
        availEndDate: true,
        fiixIssueType: true,
      },
      switchBool: props.switchBool,
      invalidDataItem: {
        comments: true,
        fiixCompletedHours: true,
        fiixWODateCompleted: true,
        fiixWOCompletedByUser: true,
        completedVendorsKey: true,
        vendorCost: true,
        fiixIssueType: true,
        costTypeKey: true
      },
      woDataList: props.woDataList,
      isDuplicateWOModalOpen: false,
      woDuplicate: [],
      isWODuplicateLoaded: false
    }
  }

  onGridRef(grid, gridName) {
    if (!grid) {
      return;
    }
    // if(gridName === 'woReportSummary')
    // {
      
    //   this.woReportSummary = grid  
    //   this.woReportSummary.dataSource = this.state.woReportSummary;
    //   this.onLoad()
    // }
    if(gridName === 'partsAndCostData')
      {
        
        this.partsAndCostData = grid  
        this.partsAndCostData.dataSource = this.state.partsAndCostData;
        this.onLoad()
      }
    else if (gridName === 'woTaskData')
    {
        this.woTaskData = grid;
        this.woTaskData.dataSource = this.state.woTaskData;
    }
    else if (gridName === 'woLogHistory')
    {
      this.woLogHistory = grid;
      this.woLogHistory.dataSource = this.state.woLogHistory
    }

  
  }

  getURLParameters = (inputPara) => {
    var paraMeters = {
      workOrderKey: null,
      woNumber: ''
    };
    var query;
    if (inputPara !== undefined) {
      query = new URLSearchParams(inputPara.search);

      paraMeters = {
        workOrderKey: query.get("key"),
        woNumber: query.get("woNumber")
      };
    }

    return paraMeters;
  };




  validateData() {
    const { comments, invalidDataItem } = this.state;
    let bVal = true;
    let valItem = { ...invalidDataItem };

    // console.log("Comments value: " + comments); // Added log

    if (comments.trim() === "") {
      bVal = false;
      valItem.comments = false;
    } else {
      valItem.comments = true;
    }

    this.setState({ invalidDataItem: valItem }, () => {
      // console.log("Updated invalidDataItem:", this.state.invalidDataItem); // Added 
    });

    return bVal;
  }

  // validDataHours = () => {
  //   const { comments, invalidDataItem, completedHours, completedDate, validDataItem,woData } = this.state
  //   var bVal = true
  //   var valItem = invalidDataItem

  //   if (woData[0].fiixCompletedHours <= 0 || woData[0].fiixCompletedHours == null) {
  //     bVal = false
  //     valItem.fiixCompletedHours = false
  //   }

  //   // console.log("CompletedDate: " + completedDate)

  //   if (woData[0].fiixWODateCompleted === "" || woData[0].fiixWODateCompleted == null) {
  //     // console.log("This hit")
  //     bVal = false
  //     valItem.fiixWODateCompleted = false
  //   }
    

  //   if ((woData[0].fiixWOCompletedByUser == null || woData[0].fiixWOCompletedByUser == "") && (woData[0].completedVendorsKey == "" || woData[0].completedVendorsKey == null))
  //   {
  //     bVal = false
  //     valItem.fiixWOCompletedByUser = false
  //     valItem.completedVendorsKey = false
  //   }

  //   if (woData[0].completedVendorsKey && (woData[0].vendorCost == null || woData[0].vendorCost <= 0)) {
  //     bVal = false;
  //     valItem.vendorCost = false;
  //   }
  


  //   this.setState({ invalidDataItem: valItem })
  //   return bVal
  // }

//   validDataHours = () => {
//     const { partsAndCostDataGrid, invalidDataItem } = this.state;
//     let bVal = true;
//     let valItem = invalidDataItem;

//     // Check if there are any records with costTypeKey 1 or 2
//     const hasValidCostTypeKey = partsAndCostDataGrid.some(item => item.costTypeKey === 1 || item.costTypeKey === 2);

//     if (!hasValidCostTypeKey) {
//         bVal = false;
//         valItem.costTypeKey = false;
//         this.setState({ invalidDataItem: valItem });
//         this.showValidationModal(); // Show modal indicating a record is needed
//     }

//     return bVal;
// };

validDataHours = () => {
  const { partsAndCostDataGrid, invalidDataItem } = this.state;
  let bVal = true;
  let valItem = invalidDataItem;

  // Check if there are any records in partsAndCostDataGrid
  const hasAnyRecords = partsAndCostDataGrid.length > 0;

  if (!hasAnyRecords) {
      bVal = false;
      valItem.hasRecords = false; // You can use this flag to mark the invalid case
      this.setState({ invalidDataItem: valItem });
      this.showValidationModal(); // Show modal indicating that at least one record is needed
  }

  return bVal;
};

showValidationModal = () => {
  this.setState({ showValidationModal: true });
};

closeValidationModal = () => {
  this.setState({ showValidationModal: false });
};



  dataValidation = () => {
    return new Promise((resolve, reject) => {
      const returnVal = { "ok": true, "message": "", "headerValidation": [] }
      const { woData, validDataItem, isPriority, isSecurityAdminLogin, workOrderKey } = this.state


      // if (woData[0].woPriority === null && isPriority == true || woData[0].woPriority === "" && isPriority == true) {
      //     validDataItem.woPriority = false
      //     returnVal.ok = false
      //     returnVal.message = "Please enter missing data in red"
      // }


      // if ((woData[0].workOrderDesc === null || woData[0].workOrderDesc === '') && (isSecurityAdminLogin && workOrderKey > 0)) {

      //   validDataItem.workOrderDesc = false
      //   returnVal.ok = false
      //   returnVal.message = "Please enter missing data in red"
      // }

      if ((woData[0].workOrderDesc === null || woData[0].workOrderDesc === '') && woData[0].workOrderKey < 0) {

        validDataItem.workOrderDesc = false
        returnVal.ok = false
        returnVal.message = "Please enter missing data in red"
      }

      if (woData[0].assetID === null || woData[0].assetID === '') {

        validDataItem.assetID = false
        returnVal.ok = false
        returnVal.message = "Please enter missing data in red"
      }

      if ((woData[0].facilityID === null && woData[0].locationKey === null) || (woData[0].facilityID === "" && woData[0].locationKey === "") || (woData[0].facilityID === undefined && woData[0].locationKey === undefined)) {

        validDataItem.facilityID = false
        validDataItem.locationKey = false
        // validDataItem.assetID = false
        returnVal.ok = false
        returnVal.message = "Please enter missing data in red"
      }

      if (woData[0].locationKey === null || woData[0].locationKey === "") {
        validDataItem.locationKey = false
        returnVal.ok = false
        returnVal.message = "Please enter missing data in red"
      }


      if (woData[0].maintenanceType === null || woData[0].maintenanceType === '') {

        validDataItem.maintenanceType = false
        returnVal.ok = false
        returnVal.message = "Please enter missing data in red"
      }


      if (woData[0].fiixIssueType === null || woData[0].fiixIssueType === '') {

        validDataItem.fiixIssueType = false
        returnVal.ok = false
        returnVal.message = "Please enter missing data in red"
      }


      if ((woData[0].availStartDate === null || woData[0].availStartDate === "") && (woData[0].availEndDate !== null && woData[0].availEndDate !== "")) {
        validDataItem.availStartDate = false
        returnVal.ok = false
        returnVal.message = "Please enter Available Start Date"
      }


      if (moment(woData[0].availStartDate) > moment(woData[0].availEndDate)) {

        validDataItem.availStartDate = false
        validDataItem.availEndDate = false
        returnVal.ok = false
        returnVal.message = "Please enter Available End Date"
      }

      if (woData[0].maintenanceType === "Corrective" && woData[0].availStartDate === null) {
        validDataItem.availStartDate = false
        validDataItem.availEndDate = false
        returnVal.ok = false
        returnVal.message = "Please enter valid Date"
      }

      if (woData[0].maintenanceType === "Corrective" && woData[0].availStartDate === "") {
        validDataItem.availStartDate = false
        validDataItem.availEndDate = false
        returnVal.ok = false
        returnVal.message = "Please enter valid Date"
      }

      if ((woData[0].woPriority === "" || woData[0].woPriority === null) && workOrderKey < 0) {
        validDataItem.woPriority = false
        validDataItem.woPriority = false
        returnVal.ok = false
        returnVal.message = "Please select a priority"
      }

      //


      // if ((woData[0].assetID === null || woData[0].assetID === "") && (woData[0].facilityID === null || woData === "")  ) {
      //     console.log("assetID: " + woData[0].assetID)
      //     console.log("facilityID: " + woData[0].facilityID)
      //     validDataItem.assetID = false
      //     validDataItem.facilityID = false
      //     returnVal.ok = false
      //     returnVal.message = "Please enter missing data in red"
      // }




      returnVal.headerValidation = validDataItem

      resolve(returnVal)
    })
  }





  toggleQRCodeScanner = () => {
    this.setState({ modalQRCodeScanner: !this.state.modalQRCodeScanner })
  }

  closeForm = () => {
    this.props.toggle()
    this.setState({ qrValue: "", facilityKey: null })
  }

  // rerouteData = (mainType, issueType)=>{
  //   const { woData, oldMaintenanceType, approveMaintenanceTypes } = this.state
  //   this.dataValidation().then((data) => {
  //     if (data.ok) {
  //       if (this.validateData()) {
  //         woData[0].fiixWOStatus = "Requires Approval"
  //         woData[0].maintenanceType = mainType
  //         woData[0].fiixIssueType = issueType
  //         this.setState({woData: woData},()=>{
  //           if (woData[0].workOrderKey > 0) {
  //                 this.updateWorkOrder()
  //                 this.saveWOComment()
  //                 if (woData[0].availStartDate !== "" && woData[0].availStartDate !== null)
  //                 {
  //                   this.updateWorkOrderDateOnly()
  //                 }
        
  //                 this.closeForm()
  //             // }
  //           }
  //           else{
  //             // console.log("This did not work")
  //           }
      
  //         })
   
  //       }
        
  //     }
  //   })

  // }
  rerouteData = (mainType, issueType)=>{
    const { woData, oldMaintenanceType, approveMaintenanceTypes, comments, woStatus } = this.state
    // mainTypeApprovalList
    // woData[0].fiixWOStatus = "Requires Approval"
    /*Added by BN 10/10/24. Save reoruted issue type and maintenance type */
    woData[0].maintenanceType = mainType
    woData[0].fiixIssueType = issueType
    this.setState({woData: woData},()=>{
      if (woData[0].workOrderKey > 0) {
            // console.log(woStatus)
            // console.log(woData[0].fiixWOStatus)
            this.updateWorkOrder()
            if (comments != null && comments != "")
            {
              this.saveWOComment()
            }

            // if (woData[0].availStartDate !== "" && woData[0].availStartDate !== null)
            // {
            //   this.updateWorkOrderDateOnly()
            // }
  
            this.closeForm()
      }
      else{
      }

    })

  }

  onHoldData = () => {
    this.setState({ woStatus: "On Hold" });
    this.dataValidation().then((data) => {
      if (data.ok) {
        if (this.validateData()) {
          this.saveWOComment();
          this.updateWorkOrder();
          this.closeForm();
          this.toggleOnHoldModal(); // Close the modal only if data is valid
        }
      }
    });
  }

//   rerouteData = (mainType, issueType) => {
//     console.log("mainType in rerouteData:", mainType); // Check the received mainType
//     console.log("issueType in rerouteData:", issueType); // Check the received issueTyp
//     this.setState(prevState => {
//         const woDataUpdated = [...prevState.woData];
//         woDataUpdated[0] = {
//             ...woDataUpdated[0],
//             fiixWOStatus: "Requires Approval",
//             maintenanceType: mainType,
//             fiixIssueType: issueType,
//         };
//         return { woData: woDataUpdated };
//     }, () => {
//         console.log(this.state.woData[0].maintenanceType);
//         // Further actions...
//     });
// };

  submitData = () => {
    const {woData, woDataList} = this.state
    this.dataValidation().then((data) => {
      if (data.ok) {
          this.addWO()           
          this.closeForm()

      }
      else {
        this.setState({
          alertMessage: data.message,
          validDataItem: data.headerValidation,
          showAlert: true
        })
      }
    })
  }

  // saveData = () => {
  //   const { woData, woDataList, woDataListDefault } = this.state;
  
  //   this.dataValidation().then((data) => {
  //     if (data.ok) {
  //       if (woData[0].workOrderKey > 0) {
  //         this.updateWorkOrder();
  //         // this.updateWorkOrderDateOnly();
  //         this.closeForm();
  //       } else {
  //         // Check if a work order already exists with the same assetID and maintenanceType
  //         const workOrderExists = this.getDuplicateWorkOrder(woData[0].assetID, woData[0].maintenanceType)
  //         if (!workOrderExists) {
  //           this.addWO();
  //         } else {
  //           // Filter woDataList to show only matching work orders
  //           const filteredList = woDataListDefault.filter(
  //             (wo) =>
  //               String(wo.qrCodeValue).trim() === String(woData[0].qrCodeValue).trim() &&
  //               String(wo.maintenanceType).trim().toLowerCase() ===
  //                 String(woData[0].maintenanceType).trim().toLowerCase()
  //                 && wo.fiixWOStatus != "Completed" && wo.fiixWOStatus != "Canceled" && wo.fiixWOStatus != "Closed" && wo.fiixWOStatus != "Deleted"
  //           );
  
  //           // Set the filtered list and open the modal
  //           this.setState({
  //             woDataList: filteredList,
  //             isDuplicateWOModalOpen: true,
  //           });
  //         }
  //         // this.closeForm();
  //       }
  //     } else {
  //       this.setState({
  //         alertMessage: data.message,
  //         validDataItem: data.headerValidation,
  //         showAlert: true,
  //       });
  //     }
  //   });
  // };

  // saveData = () => {
  //   const { woData, woDataList } = this.state;
  

  //           this.setState({
  //             isDuplicateWOModalOpen: true
  //             // showAlert: true
  //           });
  //         // this.closeForm();

  // };


  saveData = () => {
    const { woData } = this.state;
  
    this.dataValidation().then((data) => {
      if (data.ok) {
        if (woData[0].workOrderKey > 0) {
          this.updateWorkOrder();
          // this.closeForm();
        } else {
          // Check for duplicate work order in the backend
          this.getDuplicateWorkOrder(this.getAssetID(woData[0].assetID), woData[0].maintenanceType)
            .then((duplicateWorkOrders) => {
              if (duplicateWorkOrders.length === 0) {
                // No duplicate found, add new work order
                this.addWO();
                this.closeForm();
              } else {
                // Update woDataList with woDuplicate JSON before opening the modal
                this.setState(
                  { woDataList: duplicateWorkOrders }, // Ensure woDataList is updated
                  () => {
                    // Open modal only after state update is complete
                    this.setState({ isDuplicateWOModalOpen: true });
                  }
                );
              }
            })
            .catch((error) => {
              console.error("Error checking duplicate work order:", error);
              this.setState({
                alertMessage: "Failed to check for duplicate work orders. Please try again.",
                showAlert: true,
              });
            });
        }
      } else {
        this.setState({
          alertMessage: data.message,
          validDataItem: data.headerValidation,
          showAlert: true,
        });
      }
    });
  };
  
  


  completeData = () => {
    this.setState({ woStatus: "Completed" })
    // console.log("This hit")
    this.dataValidation().then((data) => {
      if (data.ok) {
        if (this.validDataHours()) {
          // console.log("Complete hit")
          this.updateWorkOrder()
          this.closeForm()
        }
      }
      else {
        //  console.log("Enter hours")
      }
    })



  }

  workInProgressData = () => {
    // console.log("This hit")
    this.setState({ woStatus: "Work In Progress" })
    this.dataValidation().then((data) => {
      if (data.ok) {
        this.updateWorkOrder()
        this.closeForm()
      }
    })
  }


  closedData = () => {
    // console.log("This hit")
    this.setState({ woStatus: "Closed" })
    this.dataValidation().then((data) => {
      if (data.ok) {
        this.updateWorkOrder()
        this.closeForm()
      }
    })
  }


  resubmitData = () => {

    this.setState({ woStatus: "Requires Approval" })
    this.dataValidation().then((data) => {
      if (data.ok) {
        this.updateWorkOrder()
        this.closeForm()
      }
    })
  }

  cancelData = () => {

    this.setState({ woStatus: "Canceled" })
    this.dataValidation().then((data) => {
      if (data.ok) {
        this.updateWorkOrder()
        // this.closeForm()
      }
    })
  }


  // onHoldData = () => {
  //   this.setState({ woStatus: "On Hold" })
  //   this.dataValidation().then((data) => {
  //     if (data.ok) {
  //       if (this.validateData()) {
  //         this.saveWOComment()
  //         this.updateWorkOrder()
  //         this.closeForm()
  //       }
  //       else {
  //         //  console.log("Enter Comments")
  //       }
  //     }
  //   })

  // }



  addWO = () => {
    const woCreateDTO = this.createWODTO()


    let myURI = apiURL + 'Fiix/WorkOrder'
    fetch(myURI, apiPOSTHeader(woCreateDTO))
      .then(async response => {
        const rspData = response.text();
        if (!response.ok) {

          const error = (rspData && rspData.message) || response.status;
          rspData.then(t => {
            this.setState({
              msgBody: t,
              msgHeader: "Error",
              modalMsg: !this.state.modalMsg
            })
          })
          return Promise.reject(error);

        }
        this.props.refreshData();

      })
      .catch(error => {

      })

  }

  // updateWorkOrder = () => {
  //   const updateWODTO = this.updateWODTO()

  //   let myURI = apiURL + 'Fiix/Workorder'
  //   fetch(myURI, apiPUTHeader(updateWODTO))
  //     .then(async response => {
  //       const rspData = response.text();
  //       console.log("this hit first")
  //       console.log(response)
  //       if (!response.ok) {

  //         const error = (rspData && rspData.message) || response.status;
  //         rspData.then(t => {
  //           this.setState({
  //             msgBody: t,
  //             msgHeader: "Error",
  //             modalMsg: !this.state.modalMsg
  //           })
  //         })
  //         console.log("this hit")
  //         return Promise.reject(error);

  //       }
  //       this.props.refreshData();

  //     })
  //     .catch(error => {
  //       // this.getMaterialRequestDetails()


  //       // console.log("PUT Error: " + error);
  //     })
  // }

  updateWorkOrder = () => {
    const updateWODTO = this.updateWODTO();

    let myURI = apiURL + "Fiix/Workorder";
    fetch(myURI, apiPUTHeader(updateWODTO))
        .then(async (response) => {
            const rspData = await response.json(); // Parse as JSON
            if (!response.ok) {
                const errorMessage = rspData.message || response.statusText;

                // Set state to open the modal
                this.setState(
                    {
                        msgBody: errorMessage,
                        msgHeader: "Error",
                        modalMsg: true, // Ensure modalMsg is set to true
                    },
                    () => console.log("State after error:", this.state)
                );

                throw new Error(errorMessage); // Stop further execution
            }

            // Refresh data if successful
            this.props.refreshData();
            this.closeForm()
        })
        .catch((error) => {
            console.error("PUT Error: ", error);
        });
};

  updateWorkOrderDateOnly = () => {
    const updateWODTO = this.updateWODTO()

    let myURI = apiURL + 'Fiix/WorkOrderAvailDate'
    fetch(myURI, apiPUTHeader(updateWODTO))
      .then(async response => {
        const rspData = response.text();
        if (!response.ok) {
          //  console.log("Error return but you dont see")
          const error = (rspData && rspData.message) || response.status;
          rspData.then(t => {
            this.setState({
              msgBody: t,
              msgHeader: "Error",
              modalMsg: !this.state.modalMsg
            })
          })
          return Promise.reject(error);

        }


        // this.getMaterialRequestDetails()
      })
      .catch(error => {
        // this.getMaterialRequestDetails()

        // console.log("PUT Error: " + error);
      })
  }

  getAssetID = (value) => {
    const { assetList, assetListDefault } = this.state
    var newAsset = "";
    {
      if (value !== null || value !== "") {
        // console.log("assetID before search: " + value)
        // newAsset = assetList.filter(e => e.qrCodeValue === value.toString())
        newAsset = assetListDefault.filter(e => e.qrCodeValue === value.toString())
        // console.log("hit1")
        // console.log(value)
        // console.log(newAsset)
        if (newAsset.length < 1) {
          // console.log("hit2")
          return value
        }
        else {
          // console.log("hit3")
          return newAsset[0].assetID
        }

      }
      else {
        return ""
      }
    }
  }

  createWODTO = () => {
    const { woData, user, offLineLinkKey, assetIDConvert } = this.state
    // console.log("AssetID Before: " + woData[0].assetID)
    const woCreateDTO = {
      "assetID": this.getAssetID(woData[0].assetID),
      "facilityID": woData[0].facilityID,
      //"facilityKey": woData[0].facilityKey,
      "reqestDateTime": woData[0].createdDateTime,
      "workOrderDesc": woData[0].workOrderDesc,
      "requestedBy": getUserADAccount(user.account.userName),
      "long": null,
      "lat": null,
      "offlineLinkKey": offLineLinkKey,
      "woPriority": woData[0].woPriority,
      "maintenanceType": woData[0].maintenanceType,
      "estimateCost": woData[0].estimateCost,
      "locationKey": woData[0].locationKey,
      "availStartDate": woData[0].availStartDate == null || woData[0].availStartDate === "" ? null : moment(woData[0].availStartDate).format('YYYY-MM-DD HH:mm:ss'),
      "availEndDate": woData[0].availEndDate == null || woData[0].availEndDate === "" ? null : moment(woData[0].availEndDate).format('YYYY-MM-DD HH:mm:ss'),
      "fiixIssueType": woData[0].fiixIssueType,
      "fiixWorkInstruction": woData[0].fiixWorkInstruction /*Added by BN 11/26/24. Change were left out of original PR.*/
    }
    return woCreateDTO;
  }


  // updateWODTO = () => {
  //     const { woData, woStatus, completedHours } = this.state
  //     const { user } = this.props
  //     const woUpdateDTO = {
  //         "woNumber": woData[0].woNumber,
  //         "fiixWOId": woData[0].fiixWOId,
  //         "fiixWOStatus": woStatus == "" ? woData[0].fiixWOStatus : woStatus,
  //         "modifiedBy": getUserADAccount(user.account.userName),
  //         "dtmDateCompleted": new Date().toISOString(),
  //         "dblTimeSpendHours": completedHours,
  //         //"strDescription": "Work order completed by " + getUserADAccount(user.account.userName).replace(/\./g, ' ')
  //         "workOrderDesc": woData[0].workOrderDesc,
  //         "fiixWorkInstruction": woData[0].fiixWorkInstruction, //Added by BN2/27/23
  //         // "availStartDate": moment(woData[0].availStartDate).format('MM-DD-YYYY'),
  //         // "availEndDate": moment(woData[0].availEndDate).format('MM-DD-YYYY')
  //         "availStartDate": woData[0].availStartDate === null || woData[0].availStartDate === "" ? null : moment(woData[0].availStartDate).format("YYYY-MM-DD HH:mm:ss"),
  //         "availEndDate": woData[0].availEndDate === null || woData[0].availEndDate === "" ? null : moment(woData[0].availEndDate).format("YYYY-MM-DD HH:mm:ss"),
  //         "fiixIssueType": woData[0].fiixIssueType
  //     }
  //     //console.log(woUpdateDTO)
  //     return woUpdateDTO;
  // }

  updateWODTO = () => {

    //BN 2/25/24
    const { woData, woStatus, completedHours, workOrderKey
      , completedDate //Commented out by BN 7/11
      , completedUser
      , isDefaultDate
      , fiixAssignedToUserOld
      // , completedVendorsKey
    } = this.state




    // Create a Date object from the timestamp
    const date = new Date(completedDate);

    // Define options for formatting the date and time
    const options = {
      timeZone: 'America/Chicago',
      year: 'numeric',
      month: '2-digit',
      day: '2-digit',
      hour: '2-digit',
      minute: '2-digit',
      second: '2-digit'
    };

    // Convert the date to a string in Central Time
    const centralTimeString = date.toLocaleString('en-US', options);




    const currentDate = new Date();
    const localTimestamp = currentDate.getTime(); // Current local time in milliseconds
    const defaultDate = currentDate.toLocaleString('en-US', options);



    const { user } = this.props
    const woUpdateDTO = {
      "workOrderKey": workOrderKey,
      "woNumber": woData[0].woNumber,
      "statusKey": woData[0].statusKey,
      "assetID": this.getAssetID(woData[0].assetID),
      "facilityID": woData[0].facilityID === false ? null : woData[0].facilityID,
      "reqestDateTime": woData[0].createdDateTime,
      "long": null,
      "lat": null,
      "woPriority": woData[0].woPriority,
      "maintenanceType": woData[0].maintenanceType,
      "estimateCost": woData[0].estimateCost,
      "locationKey": woData[0].locationKey,
      "fiixWOId": woData[0].fiixWOId,
      "fiixWOStatus": woStatus == "" ? woData[0].fiixWOStatus : woStatus,
      "modifiedBy": getUserADAccount(user.account.userName),
      // "dtmDateCompleted": new Date().toISOString(),
      // "dtmDateCompleted": !isDefaultDate ? defaultDate : centralTimeString, //Commented out by BN 7/11. //Commented out by BN 8/15/24
      "dtmDateCompleted":  woData[0].fiixWODateCompleted === null || woData[0].fiixWODateCompleted === "" ? null : moment(woData[0].fiixWODateCompleted).format("YYYY-MM-DD HH:mm:ss"), 
      // "dblTimeSpendHours": completedHours, //Commented out by BN 8/15/24
      "dblTimeSpendHours": woData[0].fiixCompletedHours,
      //"strDescription": "Work order completed by " + getUserADAccount(user.account.userName).replace(/\./g, ' ')
      "workOrderDesc": woData[0].workOrderDesc,
      "fiixWorkInstruction": woData[0].fiixWorkInstruction, //Added by BN2/27/23
      "availStartDate": woData[0].availStartDate === null || woData[0].availStartDate === "" ? null : moment(woData[0].availStartDate).format("YYYY-MM-DD HH:mm:ss"),
      "availEndDate": woData[0].availEndDate === null || woData[0].availEndDate === "" ? null : moment(woData[0].availEndDate).format("YYYY-MM-DD HH:mm:ss"),
      "fiixIssueType": woData[0].fiixIssueType,
      // "fiixWOCompletedByUser": woStatus === "Completed" ? completedUser === null ? woData[0].fiixWOCompletedByUser : completedUser : woData[0].fiixWOCompletedByUser, //Commented out by BN 8/15/2
      "fiixWOCompletedByUser": woData[0].fiixWOCompletedByUser, /*Commented out by BN 8/29/24*/
      "fiixAssignedToUser": woData[0].fiixAssignedToUser == "" || woData[0].fiixAssignedToUser == null ? fiixAssignedToUserOld : woData[0].fiixAssignedToUser,
      "completedVendorsKey": woData[0].completedVendorsKey,
      "vendorCost": woData[0].vendorCost
 
    }
    // console.log("completedUser: " + completedUser)
    // console.log(woUpdateDTO)
    return woUpdateDTO;
  }


  getWorkOrder = () => {
    const { workOrderKey } = this.state
    if (workOrderKey > 0) {
      let myURI = apiURL + 'Fiix/WorkOrder/WOKey/' + workOrderKey
      fetch(myURI, apiHeader('GET'), { modes: 'no-cors' })
        .then(res => res.json())
        .then(json => {

          this.setState({
            isWOLoaded: true,
            woData: json,
            oldMaintenanceType: json[0].maintenanceType,
            fiixAssignedToUserOld: json[0].fiixAssignedToUser,
            isPriority: (json[0].woPriority !== null && json[0].woPriority !== "" && json[0].woPriority.includes("Urgent") ? true : false)
          })
          //this.getWOReportSummary(json[0].woNumber)
          // console.log(json[0].workOrderKey)
          this.getPartsAndCost(json[0].workOrderKey)
          this.getFiixWorkOrderTask()
          // console.log("This should refresh?")
        });
    } else {
      const newWO = this.createWO()
      this.setState({
        isWOLoaded: true,
        woData: newWO,
        readOnly: false
      })
    }
  }

  

  getWorkOrderLogHistory = () => {
    const { workOrderKey } = this.state
    if (workOrderKey > 0) {
      let myURI = apiURL + 'Fiix/WorkOrderLog/' + workOrderKey
      // console.log(myURI)
      fetch(myURI, apiHeader('GET'), { modes: 'no-cors' })
        .then(res => res.json())
        .then(json => {
          this.setState({
            isWOLogHistoryLoaded: true,
            woLogHistory: json,
          })
          //  console.log(json)
        });
    } 
    else{
      this.setState({
        isWOLogHistoryLoaded: true
      })
    }
  }


  getDuplicateWorkOrder = (assetID, maintenanceType) => {
    const myURI = `${apiURL}Fiix/DuplicateWorkOrder/assetID/${encodeURIComponent(assetID)}/maintenanceType/${encodeURIComponent(maintenanceType)}`;
  
    // Return the fetch call as a Promise
    return fetch(myURI, {
      method: 'GET',
      headers: {
        'Content-Type': 'application/json',
        ...apiHeader('GET'), // Add any additional headers here
      },
    })
      .then((response) => {
        if (!response.ok) {
          // Throw an error if the response is not successful
          throw new Error(`HTTP error! Status: ${response.status}`);
        }
        return response.json(); // Parse and return the JSON data
      })
      .then((json) => {
        // Set the state with the fetched JSON data
        this.setState(
          { woDuplicate: json, isWODuplicateLoaded: true }
        );
        return json; // Ensure the function still returns the data for further chaining
      })
      .catch((error) => {
        console.error("Error fetching duplicate work order:", error);
        throw error; // Rethrow to allow handling in the calling function
      });
  };
  
  
  


  getFiixWorkOrderTask = () => {
    const { workOrderKey } = this.state
    if (workOrderKey > 0) {
      let myURI = apiURL + 'Fiix/FiixWorkOrderTask/WOKey/' + workOrderKey
      fetch(myURI, apiHeader('GET'), { modes: 'no-cors' })
        .then(res => res.json())
        .then(json => {
          this.setState({
            isWOTaskLoaded: true,
            woTaskData: json,
          })
          // console.log(json)
        });
    } 
  }


  getWOReportSummary = (woNumber) => {
    // const { woNumber } = this.state
    let myURI = apiURL + 'Fiix/FiixWOReportSummary/' + 'woNumber/' + woNumber;

    // console.log(woNumber)
    fetch(myURI, apiHeader('GET'), { modes: 'no-cors' })
      .then(res => res.json())
      .then(json => {
        this.setState({
          isWOReportSummaryLoaded: true,
          woReportSummary: json,

        })
        // console.log(json)
      });

  }

  getPartsAndCost = (workOrderKey) => {
    // const { woNumber } = this.state
    let myURI = apiURL + 'Fiix/PartsAndCost/azureAD/'+ this.props.user.account.userName + '/workOrderKey/' + workOrderKey;
    // console.log(woNumber)
    fetch(myURI, apiHeader('GET'), { modes: 'no-cors' })
      .then(res => res.json())
      .then(json => {
        this.setState({
          isPartsAndCostLoaded: true,
          partsAndCostData: json,
          partsAndCostDataGrid: json
        })
      //  console.log(json)
      });

  }

  getStatus = () => {
    // const { woNumber } = this.state
    let myURI = apiURL + 'Fiix/PartsAndCostStatus';
    // console.log(woNumber)
    fetch(myURI, apiHeader('GET'), { modes: 'no-cors' })
      .then(res => res.json())
      .then(json => {
        this.setState({
          isPartsAndCostStatusLoaded: true,
          partsAndCostStatus: json
        })
      //  console.log(json)
      });

  }

  getPriority = () => {

    let myURI = apiURL + 'Fiix/Priority'
    fetch(myURI, apiHeader('GET'), { modes: 'no-cors' })
      .then(res => res.json())
      .then(json => {

        this.setState({
          isPriorityListLoaded: true,
          priorityList: this.createPriorityList(json)
        })

      });

  }


  getAssetList = () => {

    let myURI = apiURL + 'ItemMaster/FacilityInventoryCollector2'
    fetch(myURI, apiHeader('GET'), { modes: 'no-cors' })
      .then(res => res.json())
      .then(json => {

        this.setState({
          isAssetListLoaded: true,
          assetList: this.createAssetList(json),
          assetListDefault: this.createAssetList(json)
        })

      });
  }

  getEmpList = () => {
    let myURI = apiURL + "EmployeeDetails";


    fetch(myURI, { modes: "no-cors" })
        .then((res) => res.json())
        .then((json) => {
            this.setState({
                empList: this.createEmpList(json),
                isEmpListLoaded: true,
            });
        });
};

  createEmpList = (value) => {
    var tempList = [];
    value.map((e) => {
        e.value = e.employeeKey;
        e.label = e.empName;
        tempList.push(e);
    });

    return tempList;
};


getVendor = () => {
  let myURI = apiURL + "Fiix/Vendors";


  fetch(myURI, { modes: "no-cors" })
      .then((res) => res.json())
      .then((json) => {
          this.setState({
              vendorsList: this.createVendorsList(json),
              isVendorsListLoaded: true,
          });
      });
};

createVendorsList = (value) => {
  var tempList = [];
  value.map((e) => {
      e.value = e.wdSupplierKey;
      e.label = e.supplier_Name;
      tempList.push(e);
  });

  return tempList;
};


getSelVendors = (value) => {
  const { vendorsList } = this.state
  if (value !== null && value !== "") {
    return vendorsList.filter(e => e.wdSupplierKey === value)
  }
  return ""
}


// getWOUOM = () => {
//   let myURI = apiURL + "Fiix/WOUOM";


//   fetch(myURI, { modes: "no-cors" })
//       .then((res) => res.json())
//       .then((json) => {
//           this.setState({
//               woUOMData: this.createWOUOMList(json),
//               isWOUOMLoaded: true,
//           });
//       });
// };

// createWOUOMList = (value) => {
//   var tempList = [];
//   value.map((e) => {
//       e.value = e.uomKey;
//       e.label = e.uomDesc;
//       tempList.push(e);
//   });

//   return tempList;
// };

// getSelWOUOM = (value) => {
//   const { woUOMData } = this.state
//   if (value !== null && value !== "") {
//     return woUOMData.filter(e => e.uomKey === value)
//   }
//   return ""
// }


// getWOCostType = () => {
//   let myURI = apiURL + "Fiix/WOCostType";


//   fetch(myURI, { modes: "no-cors" })
//       .then((res) => res.json())
//       .then((json) => {
//           this.setState({
//               woCostType: this.createWOCostTypeList(json),
//               isWOCostTypeLoaded: true,
//           });
//       });
// };

// createWOCostTypeList = (value) => {
//   var tempList = [];
//   value.map((e) => {
//       e.value = e.woCostTypeKey;
//       e.label = e.woCostTypeKeyDesc;
//       tempList.push(e);
//   });

//   return tempList;
// };

// getSelWOCostType = (value) => {
//   const { woCostType } = this.state
//   if (value !== null && value !== "") {
//     return woCostType.filter(e => e.woCostTypeKey === value)
//   }
//   return ""
// }




  getFacilityList = () => {

    let myURI = apiURL + 'FacilityDetails'
    fetch(myURI, apiHeader('GET'), { modes: 'no-cors' })
      .then(res => res.json())
      .then(json => {

        this.setState({
          isFacilityListLoaded: true,
          facilityList: this.createFacilityList(json),
          facilityListDefault: this.createFacilityList(json)
        })

      });

  }

  getBasinList = () => {

    let myURI = apiURL + 'Fiix/GetBasin'
    fetch(myURI, apiHeader('GET'), { modes: 'no-cors' })
      .then(res => res.json())
      .then(json => {

        this.setState({
          isBasinListLoaded: true,
          basinList: this.createBasinList(json)
        })

      });

  }


  getMainType = () => {

    let myURI = apiURL + 'Fiix/MainType'
    fetch(myURI, apiHeader('GET'), { modes: 'no-cors' })
      .then(res => res.json())
      .then(json => {

        this.setState({
          isMainTypeListLoaded: true,
          mainTypeList: this.createMainTypeList(json)
        })
        // console.log(json)
      });

  }

  getMainTypeApproval = () => {

    let myURI = apiURL + 'Fiix/MainTypeApproval'
    fetch(myURI, apiHeader('GET'), { modes: 'no-cors' })
      .then(res => res.json())
      .then(json => {

        this.setState({
          isMainTypeApprovalListLoaded: true,
          mainTypeApprovalList: this.createMainTypeList(json)
        })

      });

  }


  getIssueType = () => {
    const { woData, maintenanceTypeID } = this.state

    let myURI = apiURL + 'Fiix/FiixIssueType'
    fetch(myURI, apiHeader('GET'), { modes: 'no-cors' })
      .then(res => res.json())
      .then(json => {

        this.setState({
          isIssueTypeListLoaded: true,
          issueTypeList: this.createIssueTypeList(maintenanceTypeID == null ? json : json.filter(a => a.codeDefinitionParentKey == maintenanceTypeID)),
          issueTypeListDefault: this.createIssueTypeList(json),
          issueTypeListFiltered: json.filter(a => a.codeDefinitionParentKey == maintenanceTypeID)
        })
        // console.log(json)

      });

  }


  getSecurity = () => {
    let myURI = apiURL + 'EmployeeDetails/Checkpoint/' + this.props.user.account.userName
    const { security, appSecurityNo, meterVal } = this.state
    fetch(myURI, apiHeader('GET'), { modes: 'no-cors' })
      .then(res => res.json())
      .then(json => {
        this.setState({

          isSecurityLoaded: true,
          security: json,
          isSecurityAdminLogin: (json.filter((list) => list.appSecurityNo === 115).length > 0 ? false : true),
          isApprover: (json.filter((list) => list.appSecurityNo === 145).length > 0 ? false : true),    //Preview2
          // isSecurityPartsAndCostUserLogin: (json.filter((list) => list.appSecurityNo === 150).length > 0 ? false : true) //REPLACE APPNO ON PROD
          // isSecurityMeasurementTech: (json.filter((list)=> list.appSecurityNo === 15).length > 0 ? false: true),
          // isOtherUser: (json.filter((list)=> list.appSecurityNo === 15 || list.appSecurityNo === 14 ).length == 0 ? true:false)
        })
        // console.log(this.state.security)
        // console.log(this.state.isSecurityAdminLogin)
        // console.log(this.state.isSecurityMeasurementTech)

      })

  }

  createPriorityList = (value) => {
    var tempList = []
    value.map(e => {
      e.value = e.fiixPriorityKey
      e.label = e.fiixPriorityFullDesc
      tempList.push(e)
    })

    return tempList
  }

  createFacilityList = (value) => {
    var tempList = []
    value.map(e => {
      e.value = e.facilityKey
      e.label = e.facilityName
      tempList.push(e)
    })

    return tempList
  }

  createBasinList = (value) => {
    var tempList = []
    value.map(e => {
      e.value = e.locationKey
      e.label = e.strName
      tempList.push(e)
    })

    return tempList
  }

  createMainTypeList = (value) => {
    var tempList = []
    value.map(e => {
      e.value = e.fixxMainTypeKey
      e.label = e.fixxMainTypeDesc
      tempList.push(e)
    })

    return tempList
  }

  createIssueTypeList = (value) => {
    const { woData } = this.state
    var tempList = []


    value.map(e => {
      e.value = e.fiixMainTypeKey
      e.label = e.fiixMainTypeDesc
      tempList.push(e)
    })

    return tempList
  }

  createAssetList = (value) => {
    var tempList = []
    value.map(e => {
      e.value = e.facilityInvCollectorKey
      //e.label = e.qrCodeValue
      e.label = e.longAssetName //Modified By BN 11/20/23 Concat Asset Name with Qr Code
      tempList.push(e)
    })

    return tempList
  }

  getSelPriority = (value) => {
    const { priorityList } = this.state

    if (value !== null && value !== "") {
      return priorityList.filter(e => e.fiixPriorityFullDesc === value)
    }
    return ""
  }


  getSelAsset = (value) => {
    const { assetList, woData, qrValue } = this.state
    // console.log("value: " + value)

    // if (value !== null && value !== ""){ 

    // return assetList.filter(e => e.qrCodeValue === value)

    // }
    // else{
    //     console.log("value is returning")
    //     return value
    // }
    // else{
    //   return { value: 1, label: value }
    // }

    if (value === "") {
      // Return null or an explicit placeholder if you want no selection
      // This depends on how you want the Creatable to behave when there's no QR code value
      return null; // or any other placeholder you deem appropriate
    }
  
    if (value !== null && value !== ""){ 
    const filteredAssets = assetList.filter(e => e.qrCodeValue === value);
      // console.log(filteredAssets.length)
    if (filteredAssets.length === 0) {
        return { value: 1, label: value };
    }

    // console.log(filteredAssets)
    return filteredAssets;
  }


  }


  getSelFacility = (value) => {
    const { facilityList } = this.state
    // console.log(value)
    if (value !== null && value !== "") {
      // console.log("facilityListFilter hit")
      return facilityList.filter(e => e.facilityKey === value)
    }
    return ""
  }

  getSelBasin = (value) => {
    const { basinList } = this.state

    if (value !== null && value !== "") {
      return basinList.filter(e => e.locationKey === value)
    }
    return ""
  }

  getSelMainType = (value) => {
    const { mainTypeList } = this.state

    if (value !== null && value !== "") {
      return mainTypeList.filter(e => e.fixxMainTypeDesc === value)
    }
    return ""
  }

  getSelIssueType = (value) => {
    const { issueTypeList } = this.state
    // console.log(value)
    if (value !== null && value !== "") {
      // console.log(issueTypeList.filter(e => e.fiixMainTypeDesc === value))
      return issueTypeList.filter(e => e.fiixMainTypeDesc === value)
    }
    else{
      return ""
    }

  }

  createWO = () => {
    const { user, qrValue, facilityKey, woData, locationKey, facilityID } = this.props
    // console.log(new Date().toISOString())
    const newWORecord = ({
      "workOrderKey": -1,
      "woNumber": 'WOXXXXX',
      "statusKey": 0,
      "assetID": qrValue,
      "qrCodeValue": qrValue,
      "reqestDateTime": new Date().toISOString(),
      "workOrderDesc": "",
      "lat": null,
      "long": null,
      "fiixWONo": "",
      "fiixWOId": null,
      "modifiedBy": null,
      "offLineLinkKey": -1,
      "createdDateTime": new Date().toISOString(),
      // "createdBy": user.account.name,
      // "requestedBy": getUserADAccount(user.account.userName),
      "createdByName": user.account.name,
      "dateLinkedToFiix": null,
      "woPriority": "",
      "facilityID": facilityID,
      "facilityKey": facilityKey,
      "estimateCost": 0.00,
      "maintenanceType": "",
      "locationKey": locationKey,
      "availStartDate": null,
      "availEndDate": null,
      "fiixIssueType": null,
      "fiixWOCompletedByUser": null


      // "facilityKey": facilityKey
    })
    // if (facilityKey !== null && facilityKey !== "")
    // {
    //     console.log("facilityKey: "  + facilityKey)
    //     woData[0].facilityKey = facilityKey
    //     this.setState({woData: facilityKey})
    // }

    return [newWORecord]

  }



  getIssueTypeFiltered = (v) => {
    const { issueTypeList, issueTypeListDefault } = this.state
    if (v !== null) {

      this.setState({ issueTypeList: issueTypeListDefault.filter(e => (e.codeDefinitionParentKey == v.toString())) })

    }
  }



//Comment out by BN 4/19/24. This is 95% good.
// filterAssets = () => {
//   const { assetListDefault, selBasin, selFacility, selMaintenance, mainTypeList } = this.state;

//   let filteredAssets = [...assetListDefault]; // Start with a copy of the full list

//   // If a maintenance type is selected, apply the maintenance type filter.
//   if (selMaintenance) {
//     const maintenanceKeys = selMaintenance.split(',').map(key => key.trim());
//     filteredAssets = filteredAssets.filter(asset =>
//       maintenanceKeys.includes(asset.itemTypeKey?.toString().trim())
//     );
//   }

//   // Apply basin filter if selected
//   if (selBasin) {
//     filteredAssets = filteredAssets.filter(asset =>
//       String(asset.locationKey).trim() === String(selBasin).trim()
//     );
//   }

//   // Apply facility filter if selected
//   if (selFacility) {
//     filteredAssets = filteredAssets.filter(asset =>
//       String(asset.facilityKey).trim() === String(selFacility).trim()
//     );
//   }

//   // Ensure we don't exclude assets when maintenance type is not selected
//   // If maintenance type is not selected, and either basin or facility is selected, filter only by the selected criteria
//   if (!selMaintenance && (selBasin || selFacility)) {
//     filteredAssets = assetListDefault.filter(asset => {
//       const matchesBasin = selBasin ? String(asset.locationKey).trim() === String(selBasin).trim() : true;
//       const matchesFacility = selFacility ? String(asset.facilityKey).trim() === String(selFacility).trim() : true;
//       return matchesBasin && matchesFacility;
//     });
//   }

//   // console.log("Filtered assets:", filteredAssets);

//   // Update the assetList in state
//   this.setState({ assetList: filteredAssets });
// };

//Commented out by BN 4/19/24 This works also

// filterAssets = () => {
//   const { assetListDefault, selBasin, selFacility, selMaintenance, mainTypeList } = this.state;

//   let filteredAssets = [...assetListDefault]; // Clone the full list

//   console.log("selmaintenance: " + selMaintenance)
//   console.log("selBasin: " + selMaintenance)
//   console.log("selFacility: " + selMaintenance)
//   // console.log("selmaintenance: " + selMaintenance)
//   if (selMaintenance) {
//     const maintenanceKeys = selMaintenance.split(',').map(key => key.trim());
//     filteredAssets = filteredAssets.filter(asset =>
//       maintenanceKeys.includes(asset.itemTypeKey?.toString().trim())
//     );
//   }

//   if (selBasin) {
//     filteredAssets = filteredAssets.filter(asset =>
//       String(asset.locationKey).trim() === String(selBasin).trim()
//     );
//   }

//   if (selFacility) {
//     filteredAssets = filteredAssets.filter(asset =>
//       String(asset.facilityKey).trim() === String(selFacility).trim()
//     );
//   }

//   // Always include the asset with itemTypeKey of 2963 if it exists and matches basin and facility if they are selected
//   const specialAsset = assetListDefault.find(asset => asset.itemTypeKey?.toString().trim() === '2963');
//   if (specialAsset && 
//       (!selBasin || String(specialAsset.locationKey).trim() === String(selBasin).trim()) &&
//       (!selFacility || String(specialAsset.facilityKey).trim() === String(selFacility).trim())) {

//     if (!filteredAssets.some(asset => asset.assetID === specialAsset.assetID)) {
//       filteredAssets.push(specialAsset);
//     }
//   }


//   this.setState({ assetList: filteredAssets }, () => {
//     console.log('State updated with filtered assets:', this.state.assetList);
//   });
// };


filterAssets = () => {
  const { assetListDefault, selBasin, selFacility, selMaintenance, mainTypeList } = this.state;

  // Start with the full list of assets
  let filteredAssets = [...assetListDefault];

  // Filter by maintenance type if one is selected
  if (selMaintenance) {
    const maintenanceKeys = selMaintenance.split(',').map(key => key.trim());
    filteredAssets = filteredAssets.filter(asset =>
      maintenanceKeys.includes(asset.itemTypeKey?.toString().trim())
    );
  }

  // Filter by basin if selected
  if (selBasin) {
    filteredAssets = filteredAssets.filter(asset =>
      String(asset.locationKey).trim() === String(selBasin).trim()
    );
  }

  // Filter by facility if selected
  if (selFacility) {
    filteredAssets = filteredAssets.filter(asset =>
      String(asset.facilityKey).trim() === String(selFacility).trim()
    );
  }

  // Include the special asset with itemTypeKey of '2963' if it exists and matches the selected filters
  const specialAssetKey = '2963';
  const specialAsset = assetListDefault.find(asset => asset.itemTypeKey?.toString().trim() === specialAssetKey);
  const matchesBasin = !selBasin || String(specialAsset?.locationKey).trim() === String(selBasin).trim();
  const matchesFacility = !selFacility || String(specialAsset?.facilityKey).trim() === String(selFacility).trim();
  const matchesMaintenance = !selMaintenance || mainTypeList.some(type => type.itemTypeKey === specialAssetKey && type.isItemTypeRelation === "1");

  // Add the special asset if it matches the criteria and isn't already in the filtered list
  if (specialAsset && matchesBasin && matchesFacility && matchesMaintenance && !filteredAssets.includes(specialAsset)) {
    filteredAssets.push(specialAsset);
  }

  // Remove duplicates, in case the special asset was added by both the regular filter and the special case
  filteredAssets = filteredAssets.filter((asset, index, self) =>
    index === self.findIndex((a) => a.assetID === asset.assetID)
  );

  // Update the state with the filtered assets
  this.setState({ assetList: filteredAssets });
};





  getMainTypeFilter = (itemTypeKey) => {
    const { mainTypeList } = this.state;
  
    // console.log('getMainTypeFilter called with itemTypeKey:', itemTypeKey);
  
    if (itemTypeKey === undefined || itemTypeKey === null) {
      // console.log('itemTypeKey is undefined or null');
      this.setState({ isMainTypeSelected: false });
      return "";
    }
  
    const itemTypeKeyString = itemTypeKey.toString();
    // console.log('itemTypeKeyString:', itemTypeKeyString);
  
    let filteredMainType = mainTypeList.find(mainType => {
      const itemTypeKeys = mainType.itemTypeKey ? mainType.itemTypeKey.split(',') : [];
      const isItemTypeMatch = itemTypeKeys.includes(itemTypeKeyString);
      const isRelationNotOne = mainType.isItemTypeRelation !== "1";
      // console.log('Checking mainType:', mainType, 'isItemTypeMatch:', isItemTypeMatch, 'isRelationNotOne:', isRelationNotOne);
      return isItemTypeMatch && isRelationNotOne;
    });
  
    if (!filteredMainType) {
      // console.log('No mainType found where IsItemTypeRelation is not "1", checking for IsItemTypeRelation "1"');
      filteredMainType = mainTypeList.find(mainType => {
        const itemTypeKeys = mainType.itemTypeKey ? mainType.itemTypeKey.split(',') : [];
        const isItemTypeMatch = itemTypeKeys.includes(itemTypeKeyString);
        const isRelationOne = mainType.isItemTypeRelation === "1";
        // console.log('Checking mainType:', mainType, 'isItemTypeMatch:', isItemTypeMatch, 'isRelationOne:', isRelationOne);
        return isItemTypeMatch && isRelationOne;
      });
    }
  
    if (filteredMainType) {
      // console.log('Filtered mainType found:', filteredMainType);
      this.getIssueTypeFiltered(filteredMainType.fixxMainTypeID);
      this.setState({ isMainTypeSelected: true });
      return filteredMainType.fixxMainTypeDesc;
    } else {
      // console.log('No matching mainType found at all');
      this.setState({ isMainTypeSelected: false });
    }
  
    return null;
  };


// getMainTypeFilter = (itemTypeKey) => {
//   const { mainTypeList } = this.state;

//   if (itemTypeKey === undefined || itemTypeKey === null) {
//       this.setState({ isMainTypeSelected: false });
//       return "";
//   }

//   const itemTypeKeyString = itemTypeKey.toString();
//   console.log(itemTypeKeyString)

//   // Helper function to find a matching mainType based on IsItemTypeRelation
//   const findMainType = (isItemTypeRelation) => {
//       return mainTypeList.find(mainType => {
//           if (mainType.itemTypeKey && mainType.isItemTypeRelation === isItemTypeRelation) {
//               const itemTypeKeys = mainType.itemTypeKey.split(',');
//               return itemTypeKeys.includes(itemTypeKeyString);
//           }
//           return false;
//       });
//   };

//   // Try to find a mainType with IsItemTypeRelation as null first
//   let filteredMainType = findMainType(null);

//   // If no match found, then try to find a mainType with IsItemTypeRelation as 1
//   if (!filteredMainType) {
//       filteredMainType = findMainType(1);
//   }

//   console.log(filteredMainType)
//   if (filteredMainType) {
//       this.getIssueTypeFiltered(filteredMainType.fixxMainTypeID);
//       this.setState({ isMainTypeSelected: true });
//       return filteredMainType.fixxMainTypeDesc;
//   } else {
//       this.setState({ isMainTypeSelected: false });
//   }

//   return null;
// };

onTextChangeForModal = (fieldName, fieldValue, index) => {
  const { woData, invalidDataItem } = this.state;

  // Log the values passed to the function
  // console.log(`Field Name: ${fieldName}`);
  // console.log(`Field Value: ${fieldValue}`);
  // console.log(`Index: ${index}`);

  // Ensure woData has the correct structure before updating it
  if (!woData[0].vendors) {
    woData[0].vendors = [];
  }

  if (!woData[0].completedUsers) {
    woData[0].completedUsers = [];
  }

  // Switch case to handle different field names
  switch (fieldName) {
    case "completedVendorsKey":
      // Ensure the vendors array has an object at the specified index
      if (!woData[0].vendors[index]) {
        woData[0].vendors[index] = {};
      }
      woData[0].vendors[index].completedVendorsKey = fieldValue;
      invalidDataItem.completedVendorsKey = true;
      break;

    case "vendorCost":
      // Ensure the vendors array has an object at the specified index
      if (!woData[0].vendors[index]) {
        woData[0].vendors[index] = {};
      }
      woData[0].vendors[index].vendorCost = fieldValue;
      invalidDataItem.vendorCost = true;
      break;

    case "completedHours":
      // Ensure the completedUsers array has an object at the specified index
      if (!woData[0].completedUsers[index]) {
        woData[0].completedUsers[index] = {};
      }
      woData[0].completedUsers[index].completedHours = fieldValue;
      invalidDataItem.fiixCompletedHours = true;
      break;

    case "fiixWOCompletedByUser":
      // Ensure the completedUsers array has an object at the specified index
      if (!woData[0].completedUsers[index]) {
        woData[0].completedUsers[index] = {};
      }
      woData[0].completedUsers[index].fiixWOCompletedByUser = fieldValue;
      invalidDataItem.fiixWOCompletedByUser = true;
      break;

    case "completedDate":
      woData[0].fiixWODateCompleted = fieldValue;
      invalidDataItem.fiixWODateCompleted = true;
      break;

    default:
      break;
  }

  this.setState({ woData, invalidDataItem });
};

  onTextChange = (fieldName, fieldValue, e) => {
    var { isPriority } = this.state
    const { validDataItem, qrValue, facilityKey, switchBool, invalidDataItem, issueTypeList, completedUser, facilityListDefault, assetListDefault,assetList, workOrderKey, mainTypeList } = this.state
    const { user } = this.props
    //  console.log(fieldName + '=' + fieldValue + '=' + e)

    const { woData, comments } = this.state;
    const test = "";
    if (workOrderKey > 0)
    {
      
    }
    switch (fieldName) {
      case "workOrderDesc":
        woData[0].workOrderDesc = fieldValue
        validDataItem.workOrderDesc = true
        break;

    case "assetID":
  if (e) {
    const isExistingAsset = assetList.some(asset => asset.value === e.value);
    woData[0].assetID = e.qrCodeValue === undefined ? e.value : e.qrCodeValue;
    woData[0].qrCodeValue = woData[0].assetID;
    if (isExistingAsset) {
      woData[0].locationKey = e.locationKey || "";
      woData[0].facilityID = e.facilityID || "";
      woData[0].facilityKey = e.facilityKey || "";
    }

    this.setState({ selFacility: e.facilityKey, }, this.filterAssets);
    this.setState({ selBasin: e.locationKey, }, this.filterAssets);
  } else {
    // this.setState({ switchBool: null })
    // console.log("Asset cleared");
    woData[0].assetID = "";
    woData[0].qrCodeValue = "";
  }

  // Log the current state before updating
  // console.log("State before updating:", this.state);

  if(woData[0].maintenanceType === "" || woData[0].maintenanceType === null)
  {
    woData[0].maintenanceType = this.getMainTypeFilter(e === null ? "" : e.itemTypeKey)
    validDataItem.maintenanceType = true
    this.setState({ isMainTypeSelected: this.getMainTypeFilter(e === null ? "" : e.itemTypeKey) === null ? true : false })      
  }
  else{
    this.setState({ isMainTypeSelected: false })
  }
  validDataItem.facilityID = true
  validDataItem.assetID = true
  validDataItem.locationKey = true
  // Now let's update the state and call filterAssets
  this.setState({ woData: woData }, () => {

    if(woData[0].facilityKey === "" || woData[0].faciliyKey === null || woData[0].facilityID === "" || woData[0].facilityKey === null)
    {
      this.setState({ switchBool: null}) 
    }  
    else{
      this.setState({ switchBool: -1}) 
    }
    this.filterAssets();
  });

  break;



      case "facilityID":
  // console.log(`Facility change detected. Facility ID: ${e ? e.facilityID : "null"}`);
  woData[0].facilityID = e ? e.facilityID : "";
  woData[0].facilityKey = e ? e.facilityKey : "";
  // woData[0].locationKey = e ? e.locationKey : "";
  if (workOrderKey < 0) {
    woData[0].assetID = "";
    woData[0].qrCodeValue = "";
  }

  validDataItem.facilityID = true;
  validDataItem.assetID = true;
  validDataItem.locationKey = true;

  if (e) {
    // console.log(`Updating state for facility: ${e.facilityKey}`);
    this.setState({
      selFacility: e.facilityKey,
      switchBool: -1
    }, () => {
      // console.log("State after setting facility:", this.state);
      this.filterAssets(); 
    });
  } else {
    // console.log("Clearing facility selection.");
    this.setState({
      selFacility: "",
      switchBool: null,
      //selBasin: null, 
      facilityList: facilityListDefault.filter(
        (a) => Number(a.locationKey) === woData[0].locationKey
      ), // Uncomment if you want to reset the facility list
      // assetList: assetListDefault, 
    }, () => {
      // console.log("State after clearing facility:", this.state);
      this.filterAssets(); 
    });
  }
  break;

      case "basin":
  // console.log("Basin change detected.");
  woData[0].locationKey = e ? e.locationKey : "";
  // console.log(`Basin location key set to: ${woData[0].locationKey}`);

  // Clear facility ID and key if basin is cleared or changed.
  if (!e) {
    woData[0].facilityID = "";
    woData[0].facilityKey = "";
  }

  if (e) {
    woData[0].locationKey = (e === null ? "" : e.locationKey)
    woData[0].facilityID = (e === "")
    woData[0].facilityKey = (e === "")
    // console.log("Basin selected, updating state with new basin and calling filterAssets");
    // Combine both state updates into a single setState call
    this.setState({
      selBasin: e.locationKey,
      facilityList: facilityListDefault.filter(
        (a) => Number(a.locationKey) === e.value
      ),
      woData: woData
    }, this.filterAssets); // Call filterAssets after state is set
  } else {
    // console.log("Basin cleared, updating state and calling filterAssets");
    // When basin is cleared, also clear facility selection.
    this.setState({
      selBasin: "",
      selFacility: "", // Clear facility as well, because it depends on basin
      woData: woData
    }, this.filterAssets); // Call filterAssets after state is set
  }

  validDataItem.facilityID = true;
  validDataItem.assetID = true;
  validDataItem.locationKey = true;
  break;

      case "priority":
        woData[0].woPriority = (e === null ? "" : e.fiixPriorityFullDesc)
        if (e == null) {
          this.setState({ isPriority: false })
        }
        else {
          if (e.fiixPriorityID == "Urgent" && e !== null) {
            this.setState({ isPriority: true })
          }
          else {
            this.setState({ isPriority: false })
          }
        }

        // if((e.fiixPriorityFullDesc))
        validDataItem.woPriority = true
        // console.log(e.fiixPriorityFullDesc)

        break;

      case "estimateCost":
        woData[0].estimateCost = Number(fieldValue)

        break;
      case "maintenanceType":
        if(e === null) {
          woData[0].maintenanceType = "";
          if(workOrderKey < 0) {
            woData[0].locationKey = "";
            woData[0].facilityKey = "";
            woData[0].assetID = "";
            woData[0].qrCodeValue = "";
          }
        } else {
          const selectedMainType = mainTypeList.find(mainType => mainType.fixxMainTypeDesc === e.fixxMainTypeDesc);
          const currentMainType = mainTypeList.find(mainType => mainType.fixxMainTypeDesc === woData[0].maintenanceType);
          woData[0].maintenanceType = e.fixxMainTypeDesc;
          if(workOrderKey < 0) {
            if (!(currentMainType && selectedMainType && currentMainType.isItemTypeRelation === "1" && selectedMainType.isItemTypeRelation === "1")) {
              // woData[0].locationKey = "";
              // woData[0].facilityKey = "";
              // woData[0].assetID = "";
              // woData[0].qrCodeValue = "";
            }
          }
        }

        validDataItem.maintenanceType = true
        validDataItem.availStartDate = true
        validDataItem.availEndDate = true
        woData[0].fiixIssueType = null
        // console.log(e)
        // this.setState({ switchBool: null })

        this.getIssueTypeFiltered(e === null ? "" : e.fixxMainTypeID)

        if (e !== null && e !== "")
        {
          this.setState({ selMaintenance: e.itemTypeKey, selFacility: "", selBasin: "" }, this.filterAssets);
    
        }
        else{
          
          this.setState({ selMaintenance: "", selFacility: "", selBasin: "" }, this.filterAssets);
           this.setState({ switchBool: null })
          
        }
        if (woData[0].maintenanceType === null || woData[0].maintenanceType === "") {
          this.setState({ isMainTypeSelected: true })
        }
        else {
          this.setState({ isMainTypeSelected: false })
        }

        break;

      case "fiixIssueType":
        woData[0].fiixIssueType = (e === null ? "" : e.fiixMainTypeDesc)
        validDataItem.fiixIssueType = true
        invalidDataItem.fiixIssueType = true
        // validDataItem.availStartDate = true
        // validDataItem.availEndDate = true
        // console.log(e.value + "Test")
        break;

      case "onHoldComments":
        // console.log("onTextChange called for onHoldComments");
        this.setState({ comments: fieldValue });
        invalidDataItem.comments = fieldValue.trim() !== ""; // Correct logic
        this.setState({ invalidDataItem }); // Ensure state is updated correctly
        break;

        case "onRejectComments":
          // console.log(fieldValue)
          this.setState({ comments: fieldValue });
          invalidDataItem.comments = (fieldValue != "")
          break;  

      case "completedHours":
        // this.setState({ completedHours: fieldValue });
        // invalidDataItem.completedHours = (fieldValue != "")

        woData[0].fiixCompletedHours = fieldValue
        invalidDataItem.fiixCompletedHours = true
        break;

        case "vendorCost":
          woData[0].vendorCost = fieldValue
          this.setState({ woData: woData });
          invalidDataItem.vendorCost = true
  
          break; 
          


      case "completedDate": //Commented out by BN 7/11
        // console.log(fieldValue)

        // if (!fieldValue.isValid()) {
        //   this.setState({ completedDate: null });
        // } else {
        //   this.setState({ completedDate: fieldValue, isDefaultDate: true });
        // }

        // invalidDataItem.completedDate = (fieldValue != "");
        woData[0].fiixWODateCompleted = fieldValue
        invalidDataItem.fiixWODateCompleted = true
        break;


      case "fiixWorkInstruction":
        // woData[0].workInstruction = (e === null ? "" : e.workInstruction)
        //validDataItem.maintenanceType = true
        woData[0].fiixWorkInstruction = fieldValue
        break;

      case "availStartDate":
        woData[0].availStartDate = fieldValue
        validDataItem.availStartDate = true
        if (woData[0].availStartDate > validDataItem.availEndDate) {
          validDataItem.availEndDate = true
        }

        break;

      case "availEndDate":
        woData[0].availEndDate = fieldValue
        validDataItem.availEndDate = true

        break;

      case "completedUser": //Commented out by BN 7/11
        // if (e === null) {
        //   this.setState({ completedUser: getUserADAccount(user.account.userName), woData: woData })
          
        // }
        // else {
        //   this.setState({ completedUser: e.adAccount, woData: woData })
        //   this.getSelEmpList(e.adAccount)

        // }
        // var completedUserInfo = (e === null ? "" : e.adAccount)
        // this.setState({completedUser: completedUserInfo})
        woData[0].fiixWOCompletedByUser = (e === null ? "" : e.adAccount)
        invalidDataItem.fiixWOCompletedByUser = true
        invalidDataItem.completedVendorsKey = true
        break;

        case "completedVendorsKey": //Commented out by BN 7/11
          woData[0].completedVendorsKey = (e === null ? "" : e.wdSupplierKey)
          woData[0].vendorCost = 0.00
          invalidDataItem.completedVendorsKey = true
          invalidDataItem.fiixWOCompletedByUser = true
          invalidDataItem.vendorCost = true
        break;

        case "fiixAssignedToUser": //Commented out by BN 7/11
        if (e === null) {

          woData[0].fiixAssignedToUser = ""
        }
        else {
          woData[0].fiixAssignedToUser = e.emailAddress
          this.setState({woData: woData })
          this.getSelEmpList(e.adAccount)

        }

        break;



      default:

    }

    this.setState({ woData: woData, validDataItem: validDataItem, invalidDataItem: invalidDataItem, })
  }




  getSelEmpList = (value) => {
    const { empList, user } = this.state
    //  console.log(value)
    if (value !== null && value !== "" && value !== undefined) {
      // console.log(value)
      // console.log("Test1")
      // console.log(value)
      // console.log(empList.filter(e => e.adAccount.toLowerCase() === value.toLowerCase()))
      return empList.filter(e => e.adAccount.toLowerCase() === value.toLowerCase())

    }
    // else {
    //   return empList.filter(e => e.adAccount.toLowerCase() === getUserADAccount(user.account.userName).toLowerCase())
    // }

  }

  

  getSelRerouteEmpList = (value) => {
    const { empList, user } = this.state
    if (value !== null && value !== "") {
      return empList.filter(e => e.adAccount.toLowerCase() === value.toLowerCase())

    }
  }


getWOApproval = () => {
  const { workOrderKey } = this.state

  let myURI = apiURL + 'Fiix/Approval/' + workOrderKey

  fetch(myURI, { modes: 'no-cors' })
      .then(res => res.json())
      .then(json => {
          this.setState({
              woApproval: json,
              isApprovalLoaded: true
          })
          // console.log(json)
      });
}
  componentDidMount() {
    this.getWorkOrder()
    this.getWorkOrderLogHistory()
    this.getPriority()
    this.getAssetList()
    this.getFacilityList()
    this.getBasinList()
    this.getMainType()
    this.getMainTypeApproval()
    this.getWOComment()
    this.getIssueType()
    this.getSecurity()
    this.getWOApproval()
    this.getEmpList()
    this.getVendor()
    this.getStatus()
    // this.getWOUOM()
    // this.getWOCostType()
    // this.getFiixWorkOrderTask()
  }

  setQRCodeValue = (qrValue) => {
    const { woData, facilityList, assetList } = this.state
    woData[0].assetID = qrValue
    woData[0].qrCodeValue = qrValue
    //console.log("qrValue: " + qrValue)
    var test = assetList.filter(a => a.qrCodeValue == qrValue)
    // console.log(test)
    woData[0].facilityKey = test[0].facilityKey
    woData[0].facilityID = test[0].facilityID
    woData[0].locationKey = test[0].locationKey
    // console.log(test[0].itemTypeKey)
    woData[0].maintenanceType = this.getMainTypeFilter(test[0].itemTypeKey)
    this.setState({ isMainTypeSelected: false })
    this.setState({
      qrValue: qrValue,
      switchBool: -1,
      woData: woData,
      modalQRCodeScanner: !this.state.modalQRCodeScanner
    })

  }
  toggleTab = tab => {
    const { activeTab } = this.state

    if (activeTab !== tab) {
      this.setState({ activeTab: tab })
    }
  }
  toggleMsg = () => {
    this.setState({ modalMsg: !this.state.modalMsg })
  }


  // handleInputChange = (inputValue, actionMeta) => {
  //     console.log('handleInputChange', inputValue, actionMeta)
  // }


  getWOComment = () => {
    const { afeHeaderKey, workOrderKey } = this.state
    let myURI = apiURL + 'Fiix/Comment/' + workOrderKey

    // console.log(myURI);

    fetch(myURI, { modes: 'no-cors' })
      .then(res => res.json())
      .then(json => {
        this.setState({
          woComment: json,
          isWOCommentLoaded: true
        })
      });
  }

  saveWOComment = () => {
   const addDTO = this.createWOCommentForCreateDTO()
    let myURI = apiURL + 'Fiix/Comment'
    fetch(myURI, apiPOSTHeader(addDTO))
      .then(async response => {
        const rspData = response.text();
        if (!response.ok) {
          //console.log("Error return but you dont see")
          const error = (rspData && rspData.message) || response.status;
          //return Promise.reject(error);
          return false
        }
        this.getWOComment()

        //  console.log("No error here: " + rspData)
      })
      .catch(error => {
        //  console.log("PUT Error: " + error);
        this.getWOComment()

        return false
      })
    this.getWOComment()


    return true


  }

  createWOCommentForCreateDTO = () => {
    const { cmtDate, comments, user, workOrderKey } = this.state

    const afeCmtDTO = {
      "commentTypeKey": 5, 
      "compObjectKey": workOrderKey, //selectedValue.key,
      "workOrderKey": null,
      "commentDate": cmtDate,
      "comments": comments,
      "createdBy": getUserADAccount(user.account.userName),
      // "linkPrimaryKey": null, /* This column added by HN - 10/19 after stored proc is created*/
      // "headerComment": null /* This column added by HN - 10/19 after stored proc is created*/
    }
    return afeCmtDTO
  }
  noValue = () => {
    this.setState({ completedHours: null })
    //console.log("This hit sucessfully")
  }

  formatCurrency = (value) => {
    const number = parseFloat(value);
    if (isNaN(number)) return ""; // or return "$0.00" or any other default value
    return `$${number.toFixed(2)}`;
  }



  // onLoad() {
  //   console.log(this.grid)
  //   if (this.grid !== undefined) {
  //     console.log("onload Hit")
  //     // Define custom localization to replace "Sum" with "Total Cost"
  //     const customLocale = {
  //       ...this.grid.locale, // Spread current locale settings (if any)
  //       Sum: "Total Cost", // Override the "Sum" label
  //     };

  //     // Set the custom locale to the grid
  //     this.grid.locale = customLocale;

  //     // Clear existing summary descriptions
  //     this.grid.summaryDescriptions.clear();

  //     // Summary for totalLaborCost
  //     const totalLaborCostSum = new IgrColumnSummaryDescription();
  //     totalLaborCostSum.field = "totalLaborCost";
  //     totalLaborCostSum.operand = SummaryOperand.Sum;
  //     totalLaborCostSum.formatOverride = new Intl.NumberFormat("en-EN", {
  //       style: "currency",
  //       currency: "USD",
  //       minimumFractionDigits: 0,
  //       maximumFractionDigits: 0,
  //     });
  //     this.grid.summaryDescriptions.add(totalLaborCostSum);

  //     // Summary for totalThirdPartyCost
  //     const totalThirdPartyCostSum = new IgrColumnSummaryDescription();
  //     totalThirdPartyCostSum.field = "totalThirdPartyCost";
  //     totalThirdPartyCostSum.operand = SummaryOperand.Sum;
  //     totalThirdPartyCostSum.formatOverride = new Intl.NumberFormat(
  //       "en-EN",
  //       {
  //         style: "currency",
  //         currency: "USD",
  //         minimumFractionDigits: 0,
  //         maximumFractionDigits: 0,
  //       }
  //     );
  //     this.grid.summaryDescriptions.add(totalThirdPartyCostSum);

  //     // Summary for totalPartCost
  //     const totalPartCostSum = new IgrColumnSummaryDescription();
  //     totalPartCostSum.field = "totalPartCost";
  //     totalPartCostSum.operand = SummaryOperand.Sum;
  //     totalPartCostSum.formatOverride = new Intl.NumberFormat("en-EN", {
  //       style: "currency",
  //       currency: "USD",
  //       minimumFractionDigits: 2,
  //       maximumFractionDigits: 2,
  //     });
  //     this.grid.summaryDescriptions.add(totalPartCostSum);

  //     const totalCostSum = new IgrColumnSummaryDescription();
  //     totalCostSum.field = "totalCost";
  //     totalCostSum.operand = SummaryOperand.Sum;
  //     totalCostSum.formatOverride = new Intl.NumberFormat("en-EN", {
  //       style: "currency",
  //       currency: "USD",
  //       minimumFractionDigits: 2,
  //       maximumFractionDigits: 2,
  //     });

  //     totalCostSum.cssClass = "bold-summary";
  //     // Use special symbols or text to make the display name stand out
  //     totalCostSum.displayName = ">>> Total Cost <<<";

  //     this.grid.summaryDescriptions.add(totalCostSum);
  //   }
  // }


/*Current Works 8/7/24*/
  // onLoad() {
  //   // const targetGrid = this.woReportSummary; // Use the woReportSummary grid
  //   const targetGrid = this.partsAndCostData; // Use the woReportSummary grid
    
  //   // console.log(targetGrid);
  //   if (targetGrid !== undefined) {
  //     // console.log("onload Hit");
      
  //     const customLocale = {
  //       ...targetGrid.locale, // Spread current locale settings (if any)
  //       Sum: "Total Cost", // Override the "Sum" label
  //     };
  
  //     // Set the custom locale to the target grid
  //     targetGrid.locale = customLocale;
  
  //     // Clear existing summary descriptions
  //     targetGrid.summaryDescriptions.clear();
  
  //     // Summary for totalLaborCost
  //     const totalLaborCostSum = new IgrColumnSummaryDescription();
  //     totalLaborCostSum.field = "totalLaborCost";
  //     totalLaborCostSum.operand = SummaryOperand.Sum;
  //     totalLaborCostSum.formatOverride = new Intl.NumberFormat("en-EN", {
  //       style: "currency",
  //       currency: "USD",
  //       minimumFractionDigits: 0,
  //       maximumFractionDigits: 0,
  //     });
  //     targetGrid.summaryDescriptions.add(totalLaborCostSum);
  
  //     // Summary for totalThirdPartyCost
  //     const totalThirdPartyCostSum = new IgrColumnSummaryDescription();
  //     totalThirdPartyCostSum.field = "totalThirdPartyCost";
  //     totalThirdPartyCostSum.operand = SummaryOperand.Sum;
  //     totalThirdPartyCostSum.formatOverride = new Intl.NumberFormat("en-EN", {
  //       style: "currency",
  //       currency: "USD",
  //       minimumFractionDigits: 0,
  //       maximumFractionDigits: 0,
  //     });
  //     targetGrid.summaryDescriptions.add(totalThirdPartyCostSum);
  
  //     // Summary for totalPartCost
  //     const totalPartCostSum = new IgrColumnSummaryDescription();
  //     totalPartCostSum.field = "totalPartCost";
  //     totalPartCostSum.operand = SummaryOperand.Sum;
  //     totalPartCostSum.formatOverride = new Intl.NumberFormat("en-EN", {
  //       style: "currency",
  //       currency: "USD",
  //       minimumFractionDigits: 2,
  //       maximumFractionDigits: 2,
  //     });
  //     targetGrid.summaryDescriptions.add(totalPartCostSum);
  
  //     // Summary for totalCost
  //     const totalCostSum = new IgrColumnSummaryDescription();
  //     totalCostSum.field = "totalCost";
  //     totalCostSum.operand = SummaryOperand.Sum;
  //     totalCostSum.formatOverride = new Intl.NumberFormat("en-EN", {
  //       style: "currency",
  //       currency: "USD",
  //       minimumFractionDigits: 2,
  //       maximumFractionDigits: 2,
  //     });
  
  //     totalCostSum.cssClass = "bold-summary";
  //     totalCostSum.displayName = ">>> Total Cost <<<";
  //     targetGrid.summaryDescriptions.add(totalCostSum);
  //   }
  // }

  // onTotalLaborCostCellUpdating = (s, e) => {
  //   const formattedValue = new Intl.NumberFormat('en-EN', {
  //     style: 'currency',
  //     currency: 'USD',
  //     minimumFractionDigits: 0,
  //     maximumFractionDigits: 2
  //   }).format(e.value);

  //   e.newValue = formattedValue;
  // }

  // customSummary = (s) => {
  //   if (s.field === "totalCost") {
  //     return `Total Cost: ${s.sum}`;
  //   }
  //   return s.label; // default behavior for other fields
  // };


  onLoad() {
    const { isSecurityAdminLogin } = this.state; // Access the state
    const targetGrid = this.partsAndCostData;
  
    if (targetGrid !== undefined) {
      const customLocale = {
        ...targetGrid.locale, // Spread current locale settings (if any)
        Sum: "Total Cost", // Override the "Sum" label
      };
  
      targetGrid.locale = customLocale;
  
      // Clear existing summary descriptions
      targetGrid.summaryDescriptions.clear();
  
      // Conditionally add summary descriptions if isSecurityPartsAndCostUserLogin is true
      if (!isSecurityAdminLogin) {
        // Summary for totalLaborCost
        const totalLaborCostSum = new IgrColumnSummaryDescription();
        totalLaborCostSum.field = "totalLaborCost";
        totalLaborCostSum.operand = SummaryOperand.Sum;
        totalLaborCostSum.formatOverride = new Intl.NumberFormat("en-EN", {
          style: "currency",
          currency: "USD",
          minimumFractionDigits: 0,
          maximumFractionDigits: 0,
        });
        targetGrid.summaryDescriptions.add(totalLaborCostSum);
  
        // Summary for totalThirdPartyCost
        const totalThirdPartyCostSum = new IgrColumnSummaryDescription();
        totalThirdPartyCostSum.field = "totalThirdPartyCost";
        totalThirdPartyCostSum.operand = SummaryOperand.Sum;
        totalThirdPartyCostSum.formatOverride = new Intl.NumberFormat("en-EN", {
          style: "currency",
          currency: "USD",
          minimumFractionDigits: 0,
          maximumFractionDigits: 0,
        });
        targetGrid.summaryDescriptions.add(totalThirdPartyCostSum);
  
        // Summary for totalPartCost
        const totalPartCostSum = new IgrColumnSummaryDescription();
        totalPartCostSum.field = "totalPartCost";
        totalPartCostSum.operand = SummaryOperand.Sum;
        totalPartCostSum.formatOverride = new Intl.NumberFormat("en-EN", {
          style: "currency",
          currency: "USD",
          minimumFractionDigits: 2,
          maximumFractionDigits: 2,
        });
        targetGrid.summaryDescriptions.add(totalPartCostSum);
  
        // Summary for totalCost
        const totalCostSum = new IgrColumnSummaryDescription();
        totalCostSum.field = "totalCost";
        totalCostSum.operand = SummaryOperand.Sum;
        totalCostSum.formatOverride = new Intl.NumberFormat("en-EN", {
          style: "currency",
          currency: "USD",
          minimumFractionDigits: 2,
          maximumFractionDigits: 2,
        });
  
        totalCostSum.cssClass = "bold-summary";
        totalCostSum.displayName = ">>> Total Cost <<<";
        targetGrid.summaryDescriptions.add(totalCostSum);
      }
    }
  }
  


  capitalizeWords = (str) => {
    if (!str) return ''; // handle null or undefined values
    return str.split(' ').map(word => {
      return word.charAt(0).toUpperCase() + word.slice(1).toLowerCase();
    }).join(' ');
  }
  
  transformNames = (inputStr) => {
    if (!inputStr) return 'No assigned user.'; // handle null or undefined values
    
    // Check if the string contains a semicolon
    if (inputStr.includes(';')) {
      return inputStr.split(';').map(name => {
        return this.capitalizeWords(name.trim());
      }).join(' ; ');
    }
    // If there's no semicolon, just capitalize the words
    return this.capitalizeWords(inputStr);
  }

  // approveWO = (comment, user) => {
  //   const {woData} = this.state
  //   this.approvalWO(6, comment, null);
  //   woData[0].fiixWOStatus = "Assigned/Scheduled"
  //   console.log("This is ther user:" + user)
  //   woData[0].fiixAssignedToUser = user
  //   this.setState({woData: woData}, ()=>{
  //   this.saveData()
  //   })

  // };

  approveWO = async (comment, user) => {
    // console.log("Starting rejectWO");
    try {
        const result = await this.approvalWO(6, comment, null);
        if (result && result.success) {
            const { woData } = this.state;
            woData[0].fiixWOStatus = "Assigned/Scheduled"
            woData[0].fiixAssignedToUser = user
            this.setState({ woData }, this.saveData);
        } else {
            // console.log("Approval not successful, skipping saveData");
        }
    } catch (error) {
        // console.error("Approval process or saveData failed:", error);
        // Optionally handle the error, e.g., updating the state to display an error message
    }
};

  // approvalWO = (statusKey, comment, key) => {
  //   const approvalWODTO = this.approvalWODTO(statusKey, comment, key);
  //   let myURI = apiURL + "Fiix/Approval";

  //   fetch(myURI, apiPUTHeader(approvalWODTO))
  //     .then(async (response) => {
  //       if (!response.ok) {
  //         const rspData = response.text();
  //         var error = (rspData && rspData.message) || response.status;

  //         rspData.then((t) => {
  //           this.setState({
  //             msgBody: t,
  //             msgHeader: "Error",
  //             modalMsg: !this.state.modalMsg,
  //           });
  //         });

  //         return Promise.reject(error);
  //       }
  //       this.props.refreshData();
  //       // this.closeForm();
  //     })
  //     .catch((error) => {});
  // };

  approvalWO = (statusKey, comment, key) => {
    const approvalWODTO = this.approvalWODTO(statusKey, comment, key);
    let myURI = `${apiURL}Fiix/Approval`;

    return new Promise((resolve, reject) => {
        fetch(myURI, apiPUTHeader(approvalWODTO))
          .then(response => {
            if (!response.ok) {
              response.text().then(text => {
                this.setState({
                  msgBody: text,
                  msgHeader: "Error",
                  modalMsg: true,
                });
                reject(new Error(text || "Failed to approve"));
              });
            } else {
              // Attempt to parse JSON only if the response is not empty
              response.text().then(text => {
                if (text) {
                  try {
                    const data = JSON.parse(text);
                    this.props.refreshData();
                    resolve({ success: true });
                  } catch (e) {
                    reject(new Error("Failed to parse JSON"));
                  }
                } else {
                  // Handle empty response or treat it as successful, based on API contract
                  // console.log("Empty response received");
                  this.props.refreshData();
                  resolve({ success: true });
                }
              });
            }
          })
          .catch(error => {
            console.error("Approval error:", error);
            reject(error);
          });
    });
};

  approvalWODTO = (statusKey, comment, actionLineKey) => {
    const { user } = this.props;
   const { workOrderKey } = this.state;
    const mocApprovalDTO = {
      WorkOrderKey: workOrderKey,
      ApprovalUser: getUserADAccount(user.account.userName),
      ApprovalStatusKey: statusKey,
      ApprovalComment: comment,
      ActionLineKey: actionLineKey,
    };
    // console.log(mocApprovalDTO)
    return mocApprovalDTO;
  };


  // rejectWO = async (comment, key) => {
  //   const { woData } = this.state;
  
  //   try {
  //     // Wait for approvalWO to complete
  //     await this.approvalWO(0, comment, key);
  //     // If approvalWO succeeds, continue to update the state and call saveData
  //     woData[0].fiixWOStatus = "Rejected";
  //     woData[0].fiixAssignedToUser = "";
  //     await this.setState({ woData: woData }, () => {
  //       console.log("This should have hit")
  //       this.saveData(); // Only runs if approvalWO succeeds
  //     });
  //   } catch (error) {
  //     // If approvalWO fails, log the error and avoid calling saveData
  //     console.error("Approval process failed:", error);
  //     // Optionally, you can handle the error here, such as showing a message to the user
  //   }
  // };

  rejectWO = async (comment, key) => {
    // console.log("Starting rejectWO");
    try {
        // const result = await this.approvalWO(0, comment, key); /*Comments out b ybN 9/23/24. Don't need to approval for sendback.*/
        // if (result && result.success) {
            let { woData, approvalComments } = this.state;
            approvalComments = comment;
            woData[0].fiixWOStatus = "Need Revision"; // Modified by BN 6/12/24 Requested by Josh
            woData[0].fiixAssignedToUser = "";
            this.setState({ woData, approvalComments }, () => {
                this.saveData();
                this.saveWOReviseComment();
                // console.log("method2"); // Ensure this logs to check if the callback is executed
            });
        // } 
        // else {
        //     // Optionally handle the case when the result is not successful
        // }
    } catch (error) {
        // console.error("Approval process or saveData failed:", error);
        // Optionally handle the error, e.g., updating the state to display an error message
    }
};

// submitWO = (comment, key) => {
//   const {woData} = this.state

//   this.approvalWO(0, comment, key);
//   woData[0].fiixWOStatus = "Requires Approval"
//   // woData[0].fiixAssignedToUser = ""
//   this.setState({woData: woData}, ()=>{
//   this.saveData()
// })
// }



// isSaveButtonHidden=()=>{
//   const {isSecurityAdminLogin, woData} = this.state

//   // if(!isSecurityAdminLogin  && woData[0].fiixWOStatus !== "Rejected" && woData[0].fiixWOStatus !== "Need Revision")
//   // {
//   //   // console.log(2)
//   //   return false
//   // }
//   if(!isSecurityAdminLogin  && woData[0].fiixWOStatus !== "Need Revision")
//     {
//       // console.log(2)
//       return false
//     }
//   // if (woData[0].fiixWOStatus === "Need Revision" || woData[0].fiixWOStatus === "Rejected" || woData[0].fiixWOStatus === 'Canceled' || woData[0].fiixWOStatus === 'Completed')
//   // {
//   //   // console.log(1)
//   //   return true
//   // }
//   if ( woData[0].fiixWOStatus === "Need Revision" || woData[0].fiixWOStatus === 'Canceled' || woData[0].fiixWOStatus === 'Completed')
//     {
//       // console.log(1)
//       return true
//     }


// }
isSaveButtonHidden = () => {
  const { isSecurityAdminLogin, woData } = this.state;
  const fiixWOStatus = woData[0].fiixWOStatus;

  // Hide the button if the status is 'Canceled' or 'Completed'

  if (isSecurityAdminLogin == false)
    {
      return false
    }
  
  if (fiixWOStatus === "Canceled" || fiixWOStatus === "Completed" || fiixWOStatus === "Closed") {
    return true;
  }

  // Hide the button if a security admin is logged in and the status is 'Need Revision'
  if (!isSecurityAdminLogin && fiixWOStatus === "Need Revision") {
    return true;
  }

  // In all other cases, do not hide the button
  return false;
};



toggleDropDown=()=>{
  this.setState({
    dropdownOpen: !this.state.dropdownOpen
  });
}


toggleRerouteModal = () => {
  // console.log("Toggling reroute modal"); // Debugging line to ensure the method is called
  this.setState({ isRerouteModalOpen: !this.state.isRerouteModalOpen }, () => {
    // console.log("Modal state is now:", this.state.isRerouteModalOpen); // Confirm state has changed
  });
};

toggleApproveModal=()=>{
  // console.log("Toggling reroute modal"); // Debugging line to ensure the method is called
  this.setState({ isApproveModalOpen: !this.state.isApproveModalOpen }, () => {
    // console.log("Modal state is now:", this.state.isApproveModalOpen); // Confirm state has changed
  });
}

toggleRejectModal=()=>{
  // console.log("Toggling reroute modal"); // Debugging line to ensure the method is called
  this.setState({ isRejectModalOpen: !this.state.isRejectModalOpen }, () => {
    // console.log("Modal state is now:", this.state.isRejectModalOpen); // Confirm state has changed
  });
}

toggleWIPModal=()=>{
  // console.log("Toggling reroute modal"); // Debugging line to ensure the method is called
  this.setState({ isWIPModalOpen: !this.state.isWIPModalOpen }, () => {
    // console.log("Modal state is now:", this.state.isRejectModalOpen); // Confirm state has changed
  });
}

toggleClosedModal=()=>{
  // console.log("Toggling reroute modal"); // Debugging line to ensure the method is called
  this.setState({ isClosedModalOpen: !this.state.isClosedModalOpen }, () => {
    // console.log("Modal state is now:", this.state.isRejectModalOpen); // Confirm state has changed
  });
}


toggleDuplicateWOModal=()=>{
  // console.log("Toggling reroute modal"); // Debugging line to ensure the method is called
  this.setState({ isDuplicateWOModalOpen: !this.state.isDuplicateWOModalOpen }, () => {
    // console.log("Modal state is now:", this.state.isRejectModalOpen); // Confirm state has changed
  });
}



toggleCancelModal=()=>{
  // console.log("Toggling reroute modal"); // Debugging line to ensure the method is called
  this.setState({ isCancelModalOpen: !this.state.isCancelModalOpen }, () => {
    // console.log("Modal state is now:", this.state.isRejectModalOpen); // Confirm state has changed
  });
}

toggleOnHoldModal=()=>{
  // console.log("Toggling reroute modal"); // Debugging line to ensure the method is called
  this.setState({ isOnHoldModalOpen: !this.state.isOnHoldModalOpen }, () => {
    // console.log("Modal state is now:", this.state.isRejectModalOpen); // Confirm state has changed
  });
}

toggleCompleteModal=()=>{
  // console.log("Toggling reroute modal"); // Debugging line to ensure the method is called
  this.setState({ isCompleteModalOpen: !this.state.isCompleteModalOpen }, () => {
    
    // console.log("Modal state is now:", this.state.isRejectModalOpen); // Confirm state has changed
  });
}

toggleCompleteActionModal=()=>{
  // console.log("Toggling reroute modal"); // Debugging line to ensure the method is called
  this.setState({ isCompleteModalOpen: !this.state.isCompleteModalOpen, hideComplete: false }, () => {
    
    // console.log("Modal state is now:", this.state.isRejectModalOpen); // Confirm state has changed
  });
}



getMainTypeList = () => {
  const { mainTypeApprovalList, mainTypeList, woData } = this.state;

  // console.log(mainTypeApprovalList);
  // console.log(woData[0].maintenanceTypeID)

  if (woData && woData.length > 0 && woData[0].maintenanceTypeID !== undefined) {
    const maintenanceTypeID = woData[0].maintenanceTypeID;
    const existsInApprovalList = mainTypeApprovalList.some(a => 
      a.fixxMainTypeID === maintenanceTypeID
    );

    if (existsInApprovalList) {
      // console.log("MainApprovalList");
      return mainTypeApprovalList;
    }
  }

  // console.log("All Maintenance List");
  return mainTypeList;
}


// rerouteAssignedUser=()=>{
//   const {woData, mainTypeApprovalList} = this.state
//   const {user} = this.props
//   console.log(mainTypeApprovalList)
//   const isMainTypeApproved = mainTypeApprovalList.some(
//     (type) => type.fiixMainTypeDesc.toLowerCase() === woData[0].mainType.toLowerCase()
//   );

//   console.log(isMainTypeApproved)

//   if (woData[0].fiixAssignedToUser.toLowerCase() === user.account.userName.toLowerCase() && 
//   isMainTypeApproved &&
//   (woData[0].fiixWOStatus === "Assigned/Scheduled" || woData[0].fiixWOStatus === "On Hold" || woData[0].fiixWOStatus === "Work In Progress"))
//   {
//     return false
//   }
//   else return true
// }

rerouteAssignedUser = () => {
  const { woData, mainTypeApprovalList } = this.state;
  const { user } = this.props;
  // console.log(user.account.userName)
  // console.log(mainTypeApprovalList);

  // Check if woData and mainTypeApprovalList have been defined and have the expected properties
  if (!woData || !woData[0] || !mainTypeApprovalList || !user || !user.account) {
    console.error('One or more required properties are undefined.');
    // console.log("Hit 0")
    return true; // Return true to indicate that reroute should happen due to missing data
  }

  // Further check if the specific properties we're going to use are present
  const assignedToUser = woData[0].fiixAssignedToUser;
  const mainType = woData[0].maintenanceType;
  const userName = user.account.userName;

  
// Ensure that the variables are not null before converting to lowercase
const lowerAssignedToUser = assignedToUser ? assignedToUser.toLowerCase() : '';
const lowerUserName = userName ? userName.toLowerCase() : '';

// Check if either string contains the other, also handling the case where either might be null
const namesContainEachOther = lowerAssignedToUser.includes(lowerUserName) || lowerUserName.includes(lowerAssignedToUser);

  

  // Check the types of the properties
  if (typeof assignedToUser !== 'string' || typeof mainType !== 'string' || typeof userName !== 'string') {
    // console.log('Expected properties are not strings.');
    return true; // Return true to indicate that reroute should happen due to incorrect data types
  }

  const isMainTypeApproved = mainTypeApprovalList.some(
    (type) =>  mainType.toLowerCase() === type.fixxMainTypeDesc.toLowerCase()
  );
  // console.log(namesContainEachOther)
  // console.log(assignedToUser.toLowerCase())
  // console.log(userName.toLowerCase())
  // console.log(isMainTypeApproved)
  // console.log((woData[0].fiixWOStatus === "Assigned/Scheduled" || woData[0].fiixWOStatus === "On Hold" || woData[0].fiixWOStatus === "Work In Progress"))
  if (
    namesContainEachOther &&
    isMainTypeApproved &&
    (woData[0].fiixWOStatus === "Assigned/Scheduled" || woData[0].fiixWOStatus === "On Hold" || woData[0].fiixWOStatus === "Work In Progress")
  ) {
    // console.log("Hit1")
    return false;
  } else {
    // console.log("Hit2")
    return true;
  }
};


onCreateQRCode = (newQRCode) => {
  const {woData} = this.state
  // Handle the creation of a new QR code asset here
  // Set the QR code value without affecting the basin or facility
  woData[0].qrCodeValue = newQRCode;
  // ... any other specific logic for a new QR code

  // Update state to reflect the new QR code without clearing basin or facility
  this.setState({ woData: woData });
};

isRejectedStatusFields=()=>{
  const {woData, isSecurityAdminLogin} = this.state
  const { user } = this.props;


  // if (woData[0].fiixWOStatus === "Rejected"  &&  woData[0].createdBy === getUserADAccount(user.account.userName))
  //   {
  //     console.log("This hit")
  //     return false
  //   }
  if (woData[0].fiixWOStatus === "Need Revision"  &&  woData[0].createdBy.toLowerCase() === getUserADAccount(user.account.userName).toLowerCase())
    {
      return false
    }

  else if (isSecurityAdminLogin === false)
    {
      return false
    }
  else return true
}

saveWOReviseComment = () => {
        const addDTO = this.createWOReviseCommentForCreateDTO()
          let myURI = apiURL + 'Fiix/Comment'
          fetch(myURI, apiPOSTHeader(addDTO))
              .then(async response => {
                  const rspData = response.text();
                  if (!response.ok) {
                      //("Error return but you dont see")
                      const error = (rspData && rspData.message) || response.status;
                      //return Promise.reject(error);
                      return false
                  }
                  this.getWOComment()
                 this.setState({approvalComments: ""})
              })
              .catch(error => {
                 // console.log("PUT Error: " + error);
                  this.getWOComment()
                  this.setState({approvalComments: ""})
                  return false
              })
              this.getWOComment()

  }


createWOReviseCommentForCreateDTO = () => {
  const { cmtDate, approvalComments, user, workOrderKey } = this.state

  const woCmtDTO = {
      "commentTypeKey": 5, 
      "compObjectKey": workOrderKey, 
      "workOrderKey": null,
      "commentDate": cmtDate,
      "comments": approvalComments,
      "createdBy": getUserADAccount(user.account.userName),
  }
  // console.log("Comment DTO: " + woCmtDTO)
  return woCmtDTO
}

//  FormatDateWithTime = (value) => {
//   if (this.IsDate(value)) {
//       const d = new Date(value);
//       return d.toLocaleString("en-US", {
//           timeZone: "America/Chicago",
//           year: 'numeric',
//           month: '2-digit',
//           day: '2-digit',
//           hour: 'numeric',
//           minute: 'numeric',
//           second: 'numeric',
//           hour12: true,
//       });
//   } else {
//       return "";
//   }
// }

// FormatDateWithTime = (value) => {
//   if (this.IsDate(value)) {
//     const utcDate = new Date(value + "Z"); // Append 'Z' to treat it as UTC
//     return utcDate.toLocaleString("en-US", {
//       timeZone: "America/Chicago",
//       year: 'numeric',
//       month: '2-digit',
//       day: '2-digit',
//       hour: 'numeric',
//       minute: 'numeric',
//       second: 'numeric',
//       hour12: true,
//     });
//   }
//   return "";
// };
// IsDate = (value) => {
//   const date = new Date(value);
//   return !isNaN(date.valueOf());
// }

FormatDateWithTime = (value) => {
  if (this.IsDate(value)) {
    let dateString = value;
    // Check if the date string already includes a timezone indicator
    if (!/Z$|[+-]\d{2}:\d{2}$/.test(dateString)) {
      dateString += "Z";
    }
    const utcDate = new Date(dateString);
    return utcDate.toLocaleString("en-US", {
      timeZone: "America/Chicago",
      year: 'numeric',
      month: '2-digit',
      day: '2-digit',
      hour: 'numeric',
      minute: 'numeric',
      second: 'numeric',
      hour12: true,
    });
  }
  return "";
};

IsDate = (value) => {
  const date = new Date(value);
  return !isNaN(date.valueOf());
}

toggleWorkInProgressModal() {
  this.setState({
    isWorkInProgressModalOpen: !this.state.isWorkInProgressModalOpen,
  });
}


toggleModalHistoryLog = () => {
  this.setState({ modalHistoryLog: !this.state.modalHistoryLog })
}

refreshPartsAndCostData=()=>{
this.getWorkOrder()
this.toggleCompleteModal()
}

// toggleCompleteModal = () => {
//   this.setState(prevState => ({
//     isPartsAndCostModalOpen: !prevState.isPartsAndCostModalOpen,
//   }));
// };

//  openCompleteModal = (s, e) => {
//   if (e.isDoubleClick) {
//     // Logic to open the modal in WORequest.js
//     // Example: set state to open the modal
//     this.setState({isPartsAndCostModalOpen: true}) // Assuming you're using state to control modal visibility
//   }
// };


onGridRowSelected(s, e) {
  const { fiixWOID } = this.state
  const content = e.content;
  if (e.addedKeys.count > 0) {
      let clickedRow = e.addedKeys.item(0)
      let dataItem = clickedRow.value[0]
      this.setState({
          workOrderKey: dataItem.workOrderKey,
          partsAndCostKey: dataItem.partsAndCostKey,
          costTypeKey: dataItem.costTypeKey
      })

      // console.log(dataItem)
  }
}


onGridRowSelected2 = (s, e) => {
  if (e.isDoubleClick) {
  this.setState({ hideComplete: true },()=>{
    // console.log(this.state.hideComplete)
    if(this.state.hideComplete == true)
    {
      this.toggleCompleteModal();
    }

  });

  }
}



  render() {
    const { workOrderKey, isReadOnly, isFacilityBool,
      woData, isWOLoaded,
      activeTab, offLineLinkKey,
      qrValue, isPriorityListLoaded, priorityList, isAssetListLoaded,
      assetList, msgBody, msgHeader, validDataItem, readOnly, isFacilityListLoaded,
      facilityList, isMainTypeListLoaded, mainTypeList, isBasinListLoaded, basinList, switchBool, facilityKey,
      isWOCommentLoaded, invalidDataItem, fiixWOId, isIssueTypeListLoaded, issueTypeList, isMainTypeSelected,
      isSecurityLoaded, isSecurityAdminLogin, empList, isEmpListLoaded, completedDate, completedUser,
      isWOReportSummaryLoaded, woReportSummary, woTaskData, isWOTaskLoaded, issueTypeListFiltered, isApprovalLoaded, woApproval, 
      isApprover, oldMaintenanceType, approveMaintenanceTypes, issueTypeListDefault, user, isMainTypeApprovalListLoaded, mainTypeApprovalList, 
      comments, isWOLogHistoryLoaded, woLogHistory, isVendorsListLoaded, vendorsList, completedVendorsKey, partsAndCostData, partsAndCostDataGrid, 
      showValidationModal, hideComplete, fiixWOStatus, partsAndCostStatus, isPartsAndCostStatusLoaded, woDataList, isDuplicateWOModalOpen
      // woCostType, isWOCostTypeLoaded
     } = this.state
    var { isPriority } = this.state

    if (!isWOLoaded || !isPriorityListLoaded || !isAssetListLoaded || !isFacilityListLoaded || !isMainTypeListLoaded ||
      !isBasinListLoaded || !isWOCommentLoaded || !isMainTypeApprovalListLoaded
      || !isIssueTypeListLoaded || !isSecurityLoaded || !isApprovalLoaded ||!isEmpListLoaded || !isWOLogHistoryLoaded ||!isVendorsListLoaded  || !isPartsAndCostStatusLoaded
      // || !isWOUOMLoaded 
      // || !isWOCostTypeLoaded
    ) {
      return (<div>Loading WO modal.....</div>)
    } else if (!readOnly) {

      return (
        <div>
          <Form onSubmit={(e) => e.preventDefault()}>
            <Row form>
              <Col md={6}>
                <Label for="requestedBy">Requested By</Label>
                <Input
                  type="text"
                  name="requestedBy"
                  id="requestedBy"
                  //onChange={(e) => this.onTextChange("costDescription", e.target.value, e)}
                  disabled={true}
                  defaultValue={woData[0].createdByName}
                  value={woData[0].createdByName}
                />
              </Col>
              <Col md={6}>
                <Label for="requestDate">Requested Date</Label>
                <Input
                  type="text"
                  name="createdDateTime"
                  id="createdDateTime"
                  // onChange={(e) =>
                  //   this.onTextChange("createdDateTime", e.target.value, e)
                  // }
                  // hidden={console.log(this.FormatDateWithTime(woData[0].reqestDateTime))}
                  disabled={true}
                  defaultValue={this.FormatDateWithTime(woData[0].reqestDateTime)}
                  value={this.FormatDateWithTime(woData[0].reqestDateTime)}
                />
              </Col>
            </Row>

            <Row form>
              <Col md={8}>
                <Label
                 hidden={readOnly == false} 
                 for="assignedToUserName">
                  Assigned To
                </Label>
                <Input
                  type="text"
                  name="assignedToUserName"
                  id="assignedToUserName"
                  //onChange={(e) => this.onTextChange("costDescription", e.target.value, e)}
                  hidden={readOnly == false}
                  value={woData[0].assignedToUserName}
                />
              </Col>
              <Col md={4}>
                <Label hidden={readOnly == false} for="fiixWOStatus">
                  Status
                </Label>
                <Input
                  type="text"
                  name="fiixWOStatus"
                  id="fiixWOStatus"
                  hidden={readOnly == false}
                  disabled={readOnly}
                  // onChange={(e) => this.onTextChange("createdDateTime", e.target.value, e)}
                  // disabled={true}
                  // defaultValue={FormatDate(woData[0].createdDateTime)}
                  value={woData[0].fiixWOStatus}
                />
              </Col>
            </Row>

            <Row form>
              <Col md={12} className={"alway-on-top-more-more"}>
                <Label for="assetID">Maintenance Type</Label>
                <Select
                  options={mainTypeList}
                  isDisabled={readOnly}
                  className={
                    !validDataItem.maintenanceType
                      ? "alway-on-top-more-more form-error"
                      : ""
                  }
                  value={this.getSelMainType(woData[0].maintenanceType)}
                  placeholder={""}
                  onChange={(e) => this.onTextChange("maintenanceType", e, e)}
                  isClearable={true}
                ></Select>
              </Col>
            </Row>


            <Row form>
              <Col md={12}className={"alway-on-top"}>
                <Label for="assetID">Basin</Label>
                <Select
                  options={basinList}
                  isDisabled={switchBool !== null ? true : false}
                  className={
                    !validDataItem.locationKey
                      ? "alway-on-top-more form-error"
                      : ""
                  }
                  value={this.getSelBasin(woData[0].locationKey)}
                  onChange={(e) => this.onTextChange("basin", e, e)}
                  placeholder={""}
                  isClearable={true}
                ></Select>
              </Col>
            </Row>

            <Row form>
              <Col md={12} className={"alway-on-top-fiix"}>
                <Label for="assetID">Facility</Label>
                <Select
                  options={facilityList}
                  // isDisabled={readOnly}
                  isDisabled={isFacilityBool}
                  className={
                    !validDataItem.facilityID
                      ? "alway-on-top-more form-error"
                      : ""
                  }          
                  value={this.getSelFacility(woData[0].facilityKey)}
                  onChange={(e) => this.onTextChange("facilityID", e, e)}
                  placeholder={""}
                  isClearable={true}
                ></Select>
              </Col>
            </Row>

            <Row form className={"alway-in-between-more-more"}>
              <Col md={10} className={"alway-in-between-more-more"}>
                <Label for="assetID">Qr Code </Label>
                <Creatable
                  options={assetList}
                  className={
                    !validDataItem.assetID ? "alway-on-top-more form-error" : ""
                  }
                  value={this.getSelAsset(woData[0].qrCodeValue)}
                  isDisabled={readOnly}
                  // defaultValue = {this.getSelAsset((woData[0].assetID))}
                  onChange={(e) => this.onTextChange("assetID", e, e)}
                  onCreateOption={(newQRCode) => this.onCreateQRCode(newQRCode)}
                  // onCreateOption={(e) =>this.onTestChange("assetID",e,e)}
                  // onInputChange={this.handleInputChange}
                  // isValidNewOption={true}
                  placeholder={"Select..."}
                  isClearable={true}
                ></Creatable>
              </Col>

              <Col md={2}>
                <Label for="assetID"> </Label>
                <Button
                  color="primary"
                  style={{ margin: "0px 20px 0px 0px" }}
                  disabled={readOnly}
                  onClick={() => this.toggleQRCodeScanner()}
                >
                  QR
                </Button>
              </Col>
            </Row>



            <Row form>
              <Col md={12} className={"alway-in-between-more"}>
                <Label for="assetID">Issue Type</Label>
                <Select
                  options={issueTypeList}
                  isDisabled={isMainTypeSelected}
                  className={
                    !validDataItem.fiixIssueType
                      ? "alway-on-top form-error"
                      : ""
                  }
                  value={this.getSelIssueType(woData[0].fiixIssueType)}
                  placeholder={""}
                  onChange={(e) => this.onTextChange("fiixIssueType", e, e)}
                  isClearable={true}
                ></Select>
              </Col>
            </Row>

            <Row form>
              <Col md={12}>
                <Label for="estimateCost">Estimated Total Cost</Label>
                <NumberFormat
                  className={"form-control text-md-right"}
                  value={woData[0].estimateCost}
                  defaultValue={woData[0].estimateCost}
                  fixedDecimalScale={true}
                  decimalScale={2}
                  disabled={readOnly}
                  //displayType={'text'}
                  //disabled={isFormReadOnly}
                  onValueChange={(e) =>
                    this.onTextChange("estimateCost", e.value, e)
                  }
                  thousandSeparator={true}
                  prefix={"$"}
                />
              </Col>
            </Row>

            <Row
              form
              // style={{marginTop:"15px"}}
            >
              <Col md={2} className={"alway-in-between"}>
                <Label style={{ margin: "0px 10px 0px 0px" }}>Priority</Label>
              </Col>
              <Label style={{ margin: "0px 10px 0px 0px" }}>|</Label>
              <Col md={6} className={"alway-in-between"}>
                <Label style={{ margin: "0px 10px 0px 0px" }}>Urgent?</Label>
                <CustomInput
                  type="checkbox"
                  // className={"alway-in-between3 "}
                  id="ableToValidateYes"
                  label="Yes"
                  checked={isPriority}
                  inline
                  // disabled={readOnly}
                  disabled
                  onChange={(e) => this.onTextChange("isPriority", 1, e)}
                />

                <CustomInput
                  type="checkbox"
                  //   className={"alway-in-between3 "}
                  id="ableToValidateNo"
                  label="No"
                  checked={!isPriority}
                  inline
                  // disabled={readOnly}
                  disabled
                  onChange={(e) => this.onTextChange("isPriority", 0, e)}
                />
              </Col>
            </Row>

            <Row form>
              <Col md={12}>
                <Select
                  options={priorityList}
                  className={!validDataItem.woPriority ? "form-error" : ""}
                  value={this.getSelPriority(woData[0].woPriority)}
                  //    isDisabled={isPriority == false || readOnly}
                  onChange={(e) => this.onTextChange("priority", e, e)}
                  isClearable={true}
                ></Select>
              </Col>
            </Row>
            <Row form>
              <Col md={6} className={"alway-in-between-less"}>
                <Label for="assetID">Available Start Date</Label>
                <DateTime
                  id="startDate"
                  className={!validDataItem.availStartDate ? "form-error" : ""}
                  //disabled={isFormReadOnly}
                  isDisabled={woData[0].fiixWOStatus === "Completed"}
                  onChange={(v, f) => this.onTextChange("availStartDate", v, f)}
                  value={moment(woData[0].availStartDate)}
                />
              </Col>

              <Col md={6} className={"alway-in-between-less"}>
                <Label for="assetID">Available End Date</Label>
                <DateTime
                  id="endDate"
                  className={!validDataItem.availEndDate ? "form-error" : ""}
                  //disabled={isFormReadOnly}

                  isDisabled={woData[0].fiixWOStatus === "Completed"}
                  onChange={(v, f) => this.onTextChange("availEndDate", v, f)}
                  value={moment(woData[0].availEndDate)}
                />
              </Col>
            </Row>

            <FormGroup style={{ marginTop: "15px" }}>
              <Nav tabs>
                <NavItem>
                  <NavLink
                    className={classnames({ active: activeTab === "1" })}
                    onClick={() => {
                      this.toggleTab("1");
                    }}
                  >
                    Summary of Issue
                  </NavLink>
                </NavItem>
                <NavItem>
                  <NavLink
                    className={classnames({ active: activeTab === "10" })}
                    onClick={() => {
                      this.toggleTab("10");
                    }}
                  >
                    Work Instructions
                  </NavLink>
                </NavItem>
                    
                {/* <NavItem>
                  <NavLink
                    className={classnames({ active: activeTab === "3" })}
                    onClick={() => {
                      this.toggleTab("3");
                    }}
                    hidden={!readOnly}
                  >
                    Task & Instructions
                  </NavLink>
                </NavItem> */}
                <NavItem>
                  <NavLink
                    className={classnames({ active: activeTab === "7" })}
                    onClick={() => {
                      this.toggleTab("7");
                    }}
                    hidden={!readOnly}
                  >
                    Labor Task
                  </NavLink>
                </NavItem>
                <NavItem>
                  <NavLink
                    className={classnames({ active: activeTab === "4" })}
                    onClick={() => {
                      this.toggleTab("4");
                    }}
                    hidden={!readOnly}
                  >
                    Comments
                  </NavLink>
                </NavItem>

                <NavItem>
                  <NavLink
                    className={classnames({ active: activeTab === "2" })}
                    onClick={() => {
                      this.toggleTab("2");
                    }}
                  >
                    Documents
                  </NavLink>
                </NavItem>
              </Nav>

              <TabContent activeTab={activeTab}>
                <TabPane tabId="1">
                  {/* <Label for="workOrderDesc">Description</Label> */}
                  <Input
                    type="textarea"
                    name="workOrderDesc"
                    id="workOrderDesc"
                    rows={5}
                    className={!validDataItem.workOrderDesc ? "form-error" : ""}
                    onChange={(e) =>
                      this.onTextChange("workOrderDesc", e.target.value, e)
                    }
                    disabled={readOnly}
                    //disabled={isReadOnly}
                    value={woData[0].workOrderDesc}
                    defaultValue={woData[0].workOrderDesc}
                  />
                </TabPane>
                <TabPane tabId="10">
                  <Input
                    type="textarea"
                    name="workInstruction"
                    id="workInstruction"
                    rows={5}
                    height="150px"
                    onChange={(e) =>
                      this.onTextChange("fiixWorkInstruction", e.target.value, e)
                    }
                    value={woData[0].fiixWorkInstruction}
                    defaultValue={woData[0].fiixWorkInstruction}
                  />
                </TabPane>                

                <TabPane tabId="2">
                  <Row>
                    <Col md={12}>
                      <DocumentBrowserDragDrop
                        buttonLabel="View Image"
                        buttonColor="primary"
                        formName="Workorder Images"
                        linkTableName={"topWorkOrder"}
                        linkPrimaryKey={
                          workOrderKey < 0 ? offLineLinkKey : workOrderKey
                        }
                        apiURL={
                          apiURL +
                          "Fiix/Workorder/Documents/" +
                          (workOrderKey < 0 ? offLineLinkKey : workOrderKey)
                        }
                        // woData = {woData}
                      />
                    </Col>
                  </Row>
                </TabPane>

                <TabPane tabId="3">
                  <Row>
                    <Col md={12}>
                      {/* <Label for="fiixWorkInstruction">Work Instructions</Label> */}
                      <Input
                        type="textarea"
                        name="workInstruction"
                        id="fiixWorkInstruction"
                        rows={5}
                        onChange={(e) =>
                          this.onTextChange(
                            "fiixWorkInstruction",
                            e.target.value,
                            e
                          )
                        }
                        disabled={readOnly}
                        //disabled={isReadOnly}
                        value={woData[0].fiixWorkInstruction}
                        defaultValue={woData[0].fiixWorkInstruction}
                      />
                    </Col>
                  </Row>
                </TabPane>

                <TabPane tabId="4">
                  <WOComment
                    user={this.props.user}
                    workOrderKey={workOrderKey}
                    readOnly={readOnly}
                    fiixWOId={fiixWOId}
                  ></WOComment>
                </TabPane>

                {/* <TabPane tabId="4">
                  <WOComment
                    user={this.props.user}
                    workOrderKey={workOrderKey}
                    readOnly={readOnly}
                    fiixWOId={fiixWOId}
                  ></WOComment>
                </TabPane> */}
              </TabContent>
            </FormGroup>

            <Form>
              <Row>
                <Col md={12}>
                  <div className="float-right">
                    <Button
                      color="primary"
                      className="float-right"
                      onClick={() => this.closeForm()}
                    >
                      Close
                    </Button>

                    <Button
                      color="primary"
                      style={{ margin: "0px 10px 0px 0px" }}
                      hidden={readOnly}
                      onClick={() => this.saveData()}
                    >
                      OK
                    </Button>
                  </div>
                </Col>
              </Row>
            </Form>
          </Form>

          <div>
            <Modal
              isOpen={this.state.modalQRCodeScanner}
              toggle={() => this.toggleQRCodeScanner()}
              size="sm"
              backdrop={"static"}
              //onClosed={() => this.getAFEdata()}
            >
              <ModalHeader toggle={() => this.toggleQRCodeScanner()}>
                QR Code Scaner
              </ModalHeader>
              <ModalBody>
                <QrReader
                  constraints={{ facingMode: "environment" }}
                  onResult={(result, error) => {
                    if (!!result) {
                      //this.setState({ qrValue: result?.text });
                      this.setQRCodeValue(result?.text);
                    }

                    if (!!error) {
                      console.info(error);
                    }
                  }}
                  style={{ width: "100%" }}
                />
              </ModalBody>
            </Modal>
          </div>

          {/* <div>
            <Modal
              isOpen={this.state.modalMsg}
              toggle={() => this.toggleMsg()}
              size="lg"
            >
              <ModalHeader toggle={() => this.toggleMsg()}>
                {msgHeader}
              </ModalHeader>
              <ModalBody>{msgBody}</ModalBody>
              <ModalFooter>
                <Button color="primary" onClick={() => this.toggleMsg()}>
                  OK
                </Button>{" "}
              </ModalFooter>
            </Modal>
          </div> */}

                      {/* <WODuplicateModal
                            isOpen={isDuplicateWOModalOpen} // Pass modal open state
                            toggle={this.toggleDuplicateWOModal} // Pass toggle function
                            color="danger"
                            buttonLabel="Send Back"
                            formName="Duplicate Work Orders"
                            woDataList={woDataList}
                            message="Duplicate work orders found. Please review."
                            onClickYes={() => {
                              console.log("Confirmed duplicate work orders");
                              this.toggleDuplicateWOModal(); // Close modal
                            }}
                          /> */}


                     <WODuplicateModal
                      isOpen={this.state.isDuplicateWOModalOpen}
                      toggle={this.toggleDuplicateWOModal}
                      color="danger"
                      className="float-right"
                      formName="Duplicate Work Orders"
                      id={workOrderKey}
                      mocKey={workOrderKey}
                      woDataList={woDataList}
                      message="A work order for this asset and maintenance type already exists. Do you want to continue?"
                      commentPrompMsg="Please enter comments:"
                      onClickYes={() => this.submitData()}
                    ></WODuplicateModal>                    

        </div>
   
      );

    }
    else
      return (
        <div>
          <Form onSubmit={(e) => e.preventDefault()}>
            <Row form className="ml-6">
              <Col
                md={{
                  offset: 0,
                  size: 4,
                }}
                className={
                  !validDataItem.assetID
                    ? "alway-on-top-more-more form-error"
                    : "alway-on-top-more-more"
                }
              >
                <Label for="assetID">Qr Code </Label>
                <Creatable
                  options={assetList}
                  className={
                    !validDataItem.assetID
                      ? "alway-on-top-more-more form-error"
                      : ""
                  }
                  value={this.getSelAsset(
                    woData[0].qrCodeValue == null
                      ? woData[0].assetID
                      : woData[0].qrCodeValue
                  )}
                  // isDisabled={readOnly && isSecurityAdminLogin}
                  isDisabled={this.isRejectedStatusFields()}
                  onChange={(e) => this.onTextChange("assetID", e, e)}
                  onCreateOption={(newQRCode) => this.onCreateQRCode(newQRCode)}
                  placeholder={"Select..."}
                  isClearable={true}
                ></Creatable>
              </Col>

              <Col md={1}>
                <Label for="button"> </Label>
                <Button
                  color="primary"
                  style={{ margin: "0px 20px 0px 20px" }}
                  // disabled={readOnly}
                  disabled={this.isRejectedStatusFields()}
                  onClick={() => this.toggleQRCodeScanner()}
                >
                  QR
                </Button>
              </Col>

              <Col
                md={{
                  offset: 1,
                  size: 5,
                }}
              >
                <Label for="assetID">Asset Name</Label>
                <Input
                  type="text"
                  name="requestedBy"
                  id="requestedBy"
                  disabled={true}
                  defaultValue={woData[0].assetName}
                  value={woData[0].assetName}
                />
              </Col>
            </Row>
            <Row form className="ml-6">
              <Col
                md={{
                  offset: 0,
                  size: 5,
                }}
                className={"alway-on-top-more"}
              >
                <Label for="assetID">Facility</Label>
                <Select
                  options={facilityList}
                  // isDisabled={readOnly && isSecurityAdminLogin}
                  isDisabled={this.isRejectedStatusFields()}
                  className={
                    !validDataItem.facilityID
                      ? "alway-on-top-more form-error"
                      : "alway-on-top-more"
                  }
                  value={this.getSelFacility(woData[0].facilityKey)}
                  onChange={(e) => this.onTextChange("facilityID", e, e)}
                  placeholder={""}
                  isClearable={true}
                ></Select>
              </Col>

              <Col
                md={{
                  offset: 1,
                  size: 5,
                }}
              >
                <Label hidden={readOnly == false} for="requestDate">
                  Status
                </Label>
                <Input
                  type="text"
                  name="assetID"
                  id="assetID"
                  hidden={readOnly == false}
                  disabled={readOnly}
                  // onChange={(e) => this.onTextChange("createdDateTime", e.target.value, e)}
                  // disabled={true}
                  // defaultValue={FormatDate(woData[0].createdDateTime)}
                  value={woData[0].fiixWOStatus}
                />
              </Col>
            </Row>

            <Row form className="ml-6">
              <Col
                md={{
                  offset: 0,
                  size: 5,
                }}
                className={"alway-on-top"}
              >
                <Label hidden={readOnly == false} for="basin">
                  Basin
                </Label>
                <Select
                  options={basinList}
                  // isDisabled={(woData[0].fiixWOstatus != "Rejected" && woData[0].createdBy != getUserADAccount(user.account.userName)) || !isSecurityAdminLogin}
                  isDisabled={this.isRejectedStatusFields()}
                  className={
                    !validDataItem.locationKey
                      ? "alway-on-top form-error"
                      : "alway-on-top"
                  }
                  value={this.getSelBasin(woData[0].locationKey)}
                  onChange={(e) => this.onTextChange("basin", e, e)}
                  placeholder={""}
                  isClearable={true}
                ></Select>
              </Col>

              <Col
                md={{
                  offset: 1,
                  size: 5,
                }}
              >
                <Label for="requestedBy">Requested By</Label>
                <Input
                  type="text"
                  name="requestedBy"
                  id="requestedBy"
                  disabled={true}
                  defaultValue={woData[0].createdByName}
                  value={woData[0].createdByName}
                />
              </Col>
            </Row>

            <Row form className="ml-6">
              <Col
                md={{
                  offset: 0,
                  size: 5,
                }}
                className={"alway-on-top-fiix"}
              >
                <Label for="assetID">Maintenance Type</Label>
                <Select
                  // options={this.getMainTypeList()}
                  options={mainTypeList}
                  // isDisabled={isSecurityAdminLogin}
                  isDisabled={this.isRejectedStatusFields()}
                  className={
                    !validDataItem.maintenanceType
                      ? "alway-on-top-fiix form-error"
                      : "alway-on-top-fiix"
                  }
                  value={this.getSelMainType(woData[0].maintenanceType)}
                  placeholder={""}
                  onChange={(e) => this.onTextChange("maintenanceType", e, e)}
                  isClearable={true}
                ></Select>
              </Col>

              <Col
                md={{
                  offset: 1,
                  size: 5,
                }}
              >
                <Label for="requestDate">Requested Date</Label>
                <Input
                  type="text"
                  name="assetID"
                  id="assetID"
                  onChange={(e) =>
                    this.onTextChange("createdDateTime", e.target.value, e)
                  }
                  disabled={true}
                  // hidden={console.log(this.FormatDateWithTime(woData[0].reqestDateTime))}
                  defaultValue={this.FormatDateWithTime(
                    woData[0].reqestDateTime
                  )}
                  value={this.FormatDateWithTime(woData[0].reqestDateTime)}
                />
              </Col>
            </Row>

            <Row form className="ml-6">
              <Col
                md={{
                  offset: 0,
                  size: 5,
                }}
                // className={"alway-on-top"}
              >
                <Label for="assetID">Issue Type</Label>
                <Select
                  key={issueTypeList.length}
                  options={issueTypeList}
                  // isDisabled={isSecurityAdminLogin && woData[0].fiixWOStatus !== "Rejected"}
                  // isDisabled={
                  //   isSecurityAdminLogin &&
                  //   woData[0].fiixWOStatus !== "Need Revision"
                  // }
                  isDisabled={this.isRejectedStatusFields()}
                  // isDisabled={
                  //   (isSecurityAdminLogin && isApprover) ||
                  //   (woData[0].fiixWOStatus !== "Requires Approval" &&
                  //     woData[0].fiixWOStatus != "Rejected")
                  // }
                  className={
                    !validDataItem.fiixIssueType
                      ? "alway-in-between-more form-error"
                      : "alway-in-between-more"
                  }
                  value={this.getSelIssueType(woData[0].fiixIssueType)}
                  placeholder={""}
                  onChange={(e) => this.onTextChange("fiixIssueType", e, e)}
                  isClearable={true}
                ></Select>
              </Col>

              <Col md={{ offset: 1, size: 5 }}>
                <Label hidden={readOnly == false} for="createdBy">
                  Assigned To
                </Label>
                <div className="scrollable-container">
                  {this.transformNames(woData[0].assignedToUserName)}
                </div>
              </Col>
            </Row>

            <Row form className="ml-6 alway-in-between">
              <Col
                md={{
                  offset: 0,
                  size: 5,
                }}
                className={"alway-in-between"}
              >
                <Label>Priority | </Label>
                <Label style={{ margin: "0px 10px 0px 0px" }}>Urgent?</Label>
                <CustomInput
                  type="checkbox"
                  // className={"alway-in-between3 "}
                  id="ableToValidateYes"
                  label="Yes"
                  checked={isPriority}
                  inline
                  disabled={readOnly}
                  onChange={(e) => this.onTextChange("isPriority", 1, e)}
                />

                <CustomInput
                  type="checkbox"
                  //   className={"alway-in-between3 "}
                  id="ableToValidateNo"
                  label="No"
                  checked={!isPriority}
                  inline
                  disabled={readOnly}
                  onChange={(e) => this.onTextChange("isPriority", 0, e)}
                />

                <Select
                  options={priorityList}
                  className={!validDataItem.woPriority ? "form-error" : ""}
                  value={this.getSelPriority(woData[0].woPriority)}
                  // isDisabled={isPriority == false || readOnly}
                  isDisabled={this.isRejectedStatusFields()}
                  onChange={(e) => this.onTextChange("priority", e, e)}
                  isClearable={true}
                ></Select>
              </Col>

              <Col
                md={{
                  offset: 1,
                  size: 5,
                }}
              >
                <Label for="estimateCost">Estimated Total Cost</Label>
                <NumberFormat
                  className={"form-control text-md-right"}
                  value={woData[0].estimateCost}
                  defaultValue={woData[0].estimateCost}
                  fixedDecimalScale={true}
                  decimalScale={2}
                  // disabled={readOnly && woData[0].fiixWOStatus !== "Rejected"}
                  disabled={
                    readOnly && woData[0].fiixWOSTatus !== "Need Revision"
                  }
                  //displayType={'text'}
                  //disabled={isFormReadOnly}
                  onValueChange={(e) =>
                    this.onTextChange("estimateCost", e.value, e)
                  }
                  thousandSeparator={true}
                  prefix={"$"}
                />
              </Col>
            </Row>
            {/* Comment out by HN - 11-20-2022 until brandon fixed issue*/}

            <Row
              form
              // style={{marginTop:"15px"}}
              className="ml-6"
            >
              <Col
                md={{
                  offset: 0,
                  size: 5,
                }}
                className={"alway-in-between-less"}
              >
                <Label for="assetID">Available Start Date</Label>

                <DateTime
                  id="startDate"
                  className={!validDataItem.availStartDate ? "form-error" : ""}
                  inputProps={{
                    disabled:
                      (woData[0].fiixWOStatus === "Completed" ||
                        woData[0].fiixWOStatus === "Canceled" ||
                        woData[0].fiixWOStatus === "Closed") &&
                      isSecurityAdminLogin,
                  }}
                  onChange={(v, f) => this.onTextChange("availStartDate", v, f)}
                  value={moment(woData[0].availStartDate)}
                />
              </Col>

              <Col
                md={{
                  offset: 1,
                  size: 5,
                }}
                className={"alway-in-between-less"}
              >
                <Label for="assetID">Available End Date</Label>

                <DateTime
                  id="endDate"
                  className={!validDataItem.availEndDate ? "form-error" : ""}
                  //disabled={isFormReadOnly}

                  inputProps={{
                    disabled:
                      (woData[0].fiixWOStatus === "Completed" ||
                        woData[0].fiixWOStatus === "Canceled" ||
                        woData[0].fiixWOStatus === "Closed") &&
                      isSecurityAdminLogin,
                  }}
                  onChange={(v, f) => this.onTextChange("availEndDate", v, f)}
                  value={moment(woData[0].availEndDate)}
                />
              </Col>
            </Row>

            <hr></hr>

            <FormGroup style={{ marginTop: "15px" }}>
            <div style={{ display: 'flex', justifyContent: 'space-between', alignItems: 'center' }}>
  <Nav tabs style={{ flex: 1 }}>
    <NavItem>
      <NavLink
        className={classnames({ active: activeTab === "1" })}
        onClick={() => {
          this.toggleTab("1");
        }}
      >
        Summary of Issue
      </NavLink>
    </NavItem>

    <NavItem>
      <NavLink
        className={classnames({ active: activeTab === "10" })}
        onClick={() => {
          this.toggleTab("10");
        }}
      >
        Work Instructions
      </NavLink>
    </NavItem>
    

    {/* <NavItem>
      <NavLink
        className={classnames({ active: activeTab === "3" })}
        onClick={() => {
          this.toggleTab("3");
        }}
        hidden={!readOnly}
      >
        Task & Instructions
      </NavLink>
    </NavItem> */}

          <NavItem>
                  <NavLink
                    className={classnames({ active: activeTab === "6" })}
                    onClick={() => {
                      this.toggleTab("6");
                    }}
                  >
                    Parts & Labor Cost
                  </NavLink>
                </NavItem>

    <NavItem>
      <NavLink
        className={classnames({ active: activeTab === "8" })}
        onClick={() => {
          this.toggleTab("8");
        }}
        hidden={!readOnly}
      >
        Approval
      </NavLink>
    </NavItem>

    <NavItem>
      <NavLink
        className={classnames({ active: activeTab === "9" })}
        onClick={() => {
          this.toggleTab("9");
        }}
        hidden // Hidden by BN 2/1/24
      >
        Approval Comments
      </NavLink>
    </NavItem>

    <NavItem>
      <NavLink
        className={classnames({ active: activeTab === "4" })}
        onClick={() => {
          this.toggleTab("4");
        }}
        hidden={!readOnly}
      >
        Comments
      </NavLink>
    </NavItem>

    <NavItem>
      <NavLink
        className={classnames({ active: activeTab === "2" })}
        onClick={() => {
          this.toggleTab("2");
        }}
      >
        Documents
      </NavLink>
    </NavItem>
  </Nav>

</div>

              {/* Second Row */}
              {/* <Nav tabs>
                <NavItem>
                  <NavLink
                    className={classnames({ active: activeTab === "7" })}
                    onClick={() => {
                      this.toggleTab("7");
                    }}
                    hidden={!readOnly}
                  >
                    Labor Task
                  </NavLink>
                </NavItem> */}


                {/* <NavItem>
                  <NavLink
                    className={classnames({ active: activeTab === "5" })}
                    onClick={() => {
                      this.toggleTab("5");
                    }}
                  >
                    Completion Info
                  </NavLink>
                </NavItem> */}
              {/* </Nav> */}

              <TabContent
                className="tab-content-fixed-height"
                activeTab={activeTab}
              >
                <TabPane tabId="1">
                  {/* <Label for="workOrderDesc">Description</Label> */}
                  <Input
                    type="textarea"
                    name="workOrderDesc"
                    id="workOrderDesc"
                    rows={8}
                    height="150px"
                    onChange={(e) =>
                      this.onTextChange("workOrderDesc", e.target.value, e)
                    }
                    disabled={this.isRejectedStatusFields()}
                    //disabled={isReadOnly}
                    value={woData[0].workOrderDesc}
                    defaultValue={woData[0].workOrderDesc}
                  />
                </TabPane>

                <TabPane tabId="10">
                  {/* <Label for="workOrderDesc">Description</Label> */}
                  <Input
                    type="textarea"
                    name="workInstruction"
                    id="workInstruction"
                    rows={8}
                    height="150px"
                    onChange={(e) =>
                      this.onTextChange("fiixWorkInstruction", e.target.value, e)
                    }
                    // disabled={this.isRejectedStatusFields()}
                    hidden={console.log(isSecurityAdminLogin)}
                    disabled={isSecurityAdminLogin ? 
                      (woData[0].fiixWOStatus === "Completed" || woData[0].fiixWOStatus === "Canceled" || woData[0].fiixWOStatus === "Deleted" || woData[0].fiixWOStatus === "Closed") 
                      : false}
                    value={woData[0].fiixWorkInstruction}
                    defaultValue={woData[0].fiixWorkInstruction}
                  />
                </TabPane>

                <TabPane tabId="2">
                  <Row>
                    <Col md={12}>
                      <DocumentBrowserDragDrop
                        buttonLabel="View Image"
                        buttonColor="primary"
                        formName="Workorder Images"
                        linkTableName={"topWorkOrder"}
                        linkPrimaryKey={
                          workOrderKey < 0 ? offLineLinkKey : workOrderKey
                        }
                        apiURL={
                          apiURL +
                          "Fiix/Workorder/Documents/" +
                          (workOrderKey < 0 ? offLineLinkKey : workOrderKey)
                        }
                        // woData = {woData}
                      />
                    </Col>
                  </Row>
                </TabPane>

                <TabPane tabId="3">
                  <Row>
                    <Col md={12}>
                      {/* <Label for="fiixWorkInstruction">Work Instructions</Label>  */}
                       <Input
                        type="textarea"
                        name="workInstruction"
                        id="fiixWorkInstruction"
                        rows={8}
                        height="200px"
                        onChange={(e) =>
                          this.onTextChange(
                            "fiixWorkInstruction",
                            e.target.value,
                            e
                          )
                        }
                        disabled={readOnly}
                        // disabled={isReadOnly}
                        value={woData[0].fiixWorkInstruction}
                        defaultValue={woData[0].fiixWorkInstruction}
                      /> 
                     </Col> 
                  </Row>
                  {/* <TaskAndInstructions
                    fiixWOStatus={fiixWOStatus}
                    user={this.props.user}
                    workOrderKey={workOrderKey}
                    readOnly={readOnly}
                    fiixWOId={fiixWOId}
                  ></TaskAndInstructions> */}
                </TabPane>

                <TabPane tabId="8">
                  <WOApproval
                    user={this.props.user}
                    workOrderKey={workOrderKey}
                    readOnly={readOnly}
                    fiixWOId={fiixWOId}
                    woApproval={woApproval}
                  ></WOApproval>
                </TabPane>

                {/* <TabPane tabId="9">             //Commented out by BN 2/1/24
                  <WOApprovalComment
                    user={this.props.user}
                    workOrderKey={workOrderKey}
                    readOnly={readOnly}
                    fiixWOId={fiixWOId}
                  ></WOApprovalComment>
                </TabPane> */}

                <TabPane tabId="4">
                  <WOComment
                    user={this.props.user}
                    workOrderKey={workOrderKey}
                    readOnly={readOnly}
                    fiixWOId={fiixWOId}
                  ></WOComment>
                </TabPane>

                  
                {/* <TabPane tabId="5">
                  <Row>
                    <Col md={6}>
                      <Label for="assetID">Completed By</Label>
                      <Input
                        type="text"
                        name="fiixWOCompletedByUser"
                        id="fiixWOCompletedByUser"
                        //onChange={(e) => this.onTextChange("costDescription", e.target.value, e)}
                        disabled={true}
                        defaultValue={woData[0].fiixWOCompletedByUser}
                        value={woData[0].fiixWOCompletedByUser}
                      />
                    </Col>
                    <Col md={6}>
                      <Label for="assetID">WO Completed Hours</Label>
                      <Input
                        type="number"
                        name="fiixCompletedHours"
                        id="fiixCompletedHours"
                        //onChange={(e) => this.onTextChange("costDescription", e.target.value, e)}
                        disabled={true}
                        defaultValue={woData[0].fiixCompletedHours}
                        value={woData[0].fiixCompletedHours}
                      />
                    </Col>
                  </Row>

                  <Row>
                    <Col md={6}>
                      <Label for="assetID">Completed By Vendor</Label>
                      <Input
                        type="text"
                        name="supplier_Name"
                        id="supplier_Name"
                        //onChange={(e) => this.onTextChange("costDescription", e.target.value, e)}
                        disabled={true}
                        defaultValue={woData[0].supplier_Name}
                        value={woData[0].supplier_Name}
                      />
                    </Col>
                    <Col md={6}>
                      <Label for="assetID">Vendor Cost</Label>
                      <Input
                        type="number"
                        name="fiixCompletedHours"
                        id="fiixCompletedHours"
                        //onChange={(e) => this.onTextChange("costDescription", e.target.value, e)}
                        disabled={true}
                        defaultValue={woData[0].fiixCompletedHours}
                        value={woData[0].fiixCompletedHours}
                      />
                    </Col>
                  </Row>

                  <Row>
                    <Col md={6}>
                      <Label for="assetID">Suggested Completion Date</Label>
                      <Input
                        type="text"
                        name="suggestedCompletionDate"
                        id="suggestedCompletionDate"
                        //onChange={(e) => this.onTextChange("costDescription", e.target.value, e)}
                        disabled={true}
                        defaultValue={
                          woData[0].suggestedCompletionDate === null
                            ? null
                            : moment(woData[0].suggestedCompletionDate).format(
                                "YYYY-MM-DD HH:mm:ss"
                              )
                        }
                        value={
                          woData[0].suggestedCompletionDate === null
                            ? null
                            : moment(woData[0].suggestedCompletionDate).format(
                                "YYYY-MM-DD HH:mm:ss"
                              )
                        }
                      />
                    </Col>

                    <Col md={6}>
                      <Label for="assetID">Actual Completed Date</Label>
                      <Input
                        type="text"
                        name="actualCompletedDate"
                        id="actualCompletedDate"
                        //onChange={(e) => this.onTextChange("costDescription", e.target.value, e)}
                        disabled={true}
                        defaultValue={
                          woData[0].fiixWODateCompleted === null
                            ? null
                            : moment(woData[0].fiixWODateCompleted).format(
                                "YYYY-MM-DD HH:mm:ss"
                              )
                        }
                        value={
                          woData[0].fiixWODateCompleted === null
                            ? null
                            : moment(woData[0].fiixWODateCompleted).format(
                                "YYYY-MM-DD HH:mm:ss"
                              )
                        }
                      />
                    </Col>
                  </Row>

                  <Row>
                    <Col md={12}>
                      <Label for="assetID">Completion Notes</Label>
                      <Input
                        type="textarea"
                        name="completionNotes"
                        id="completionNotes"
                        rows={3}
                        //onChange={(e) => this.onTextChange("costDescription", e.target.value, e)}
                        disabled={true}
                        defaultValue={woData[0].fiixWOCompletionNotes}
                        value={woData[0].fiixWOCompletionNotes}
                      />
                    </Col>
                  </Row>
                </TabPane> */}

                <TabPane tabId="6">
                  <FormGroup>
                    <Row form style={{ zIndex: 0, position: "relative" }}>
                      <IgrDataGrid
                        // ref={(grid) => this.onGridRef(grid, "woReportSummary")}
                        ref={(grid) => this.onGridRef(grid, "partsAndCostData")}
                        height={"250px"}
                        width={"100%"}
                        className="scrollable-grid"
                        isColumnOptionsEnabled="true"
                        editMode={0}
                        dataSource={partsAndCostData}
                        autoGenerateColumns="false"
                        selectionMode="SingleRow"
                        summaryScope="Both"
                        selectedKeysChanged={this.onGridRowSelected}
                        cellClicked={this.onGridRowSelected2}

                        // selectedKeysChanged={this.onGridRowSelected}
                      >
                        {/* <IgrTextColumn
                          field="woCostTypeDesc"
                          headerText="Cost Type"
                          width="*>150"
                          cellUpdating={this.onStatusCellUpdating}
                        />    */}                         
                        <IgrTemplateColumn
                          field="description"
                          headerText="Description"
                          width="*>250"
                          cellUpdating={this.onStatusCellUpdating}
                        />
                         <IgrTemplateColumn
                          field="instructions"
                          headerText="Work Instructions"
                          width="*>250"
                          cellUpdating={this.onStatusCellUpdating}
                        />
                          <IgrTextColumn
                          field="status"
                          headerText="Status"
                          width="*>150"
                          cellUpdating={this.onStatusCellUpdating}
                        />                         
                         {/* <IgrTemplateColumn
                          field="quantity"
                          headerText="Quantity"
                          width="*>250"
                          cellUpdating={this.onStatusCellUpdating}
                        />                        */}
                        <IgrNumericColumn
                          field="quantity"
                          headerText="Quantity"
                          width="*>100"
                          minFractionDigits={2} // Display at least 1 fractional digit
                          maxFractionDigits={2} // Display at most 1 fractional digit
                          cellUpdating={this.onStatusCellUpdating}
                        />
                        <IgrNumericColumn
                          field="unitCost"
                          headerText="Unit Cost"
                          positivePrefix="$"
                          maxFractionDigits={2}
                          minFractionDigits={2}
                          width="*>100"
                          cellUpdating={this.onStatusCellUpdating}
                        />
                        <IgrNumericColumn
                          field="totalCost"
                          headerText="Total Cost"
                          positivePrefix="$"
                          maxFractionDigits={2}
                          minFractionDigits={2}
                          showGroupingSeparator="true"
                          width="*>200"
                          cellUpdating={this.onStatusCellUpdating}
                        />
                      </IgrDataGrid>
                    </Row>
                  </FormGroup>
                </TabPane>

                <TabPane tabId="7">
                  <FormGroup>
                    <Row
                    // form style={{ zIndex: 0, position: "relative" }}
                    >
                      <IgrDataGrid
                        ref={(grid) => this.onGridRef(grid, "woTaskData")}
                        height={"250px"}
                        width={"100%"}
                        className="scrollable-grid"
                        isColumnOptionsEnabled="true"
                        editMode={0}
                        dataSource={woTaskData}
                        autoGenerateColumns="false"
                        selectionMode="SingleRow"
                      >
                        <IgrTextColumn
                          field="woNumber"
                          headerText="WO Number"
                          width="*>150"
                        />
                        <IgrTextColumn
                          field="strFullName"
                          headerText="Assigned To"
                          width="*>150"
                        />
                        <IgrTextColumn
                          field="strDescription"
                          headerText="Description"
                          width="*>300"
                        />
                        <IgrNumericColumn
                          field="dblTimeEstimatedHours"
                          headerText="Estimated Hours"
                          width="*>180"
                        />
                        <IgrNumericColumn
                          field="dblTimeSpendHours"
                          headerText="Actual Hours"
                          width="*>150"
                        />
                      </IgrDataGrid>
                    </Row>
                  </FormGroup>
                </TabPane>


                {/* <TabPane tabId="10"> */}
                  {/* <FormGroup>
                    <Row
                    // form style={{ zIndex: 0, position: "relative" }}
                    > */}
                      {/* <IgrDataGrid
                        ref={(grid) => this.onGridRef(grid, "woLogHistory")}
                        height={"250px"}
                        width={"100%"}
                        className="scrollable-grid"
                        isColumnOptionsEnabled="true"
                        editMode={0}
                        dataSource={woLogHistory}
                        autoGenerateColumns="false"
                        selectionMode="SingleRow"
                      >
                        <IgrTextColumn
                          field="woNumber"
                          headerText="WO Number"
                          width="*>150"
                        />
                        <IgrTextColumn
                          field="assetName"
                          headerText="Asset"
                          width="*>150"
                        />
                        <IgrTextColumn
                          field="fiixWOStatus"
                          headerText="Status"
                          width="*>250"
                        />
                        <IgrTextColumn
                          field="maintenanceType"
                          headerText="Maintenance Type"
                          width="*>250"
                        />
                         <IgrTextColumn
                          field="fiixIssueType"
                          headerText="Issue Type"
                          width="*>250"
                        />                       
                        <IgrTextColumn
                          field="assignedToUserName"
                          headerText="Assigned To"
                          width="*>300"
                        />  
                        <IgrTextColumn
                          field="approvedStatus"
                          headerText="Approval Status"
                          width="*>300"
                        />                                 
                        <IgrTextColumn
                          field="approvers"
                          headerText="Approvers"
                          width="*>300"
                        />  
                        <IgrTextColumn
                          field="approvedUser"
                          headerText="Approved User"
                          width="*>300"
                        />
                        <IgrTextColumn
                          field="approvalComments"
                          headerText="Approval Comments"
                          width="*>300"
                        />                        
                         <IgrTextColumn
                          field="approvedDate"
                          headerText="Approved Date"
                          width="*>300"
                        />   
                         
                        <IgrTextColumn
                          field="createdByName"
                          headerText="Created By"
                          width="*>300"
                        />

                         <IgrTextColumn
                          field="reqestDateTime"
                          headerText="Created Date"
                          width="*>300"
                        />                       
                        
                        <IgrTextColumn
                          field="modifiedByName"
                          headerText="Modified By"
                          width="*>300"
                        /> 
                        <IgrTextColumn
                          field="modifiedDateTime"
                          headerText="Modified Date"
                          width="*>300"
                        /> 
                        <IgrTextColumn
                          field="userAction"
                          headerText="User Action"
                          width="*>300"
                        /> 
                         <IgrTextColumn
                          field="actionBy"
                          headerText="Action By"
                          width="*>300"
                        /> 
                         <IgrTextColumn
                          field="actionDateTime"
                          headerText="Action Date"
                          width="*>300"
                        />                                                                                                                                                                                                                                                                                          
                        <IgrNumericColumn
                          field="estimateCost"
                          headerText="Estimate Cost"
                          width="*>150"
                        />
                      </IgrDataGrid>
                    </Row>
                  </FormGroup> */}
                {/* </TabPane> */}
              </TabContent>
            </FormGroup>

            <FormGroup>
              <Row>
                <Col md={3}></Col>

                <Col md={4}></Col>
              </Row>
            </FormGroup>
            <Form>
              <Row>
                <Col md={12}>
                  {/* <ConfirmFiixWOComplete
                    outline={true}
                    style={{ margin: "0px 0px 0px 0px" }}
                    color="primary"
                    className="float-left"
                    buttonLabel="Complete"
                    hidden={
                      woData[0].fiixWOStatus === "Completed" ||
                      woData[0].fiixWOStatus !== "Work In Progress"
                        ? true
                        : false
                    }
                    formName="Complete"
                    id={woData[0].workOrderKey}
                    message="Are you sure you want to complete the Work Order?"
                    invalid={!invalidDataItem.completedHours}
                    invalidDate={!invalidDataItem.completedDate}
                    invalidIssueType={!invalidDataItem.fiixIssueType}
                    onChangeTest4={(e) =>
                      this.onTextChange("fiixIssueType", e, e)
                    }
                    onChangeTest={(e) =>
                      this.onTextChange("completedHours", e.target.value, e)
                    }
                    onChangeTest2={(v, f) =>
                      this.onTextChange("completedDate", v, f)
                    } //Commented out by BN 7/11
                    onChangeTest3={(e) =>
                      this.onTextChange("completedUser", e, e)
                    } //Commented out by BN 7/11
                    onClickNo={(e) => this.noValue()}
                    onClickYes={(e) => this.completeData()}
                    empList={empList}
                    issueTypeListFiltered={issueTypeListFiltered}
                    completedDate={completedDate}
                    completedUser={completedUser}
                    getSelEmpList={this.getSelEmpList}
                    isSecurityAdminLogin={isSecurityAdminLogin}
                    getSelIssueType={this.getSelIssueType}
                    woData={woData}
                  ></ConfirmFiixWOComplete> */}

                  {/* <ConfirmModal
                    outline={true}
                    style={{ margin: "0px 0px 0px 0px" }}
                    color="primary"
                    className="float-left"
                    buttonLabel="Work In Progress"
                    hidden={
                      woData[0].fiixWOStatus === "Requires Approval"
                        ? true
                        : // isSecurityAdminLogin === false
                        //   ? false:
                        woData[0].fiixWOStatus === "Canceled" ||
                          woData[0].fiixWOStatus === "Completed" ||
                          woData[0].fiixWOStatus === "Work In Progress" ||
                          woData[0].fiixWOStatus === "Closed" ||
                          woData[0].fiixWOStatus === "Rejected" ||
                          woData[0].fiixWOStatus === "Need Revision"
                        ? true
                        : false
                    }
                    // disabled={console.log(woData[0].fiixWOStatus)}
                    formName="Complete"
                    id={woData[0].workOrderKey}
                    message="Are you sure you want to change Work order to Work In Progress?"
                    onClickYes={(e) => this.workInProgressData()}
                  ></ConfirmModal> */}

                  {/* <ConfirmModal
          outline={true}
          style={{ margin: "0px 0px 0px 0px" }}
          color="primary"
          className="float-left"
          buttonLabel="Work In Progress"
          hidden={!this.state.isWorkInProgressModalOpen}
          formName="Complete"
          id={woData[0].workOrderKey}
          message="Are you sure you want to change Work order to Work In Progress?"
          onClickYes={this.workInProgressData}
          onClosed={this.toggleWorkInProgressModal}
        /> */}

                  {/* <ConfirmMeterValModal
                    outline={false}
                    style={{ margin: "0px 0px 0px 10px" }}
                    color="danger"
                    className="float-left"
                    buttonLabel="On Hold"
                    hidden={
                      woData[0].fiixWOStatus === "Requires Approval"
                        ? true
                        : // isSecurityAdminLogin === false
                        //   ? false:
                        woData[0].fiixWOStatus === "Canceled" ||
                          woData[0].fiixWOStatus === "Completed" ||
                          woData[0].fiixWOStatus === "On Hold" ||
                          woData[0].fiixWOStatus === "Closed" ||
                          woData[0].fiixWOStatus === "Rejected" ||
                          woData[0].fiixWOStatus === "Need Revision"
                        ? true
                        : false
                    }
                    invalid={!invalidDataItem.comments}
                    formName="On Hold"
                    id={woData[0].workOrderKey}
                    message="Are you sure you want to put work order on hold?"
                    onChangeTest={(e) =>
                      this.onTextChange("onHoldComments", e.target.value, e)
                    }
                    onClickYes={(e) => this.onHoldData()}
                  /> */}

                  {/* <ConfirmModal
                    // outline={true}
                    style={{ margin: "0px 0px 0px 10px" }}
                    color="danger"
                    className="float-left"
                    buttonLabel="Cancel"
                    hidden={
                      woData[0].fiixWOStatus === "Completed" ||
                      woData[0].fiixWOStatus === "Canceled" ||
                      woData[0].fiixWOStatus === "Closed"
                    }
                    formName="Cancel"
                    id={woData[0].workOrderKey}
                    message="Are you sure you want to cancel Work Order?"
                    onClickYes={(e) => this.cancelData()}
                  ></ConfirmModal> */}

                  <div className="button-container">
                  <Button onClick={() => this.toggleModalHistoryLog()} style={{ marginRight: 'auto',}}>
                        Log History
                  </Button>


                    <ConfirmModal
                      // outline={true}
                      color="primary"
                      // className="float-right"
                      buttonLabel="Save"
                      // hidden={
                      //   woData[0].fiixWOStatus !== "Rejected"
                      // }
                      hidden
                      formName="Resubmit"
                      id={woData[0].workOrderKey}
                      message="Are you sure you want to resubmit Work Order?"
                      onClickYes={(e) => this.resubmitData()}
                    ></ConfirmModal>

                    <ButtonDropdown
                      isOpen={this.state.dropdownOpen}
                      toggle={this.toggleDropDown}
                      style={{ marginRight: "-50px" }}
                      // hidden={
                      //   (woData[0].fiixWOStatus === "Need Revision" &&
                      //     woData[0].createdBy !==
                      //       getUserADAccount(user.account.userName)) ||
                      //   (this.state.isSecurityAdminLogin &&
                      //     ![
                      //       "Requested",
                      //       "Requires Approval",
                      //       "Assigned/Scheduled",
                      //       "Work In Progress",
                      //       "On Hold",
                      //       "Rejected",
                      //       "Need Revision",
                      //     ].includes(woData[0].fiixWOStatus))
                      // }
                      hidden={
                        this.state.isSecurityAdminLogin &&
                        (
                          (woData[0].fiixWOStatus === "Need Revision" &&
                            woData[0].createdBy.toLowerCase() !== getUserADAccount(user.account.userName).toLowerCase()) ||
                          ![
                            "Requested",
                            "Requires Approval",
                            "Assigned/Scheduled",
                            "Work In Progress",
                            "On Hold",
                            "Rejected",
                            "Need Revision",
                          ].includes(woData[0].fiixWOStatus)
                        )
                      }
                      // disabled={console.log(this.state.isSecurityAdminLogin)}
                    >
                      <DropdownToggle>Action</DropdownToggle>

                      <DropdownMenu caret size="lg">
                        {/* ------------------------------------------------------------------------------------------------------------------------------------------------------------------------- */}
                        <DropdownItem
                          onClick={this.toggleApproveModal}
                          hidden={
                            woData[0].fiixWOStatus !== "Requires Approval" ||
                            !woData[0].approvers
                              .toLowerCase()
                              .split(",")
                              .includes(user.account.userName.toLowerCase())
                          }
                        >
                          Approve
                        </DropdownItem>

                        {/* ------------------------------------------------------------------------------------------------------------------------------------------------------------------------- */}
                        <DropdownItem
                          onClick={this.toggleRerouteModal}
                          hidden={
                            ![
                              "Requested",
                              "Requires Approval",
                              "Assigned/Scheduled",
                              "Work In Progress",
                              "On Hold",
                              "Rejected",
                            ].includes(woData[0].fiixWOStatus)
                            // ||
                            // (
                            //   (
                            //     !woData[0].approvers
                            //       .toLowerCase()
                            //       .split(",")
                            //       .includes(user.account.userName.toLowerCase())
                            //   ) &&
                            //   this.rerouteAssignedUser() &&
                            //   !mainTypeApprovalList.some(item => item.fixxMainTypeDesc === woData[0].maintenanceType)
                            // )
                          }
                        >
                          Reroute
                        </DropdownItem>
                        {/* ------------------------------------------------------------------------------------------------------------------------------------------------------------------------- */}
                        <DropdownItem
                          onClick={this.toggleRejectModal}
                          hidden={
                            woData[0].fiixWOStatus === "Completed" ||
                            !(
                              (woData[0].fiixWOStatus ===
                                "Assigned/Scheduled" &&
                                woData[0].fiixAssignedToUser &&
                                woData[0].fiixAssignedToUser
                                  .toLowerCase()
                                  .split(";")
                                  .includes(
                                    user.account.userName.toLowerCase()
                                  )) ||
                              (woData[0].approvers &&
                                woData[0].approvers
                                  .toLowerCase()
                                  .split(",")
                                  .includes(
                                    user.account.userName.toLowerCase()
                                  )) ||
                              (woData[0].assignedToADAccount &&
                                woData[0].assignedToADAccount
                                  .toLowerCase()
                                  .split(",")
                                  .includes(
                                    getUserADAccount(
                                      user.account.userName
                                    ).toLowerCase()
                                  ))
                            )
                          }
                        >
                          Send Back
                        </DropdownItem>

                        {/* ------------------------------------------------------------------------------------------------------------------------------------------------------------------------- */}
                        <DropdownItem
                          hidden={
                            woData[0].fiixWOStatus === "Requires Approval"
                              ? true
                              : // isSecurityAdminLogin === false
                              //   ? false:
                              woData[0].fiixWOStatus === "Canceled" ||
                                woData[0].fiixWOStatus === "Completed" ||
                                woData[0].fiixWOStatus === "Work In Progress" ||
                                woData[0].fiixWOStatus === "Closed" ||
                                woData[0].fiixWOStatus === "Rejected" ||
                                woData[0].fiixWOStatus === "Need Revision"
                              ? true
                              : false
                          }
                          onClick={this.toggleWIPModal}
                        >
                          Work in Progress
                        </DropdownItem>

                        {/* ------------------------------------------------------------------------------------------------------------------------------------------------------------------------- */}
                        <DropdownItem
                          hidden={
                            woData[0].fiixWOStatus === "Requires Approval"
                              ? true
                              : // isSecurityAdminLogin === false
                              //   ? false:
                              woData[0].fiixWOStatus === "Canceled" ||
                                woData[0].fiixWOStatus === "Completed" ||
                                woData[0].fiixWOStatus === "On Hold" ||
                                woData[0].fiixWOStatus === "Closed" ||
                                woData[0].fiixWOStatus === "Rejected" ||
                                woData[0].fiixWOStatus === "Need Revision"
                              ? true
                              : false
                          }
                          // style={{ color: 'red' }}
                          onClick={this.toggleOnHoldModal}
                        >
                          On Hold
                        </DropdownItem>
  {/* ------------------------------------------------------------------------------------------------------------------------------------------------------------------------- */}
                        <DropdownItem
                      hidden={
                        woData[0].fiixWOStatus === "Completed" ||
                        woData[0].fiixWOStatus !== "Work In Progress"
                          ? true
                          : false
                      }
                          onClick={this.toggleCompleteActionModal}
                        >
                          Complete
                        </DropdownItem>

  {/* ------------------------------------------------------------------------------------------------------------------------------------------------------------------------- */}
                      <DropdownItem
                      hidden={
                        woData[0].fiixWOStatus !== "Completed" 
                      }
                          onClick={this.toggleClosedModal}
                        >
                          Close
                        </DropdownItem>

 {/* ------------------------------------------------------------------------------------------------------------------------------------------------------------------------- */}                        

                        {/* ------------------------------------------------------------------------------------------------------------------------------------------------------------------------- */}
                        <DropdownItem
                          hidden={
                            !(
                              woData[0].fiixWOStatus === "Need Revision" &&
                              (
                                woData[0].createdBy.toLowerCase() === getUserADAccount(user.account.userName).toLowerCase() ||
                                !this.state.isSecurityAdminLogin
                              )
                            )
                          }
                          onClick={() => this.resubmitData()}
                        >
                          Resubmit
                        </DropdownItem>
  {/* ------------------------------------------------------------------------------------------------------------------------------------------------------------------------- */}
                        {/* Added by BN 6/6/24. Move save button to "Action" button per Josh Request 6/5/24 */}
                        <DropdownItem
                          hidden={this.isSaveButtonHidden()}
                          onClick={() => this.saveData()}
                        >
                          Save
                        </DropdownItem>
{/* ------------------------------------------------------------------------------------------------------------------------------------------------------------------------- */}                        
                        <DropdownItem
                    hidden={ isSecurityAdminLogin  &&(
                      woData[0].fiixWOStatus === "Completed" ||
                      woData[0].fiixWOStatus === "Canceled" ||
                      woData[0].fiixWOStatus === "Closed")
                    }
                    style={{ color: 'red' }}
                          onClick={this.toggleCancelModal}
                        >
                          Cancel
                        </DropdownItem>




                      </DropdownMenu>
               
                    </ButtonDropdown>

                    <WORejectModal
                      isOpen={this.state.isRejectModalOpen}
                      toggle={this.toggleRejectModal}
                      color="danger"
                      buttonLabel="Send Back"
                      className="float-right"
                      hidden={
                        woData[0].fiixWOStatus == "Assigned/Scheduled" ||
                        woData[0].fiixWOStatus == "Rejected" ||
                        woData[0].fiixWOStatus == "Need Revision"
                      }
                      formName="Send Back"
                      id={workOrderKey}
                      mocKey={workOrderKey}
                      message=""
                      commentPrompMsg="Please enter comments:"
                      onClickYes={(e, key) => this.rejectWO(e, key)}
                    ></WORejectModal>


                      {/* <WODuplicateModal
                      isOpen={this.state.isDuplicateWOModalOpen}
                      toggle={this.toggleDuplicateWOModal}
                      color="danger"
                      buttonLabel="Send Back"
                      className="float-right"
                      formName="Send Back"
                      id={workOrderKey}
                      mocKey={workOrderKey}
                      woDataList={woDataList}
                      message=""
                      commentPrompMsg="Please enter comments:"
                      onClickYes={(e, key) => this.rejectWO(e, key)}
                    ></WODuplicateModal>                     */}


                    {/* <WOConfirmModal
                      isOpen={this.state.isWIPModalOpen}
                      toggle={this.toggleWIPModal}
                      color="danger"
                      buttonLabel="WIP"
                      className="float-right"
                      formName="Work in Progress confirmation"
                      id={workOrderKey}
                      mocKey={workOrderKey}
                      message=""
                      commentPrompMsg="Are you sure you want to set this work order to 'Work in Progress'?"
                      onClickYes={this.workInProgressData}
                    ></WOConfirmModal> */}
                    <WOWIPModal
                      fiixWOStatus={fiixWOStatus}
                      readOnly={readOnly}
                      fiixWOId={fiixWOId}                       
                      user={this.props.user}
                      empLost={empList}                  
                      isOpen={this.state.isWIPModalOpen}
                      toggle={this.toggleWIPModal}
                      color="danger"
                      buttonLabel="WIP"
                      className="float-right"
                      formName="Work in Progress confirmation"
                      id={workOrderKey}
                      mocKey={workOrderKey}
                      message=""
                      commentPrompMsg="Are you sure you want to set this work order to 'Work in Progress'?"
                      onClickYes={this.workInProgressData}
                      partsAndCostData={partsAndCostData}
                      partsAndCostDataGrid={partsAndCostDataGrid}
                    ></WOWIPModal>                    

                    <WOConfirmModal
                      isOpen={this.state.isClosedModalOpen}
                      toggle={this.toggleClosedModal}
                      color="danger"
                      buttonLabel="Closed"
                      className="float-right"
                      formName="Close confirmation"
                      id={workOrderKey}
                      mocKey={workOrderKey}
                      message=""
                      commentPrompMsg="Are you sure you want to set this work order to 'Closed'?"
                      onClickYes={this.closedData}
                    ></WOConfirmModal>

                  <WOConfirmModal
                      isOpen={this.state.isCancelModalOpen}
                      toggle={this.toggleCancelModal}
                      color="danger"
                      buttonLabel="Cancel"
                      className="float-right"
                      formName="Cancel confirmation"
                      id={workOrderKey}
                      mocKey={workOrderKey}
                      message=""
                      commentPrompMsg="Are you sure you want to cancel this work order?"
                      onClickYes={this.cancelData}
                    ></WOConfirmModal>

                    <WOOnHoldModal
                      isOpen={this.state.isOnHoldModalOpen}
                      toggle={this.toggleOnHoldModal}
                      color="danger"
                      buttonLabel="On Hold"
                      className="float-right"
                      formName="On Hold"
                      message="Are you sure you want to put work order on hold?"
                      commentPrompMsg="Please enter your comment:"
                      invalid={!this.state.invalidDataItem.comments} // 2024-07-24: Pass the invalid prop based on comments validity
                      id={woData[0].workOrderKey}
                      defaultValue=""
                      value={comments}
                      onChangeTest={(e) =>
                        this.onTextChange("onHoldComments", e.target.value, e)
                      }
                      onClickYes={this.onHoldData}
                    />

                    <WOCompleteModal
                      
                      isOpen={this.state.isCompleteModalOpen}
                      toggle={this.toggleCompleteModal}
                      outline={true}
                      style={{ margin: "0px 0px 0px 0px" }}
                      color="primary"
                      className="float-left"
                      buttonLabel="Complete"
                      hidden={
                        woData[0].fiixWOStatus === "Completed" ||
                        woData[0].fiixWOStatus !== "Work In Progress"
                          ? true
                          : false
                      }
                      formName="Complete"
                      id={woData[0].workOrderKey}
                      message="Are you sure you want to complete the Work Order?"
                      invalidDataItem={invalidDataItem}
                      onChangeVendor={(e) =>
                        this.onTextChange("completedVendorsKey", e.target.value, e)
                      } 
                      onClickNo={(e) => this.noValue()}
                      onClickYes={(e) => this.completeData()}
                      empList={empList}
                      issueTypeListFiltered={issueTypeListFiltered}
                      completedDate={completedDate}
                      completedUser={completedUser}
                      // getSelEmpList={this.getSelEmpList}
                      isSecurityAdminLogin={isSecurityAdminLogin}
                      getSelIssueType={this.getSelIssueType}
                      woData={woData}
                      getSelVendors={this.getSelVendors}
                      vendorsList= {vendorsList}
                      partsAndCostData={partsAndCostData}
                      partsAndCostDataGrid={partsAndCostDataGrid}
                      onStatusCellUpdating={this.onStatusCellUpdating}
                      // woUOMData= {woUOMData}
                      // getSelWOUOM = {this.getSelWOUOM}
                      user={this.props.user}
                      workOrderKey = {workOrderKey}
                      refreshPartsAndCostData={this.refreshPartsAndCostData}
                      getWorkOrder={this.getWorkOrder}
                      offLineLinkKey={offLineLinkKey}
                      hideComplete={hideComplete}
                      // woCostType ={woCostType}
                      // getSelWOCostType = {this.getSelWOCostType}
                    />
                    <ApproveConfirmationModal
                      isOpen={this.state.isApproveModalOpen}
                      toggle={this.toggleApproveModal}
                      color="success"
                      buttonLabel={"Approve"}
                      hidden={
                        woData[0].fiixWOStatus == "Assigned/Scheduled" ||
                        woData[0].fiixWOStatus == "Rejected" ||
                        woData[0].fiixWOStatus == "Need Revision"
                      }
                      empList={empList}
                      formName="Approve"
                      id={workOrderKey}
                      message="Please assign user:"
                      inputComment="Approved."
                      isCommentRequired={false}
                      isAssignedToRequired={false}
                      commentPrompMsg="Please Enter Approval Comment:"
                      onClickYes={(e, y) => this.approveWO(e, y)}
                    ></ApproveConfirmationModal>

                                      {/* <TaskAndInstructions
                    fiixWOStatus={fiixWOStatus}
                    user={this.props.user}
                    workOrderKey={workOrderKey}
                    readOnly={readOnly}
                    fiixWOId={fiixWOId}
                  ></TaskAndInstructions> */}

                    <RerouteConfirmationModal
                      // hidden={woData[0].fiixWOStatus === "Rejected" || woData[0].fiixWOStatus === "Assigned/Scheduled"}
                      fiixWOStatus={fiixWOStatus}
                      readOnly={readOnly}
                      fiixWOId={fiixWOId}
                      refreshData = {this.getPartsAndCost}                    

                      isOpen={this.state.isRerouteModalOpen}
                      toggle={this.toggleRerouteModal}
                      color="success"
                      buttonLabel={"Reroute"}
                      workOrderKey={workOrderKey}
                      woData={woData}
                      issueTypeListDefault={issueTypeListDefault}
                      // mainTypeApprovalList={mainTypeApprovalList}
                      mainTypeList={mainTypeList}
                      oldMaintenanceType={oldMaintenanceType}
                      issueTypeList={issueTypeList}
                      formName="Reroute"
                      id={workOrderKey}
                      // message="Please select Maintenance Type"
                      commentPrompMsg="Please enter comments"
                      empList={empList}
                      user={this.props.user}
                      getSelRerouteEmpList={this.getSelRerouteEmpList}
                      invalid={!invalidDataItem.comments}
                      partsAndCostData={partsAndCostData}
                      getWorkOrder={this.getWorkOrder}
                      partsAndCostStatus = {partsAndCostStatus}
                      partsAndCostDataGrid={partsAndCostDataGrid}
                      onChangeTest3={(e) =>
                        this.onTextChange("fiixAssignedToUser", e, e)
                      }
                      onChangeComments={(e) =>
                        this.onTextChange("onRejectComments", e.target.value, e)
                      }
                      onClickYes={(e, y) => this.rerouteData(e, y)}
                    ></RerouteConfirmationModal>

                    {/* <ConfirmModal
                    outline={false}
                    color="success"
                    buttonLabel="Submit"
                    hidden={woData[0].fiixWOStatus === "Rejected"}
                    formName="Complete"
                    id={woData[0].workOrderKey}
                    message="Are you sure you want to submit work order?"
                    onClickYes={(e) => this.submitWO()}
                  ></ConfirmModal> */}

                    <Button
                      color="primary"
                      hidden={readOnly}
                      onClick={() => this.saveData()}
                    >
                      OK
                    </Button>
                    {/* Added by BN 6/6/24. Move save button to "Action" button per Josh Request 6/5/24 */}
                    {/* 
                    <Button
                      color="primary"

                      hidden={this.isSaveButtonHidden()}
                      onClick={() => this.saveData()}
                    >
                      Save
                    </Button> */}



                    <Button color="primary" onClick={() => this.closeForm()}>
                      Close
                    </Button>
                  </div>
                </Col>
              </Row>
            </Form>
          </Form>

          <div>
            <Modal
              isOpen={this.state.modalQRCodeScanner}
              toggle={() => this.toggleQRCodeScanner()}
              size="sm"
              backdrop={"static"}
              //onClosed={() => this.getAFEdata()}
            >
              <ModalHeader toggle={() => this.toggleQRCodeScanner()}>
                QR Code Scaner
              </ModalHeader>
              <ModalBody>
                <QrReader
                  constraints={{ facingMode: "environment" }}
                  onResult={(result, error) => {
                    if (!!result) {
                      //this.setState({ qrValue: result?.text });
                      this.setQRCodeValue(result?.text);
                    }

                    if (!!error) {
                      console.info(error);
                    }
                  }}
                  style={{ width: "100%" }}
                />
              </ModalBody>
            </Modal>
          </div>

        <div>
          <Modal
            isOpen={this.state.modalMsg}
            toggle={() => this.toggleMsg()}
            size="lg"
            style={{
              backgroundColor: "#fdecea", // Softer red background
              color: "#721c24", // Dark red text
              border: "1px solid #f5c6cb", // Red border
              borderRadius: "8px", // Rounded corners
              padding: "20px", // Add padding
            }}
          >
            <ModalHeader
              toggle={() => this.toggleMsg()}
              style={{
                backgroundColor: "#f8d7da",
                color: "#721c24",
                borderBottom: "1px solid #f5c6cb",
                fontWeight: "bold",
                fontSize: "18px", // Slightly larger header
              }}
            >
              {msgHeader}
            </ModalHeader>
            <ModalBody
            style={{
              backgroundColor: "#fdecea", // Softer background
              color: "#721c24",
              padding: "20px", // More padding for better spacing
              fontSize: "16px",
              textAlign: "left", // Align text for better readability
            }}
            >
              {msgBody}
            </ModalBody>
            <ModalFooter
              style={{
                backgroundColor: "#fdecea",
                borderTop: "1px solid #f5c6cb",
              }}
            >
              <Button color="primary" onClick={() => this.toggleMsg()}>
                OK
              </Button>
            </ModalFooter>
          </Modal>
        </div>

{/* 
          <div>
            <Modal
              isOpen={this.state.modalMsg}
              toggle={() => this.toggleMsg()}
              size="lg"
            >
              <ModalHeader toggle={() => this.toggleMsg()}>
                {msgHeader}
              </ModalHeader>
              <ModalBody>{msgBody}</ModalBody>
              <ModalFooter>
                <Button color="primary" onClick={() => this.toggleMsg()}>
                  OK
                </Button>{" "}
              </ModalFooter>
            </Modal>
          </div> */}

          
          <div>
                    <Modal
                        isOpen={this.state.modalHistoryLog}
                        toggle={() => this.toggleModalHistoryLog()}
                        // size="xl"
                        style={{ maxWidth: '90%', width: '90%' }} // Inline style for testing
                    >

                        <ModalHeader toggle={() => this.toggleModalHistoryLog()}>
                            Work Order Log History
                        </ModalHeader>
                        <ModalBody>
                        <FormGroup>
                    <Row
                    // form style={{ zIndex: 0, position: "relative" }}
                    >
                      <IgrDataGrid
                        ref={(grid) => this.onGridRef(grid, "woLogHistory")}
                        height={"500px"}
                        width={"100%"}
                        className="scrollable-grid"
                        isColumnOptionsEnabled="true"
                        editMode={0}
                        dataSource={woLogHistory}
                        autoGenerateColumns="false"
                        selectionMode="SingleRow"
                      >
                         <IgrTextColumn
                          field="locationName"
                          headerText="Location"
                          width="*>200"
                        />   
                        <IgrTextColumn
                          field="fiixWOStatus"
                          headerText="Status"
                          width="*>200"
                        />                                                  
                        <IgrTextColumn
                          field="woNumber"
                          headerText="WO No."
                          width="*>120"
                        />
                         <IgrTextColumn
                          field="qrCodeValue"
                          headerText="QR Code"
                          width="*>200"
                        />                        
                        <IgrTextColumn
                          field="assetName"
                          headerText="Asset"
                          width="*>200"
                        />
                         <IgrTextColumn
                          field="maintenanceType"
                          headerText="Maintenance Type"
                          width="*>200"
                        />                       
                                           

                         <IgrTextColumn
                          field="fiixIssueType"
                          headerText="Issue Type"
                          width="*>200"
                        />                       
                         <IgrTextColumn
                          field="fiixWorkInstruction"
                          headerText="Work Instruction"
                          width="*>250"
                        /> 
                        <IgrTextColumn
                          field="woPriority"
                          headerText="Priority"
                          width="*>200"
                        />  
                        <IgrTextColumn
                          field="createdByName"
                          headerText="Created By"
                          width="*>150"
                        />

                         <IgrTextColumn
                          field="reqestDateTime"
                          headerText="Created Date"
                          width="*>250"
                        /> 
                        <IgrTextColumn
                          field="approvedStatus"
                          headerText="Approval Status"
                          width="*>200"
                        />  
                        <IgrTextColumn
                          field="approvers"
                          headerText="Approvers"
                          width="*>300"
                        />    

                        <IgrTextColumn
                          field="approvedUser"
                          headerText="Approved User"
                          width="*>300"
                        />   
                        <IgrTextColumn
                          field="approvalComments"
                          headerText="Approval Comments"
                          width="*>300"
                        />   
                         <IgrTextColumn
                          field="approvedDate"
                          headerText="Approved Date"
                          width="*>250"
                        />                                                                                                                                                                                                                       
                        <IgrTextColumn
                          field="assignedToUserName"
                          headerText="Assigned To"
                          width="*>300"
                        />                        
                         <IgrTextColumn
                          field="fiixWOCompletedByUser"
                          headerText="Completed By"
                          width="*>200"
                        />                                                                       
                         <IgrTextColumn
                          field="fiixWODateCompleted"
                          headerText="Completed Date"
                          width="*>200"
                        />    
                         <IgrTextColumn
                          field="fiixCompletedHours"
                          headerText="Completed Hours"
                          width="*>200"
                        />                                            
                         <IgrTextColumn
                          field="fiixWOCompletionNotes"
                          headerText="Completion Notes"
                          width="*>300"
                        />                          
                        <IgrTextColumn
                          field="modifiedByName"
                          headerText="Modified By"
                          width="*>180"
                        /> 
                        <IgrTextColumn
                          field="modifiedDateTime"
                          headerText="Modified Date"
                          width="*>250"
                        /> 
                        <IgrTextColumn
                          field="userAction"
                          headerText="User Action"
                          width="*>150"
                        /> 
                         <IgrTextColumn
                          field="actionBy"
                          headerText="Action By"
                          width="*>200"
                        /> 
                         <IgrTextColumn
                          field="actionDateTime"
                          headerText="Action Date"
                          width="*>250"
                        />                                                                                                                                                                                                                                                                                          
                      </IgrDataGrid>
                    </Row>
                  </FormGroup>
                        </ModalBody>

                    </Modal>
                </div>

          {/* <div>
                    <Modal isOpen={this.state.modalAppHist}
                        toggle={() => this.toggleModalAppHist()}
                        size="xl">
                        <ModalHeader toggle={() => this.toggleModalAppHist()}>
                            Approval History Log
                        </ModalHeader>
                        <ModalBody>
                            <WOApprovalHistory workOrderKey={workOrderKey}
                                toggleModalAppHist={this.toggleModalAppHist}>

                            </WOApprovalHistory>
                        </ModalBody>

                    </Modal>
                </div> */}

<Modal isOpen={showValidationModal} toggle={this.closeValidationModal}>
                <ModalHeader toggle={this.closeValidationModal}>Error</ModalHeader>
                <ModalBody>
                    Vendor or Labor data must be inserted before completing this Work Order.
                </ModalBody>
                <ModalFooter>
                    <Button color="primary" onClick={this.closeValidationModal}>OK</Button>
                </ModalFooter>
            </Modal>       
        </div>
      );
  }

  onStatusCellUpdating(s, e) {
    const content = e.content;
    const info = e.cellInfo;
    const item = info.rowItem;

    if (info.isHeaderCell) {
      if(content.children[0]){
          content.children[0].style.textAlign = "center";
      } else {
          content.style.textAlign = "center";
      }
      return;
  }
    let link;
    if (content.childElementCount === 0) {
      link = document.createElement("label");
      content.appendChild(link);
    } else {
      link = content.children[0];
    }

    const currencyFields = [
      'totalLaborCost',
      'totalThirdPartyCost',
      'totalPartCost',
      'totalCost',
      'partCostPerUnit',
      'laborCostPerHour',
    ];
    // if (s.field === 'completedDate') {
    //   // Format the completedDate using moment in Central Time
    //   const formattedDate = moment(item[s.field]).tz('America/Chicago').format('YYYY-MM-DD HH:mm:ss');
    //   link.textContent = formattedDate ? formattedDate : ''; // Handle empty or null dates
    // } 

    if (currencyFields.includes(s.field)) {
      // If s.field is 'partCostPerUnit' and the value is null, set the content to an empty string
      if (s.field === 'totalCost') {
        link.style.fontWeight = 'bold';
        link.style.fontSize = '1em';
      } else {
        // Reset styles for other fields
        link.style.fontWeight = "normal";
        link.style.fontSize = "1em";
      }
      if (s.field === 'partCostPerUnit' && (item[s.field] === null || item[s.field] === undefined)) {
        link.textContent = '';
      } else {
        const formattedValue = new Intl.NumberFormat('en-EN', {
          style: 'currency',
          currency: 'USD',
          minimumFractionDigits: 0,
          maximumFractionDigits: 2
        }).format(item[s.field]);

        link.textContent = formattedValue;
      }
    } else if (s.field === 'quantity') {
      // Convert 'quantityUsed' to a string if it's not null or undefined
      if (item[s.field] !== null && item[s.field] !== undefined) {
        const formattedValue = item[s.field].toString();
        link.textContent = formattedValue;
      } else {
        link.textContent = ''; // or set to a default value if needed
      }
    } else if (['parts', 'hrsSpent', 'costType'].includes(s.field)) {
      // Handle 'parts', 'hrsSpent', and 'costType' without transformations
      link.textContent = item[s.field] ? item[s.field].toString() : '';
    } else {
      // If there are other cases that do not require specific formatting, handle them here.
      link.textContent = item[s.field] ? item[s.field].toString() : '';
    }
  }

  

}
