import React, { Component, ReactDOM, useState, useEffect, useCallback, useRef } from 'react';

import {
    Table, Alert, UncontrolledTooltip,
    Media, Col, Row, Button, FormGroup, Modal, ModalHeader, ModalBody,
    Label, Input, InputGroup, ModalFooter, ListGroup
} from 'reactstrap';
import { apiURL, apiDELETEHeader, apiHeader, apiPOSTHeader } from '../../resources/apiURL';
import MenuIcon from '../../resources/menu.png';
import globebw from '../../resources/globebw.svg'
import '../../custom.css';
import Select from 'react-select';
import { getRandomInt, getFirstDayofMonth } from '../../resources/utility';
import { getUserADAccount, USDCurrencyFormat, ColoredLine, bitwise, addDays } from '../../resources/utility'
import ImageViewer from '../../resources/ImageViewer';
import TrashBin from '../../resources/trash.svg'
import ConfirmModal from '../../operation/ConfirmModal'
import EditIcon from '../../resources/revise.png';
import filter from '../../resources/filter.png'
import OpenLink from '../../resources/OpenLink.png'
import add from '../../resources/add.png'
import link_icon from '../../resources/link_icon.png'
import PrintComponent from '../../resources/PrintComponent ';

import NumberFormat from 'react-number-format';

import { DateRangePickerComponent } from '@syncfusion/ej2-react-calendars';
import { DateTimePickerComponent } from '@syncfusion/ej2-react-calendars';
import { DatePickerComponent } from '@syncfusion/ej2-react-calendars';
import { DropDownListComponent, Inject, MultiSelectComponent, CheckBoxSelection } from '@syncfusion/ej2-react-dropdowns';
import { NumericTextBoxComponent } from '@syncfusion/ej2-react-inputs';
import { Query } from '@syncfusion/ej2-data';

import FileManager from '../../resources/FileManager';
import InspectionButton from './InspectionButton'
import { BottomClipper, Random, isNumber } from 'igniteui-react-core';
import { ButtonDisplayStyle_$type } from 'igniteui-react-inputs';
import { TreemapValueMappingMode_$type } from 'igniteui-react-charts';
import { SpreadsheetColumnScrollRegion } from 'igniteui-react-spreadsheet';

import { AFEAddView, AFELink, AFEUnlink } from './AssetAFEAction'
import AssetMapViewSimple from './AssetMapViewSimple';

/**added by HN 01/29/2024
 * new control to show comment section look like discussion blob
 */
import AssetDiscussion from './AssetDiscussion'
/**end */

/**added by HN 06/10/2024
 * print asset details
 */
import ReactToPrint, { PrintContextConsumer } from 'react-to-print';

var DatePicker = require("reactstrap-date-picker");

export const getValueList = async (companyID, data, isAccessReadonly) => {

    return new Promise((res, rej) => {
        //var tempDataList = []
        data.map(e => {
            if (e.asset_config !== null) {

                e.asset_config.map(b => {
                    let tempList = b.meta_datas.filter(d => d.data_type === 'value_list' || d.data_type === 'multi_select')
                    if (tempList.length > 0) {

                        //console.log(" Begin loading Value List ")
                        tempList.map(a => {
                            getValueListData(companyID, a.value_list_id).then(rntData => {
                                a.value_list_data = rntData
                                a.parent_meta_value = -1
                                //console.log("Finish loading Value List " + a.value_list_id)
                            })

                            //tempDataList.push(tempList)
                        })
                    }

                    if (isAccessReadonly) {
                        b.meta_datas.map(g => {
                            g.is_readonly = 1
                        })
                    }
                })
            }

        })
        //console.log("Exist loading value list ")
        res(data)
    })

}

export const getValueListData = (companyID, value_list_id) => {
    return new Promise((res, rej) => {

        let myURI = apiURL + 'Asset/' + companyID + '/ValueList/ByName/' + value_list_id

        fetch(myURI, { modes: 'no-cors' })
            .then(res => res.json())
            .then(json => {

                res(json[0].value_list_data)

                /* move this part into API
                create column label and value */
                // this.convertValueList(json[0].value_list_data).then((data) => {
                //     res(data)
                // })
                /*end */

            });
    })
}



const AssetControl = (props) => {
    const { company_id, value_list, metaLength, isReadOnly,
        config_format_name, isDisscusionFormStyle, isPrintMode } = props

    const [config_meta, setConfigMeta] = useState(props.config_meta)
    const [asset_detail, setAssetDetail] = useState(props.asset_detail)
    const [modalSearchForm, setModalSearchForm] = useState(false)
    const [modalNewAFE, setModalNewAFE] = useState(false)
    const [reloadForm, setReloadForm] = useState(false)

    const get_meta_value = (meta_id) => {

        if (asset_detail.meta_datas !== null) {
            let meta_data = asset_detail.meta_datas.filter(e => e.meta_id === meta_id);
            if (meta_data !== undefined && meta_data !== null) {
                if (meta_data.length > 0) {

                    if ((meta_data[0].data_type === 'date') &&
                        meta_data[0].meta_value !== null &&
                        meta_data[0].meta_value !== '') {

                        return new Date(meta_data[0].meta_value)

                    } else if (meta_data[0].data_type === "datetime") {
                        //return new Date(meta_data[0].meta_value)
                    }
                    return meta_data[0].meta_value
                }
            }
        }

        /**this section added by HN 01/21/2024
         * set default value for control.
         * need to find a way to automatic instead of coding
         */
        /* default value need to find way dynamic control this */

        if (asset_detail.meta_datas === null) {
            asset_detail.meta_datas = []
        }

        switch (meta_id) {
            case "wtc_act_assigndate":
                const wtc_act_assigndate = new Date()
                asset_detail.meta_datas.push(create_meta_data("date", meta_id, wtc_act_assigndate))
                return wtc_act_assigndate
                break;
            case "wtc_act_duedate":
                const wtc_act_duedate = addDays(new Date(), 10)
                asset_detail.meta_datas.push(create_meta_data("date", meta_id, wtc_act_duedate))
                return wtc_act_duedate
                break;
            case "pnd_wqt_collect_date":
            case "pnd_cinv_collect_date":
            case "collect_date":
                const pnd_wqt_collect_date = addDays(new Date(), 0)
                asset_detail.meta_datas.push(create_meta_data("date", meta_id, pnd_wqt_collect_date))
                return pnd_wqt_collect_date
                break;
            default:
                break;
        }
        return null
    }

    const get_meta_text = (meta_id) => {

        if (asset_detail.meta_datas !== null) {
            let meta_data = asset_detail.meta_datas.filter(e => e.meta_id === meta_id);
            if (meta_data !== undefined && meta_data !== null) {
                if (meta_data.length > 0) {

                    if (meta_data[0].data_type === 'date' &&
                        meta_data[0].meta_text !== null &&
                        meta_data[0].meta_text !== '') {

                        return new Date(meta_data[0].meta_text)

                    } else if (meta_data[0].data_type === "datetime") {
                        //return new Date(meta_data[0].meta_value)
                    }
                    return meta_data[0].meta_text
                }
            }
        }
        return null
    }


    const get_value_List_meta_value = (meta_id, value_list_data, parent_meta_id) => {



        if (value_list_data !== undefined) {
            if (asset_detail.meta_datas !== null && value_list_data !== null) {
                // console.log("here------------------------------>")
                //if (asset_detail.assetkey > 0) {
                let meta_data = asset_detail.meta_datas.filter(e => e.meta_id === meta_id);
                if (meta_data !== undefined && meta_data !== null) {
                    if (meta_data.length > 0) {
                        return (value_list_data.filter(e => e.value === meta_data[0].meta_value))
                    }
                }
                //}

            } else if (value_list_data !== null && asset_detail.assetkey < 0 && asset_detail.parent_assetkey !== null) {

                // console.log("here here")
                if (asset_detail.parent_assetkey > 0 && meta_id === "parent_meta") {
                    // console.log("here here again")
                    // console.log(parent_meta_id)

                    const fieldValue = value_list_data.filter(e => e.value === (asset_detail.parent_assetkey + ''))


                    if (asset_detail.meta_datas === null) {
                        asset_detail.meta_datas = []
                    }

                    let new_meta = create_meta_data("value_list", meta_id, fieldValue[0])

                    //console.log(new_meta)
                    asset_detail.meta_datas.push(new_meta)

                    return (fieldValue)

                }

            }
        }
        return null
    }
    const create_meta_data = (fieldType, meta_id, meta_value, meta_text = null) => {
        const { user } = props


        const meta_data = {
            "asset_meta_key": -1,
            "meta_id": meta_id,
            "meta_value": (fieldType === "value_list" ? meta_value.value :
                fieldType === "multi_select" ? meta_value.join(',') :
                    meta_value),
            //"meta_text": (fieldType === "value_list" ? meta_value.label : null),
            "meta_text": (fieldType === "value_list" ? meta_value.label :
                (fieldType === "inspItem" ? getInspItemText(meta_value) :
                    (meta_text))),
            "meta_name": config_meta.meta_name,
            "asset_config_meta_key": config_meta.asset_config_meta_key,
            "assetkey": asset_detail.assetkey,
            "created_by": getUserADAccount(user.account.userName)
        }

        return meta_data
    }

    const getInspItemText = (value) => {
        if (Number(value) === 1) return "Passed"
        else if (Number(value) === 2) return "Failed"
        else return " "
    }

    const get_sel_value_text = (value_list, selkey) => {

        let selKeys = selkey.split(",")
        var rntValue = []
        selKeys.map(e => {
            let filter = value_list.filter(f => f.field_value === e)
            if (filter.length > 0) {
                rntValue.push(filter[0])
            }
        })

        console.log("rntValue<--------------")
        console.log(rntValue)
        console.log(rntValue.map(e => e.label).join(","))
        return rntValue.map(e => e.label).join(",")

        // let filters = value_list.filter(e => selkey.includes(e.value))
        // return filters.map(e => e.label).join(",")
    }

    const onTextChange = (config_meta, fieldName, fieldValue, v) => {

        // console.log("AssetControl--->fieldName")
        // console.log(fieldName)
        // console.log("fieldValue, v")
        // console.log(fieldValue, v)
        // console.log("config_meta")
        // console.log(config_meta)

        /**Added by HN 09/10/2024
         * allow user to clear out data
         * some control return null when user clear data out
         */
        if (fieldName != "" && fieldValue === null) {
            fieldValue = ""
        }
        /**end */

        if (fieldName != "" && fieldValue !== null) {
            var bMetaExist = false
            let fieldType = config_meta.data_type

            if (fieldType === "multi_select") {
                // console.log("fieldValue.join(',')")
                // console.log(fieldValue.join(','))

                let selkey = fieldValue.join(',')
                let value_list_data = config_meta.value_list_data
                // let filters = value_list_data.filter(e => selkey.includes(e.value))
                let filters = value_list_data.filter(e => selkey.includes(e.value))
                // console.log("filters")
                // console.log(filters)
                // console.log("filters.map(x => x.label).join(',')")
                //console.log(get_sel_value_text(config_meta.value_list_data, fieldValue.join(',')))
            }


            if (asset_detail.meta_datas === null) {
                asset_detail.meta_datas = []
            }

            asset_detail.meta_datas.map(e => {
                if (e.meta_id === fieldName) {
                    e.asset_meta_key = -1
                    e.meta_value = (fieldType === "value_list" ? fieldValue.value :
                        fieldType === "multi_select" ? fieldValue.join(',') :
                            fieldValue)

                    e.meta_text = (fieldType === "value_list" ? (fieldValue.label !== undefined ? fieldValue.label : fieldValue.text) :
                        (fieldType === "multi_select" ? get_sel_value_text(config_meta.value_list_data, fieldValue.join(',')) :
                            (fieldType === "inspItem" ? getInspItemText(fieldValue) :
                                fieldType === "afe" ? v : (null))))

                    bMetaExist = true

                    if (fieldType === "value_list") {
                        props.onValueListChange(config_meta.asset_config_key, fieldName, fieldValue)

                    }
                    return
                }
            })

            if (!bMetaExist) {
                // console.log("AssetControl--->fieldName ----  here")

                let new_meta = create_meta_data(fieldType,
                    fieldName,
                    fieldValue,
                    (fieldType === "multi_select" ? get_sel_value_text(config_meta.value_list_data, fieldValue.join(',')) : null))
                /**Added by HN  -01/19/2024 set meta text for AFE */
                if (fieldType === "afe") {
                    new_meta.meta_text = v
                }

                asset_detail.meta_datas.push(new_meta)
                if (fieldType === "value_list") {
                    asset_detail.meta_datas.map(e => {
                        if (e.meta_id === fieldName) {
                            e.asset_meta_key = -1
                            e.meta_value = (fieldType === "value_list" ? fieldValue.value :
                                fieldValue)
                            e.meta_text = (fieldType === "value_list" ? (fieldValue.label !== undefined ? fieldValue.label : fieldValue.text) :
                                (fieldType === "inspItem" ? getInspItemText(fieldValue) :
                                    fieldType === "afe" ? v : (null)))
                            bMetaExist = true

                            if (fieldType === "value_list") {
                                props.onValueListChange(config_meta.asset_config_key, fieldName, fieldValue)

                            }
                        }
                    })
                }
            }

            /* added by HN filter other related fields */
            if (config_meta.auto_fill_meta_ids !== "") {
                if (fieldValue !== undefined && fieldValue !== null) {
                    if (fieldValue.additional_values !== "") {
                        asset_detail.auto_fill_meta_ids = config_meta.auto_fill_meta_ids
                        asset_detail.additional_values = fieldValue.additional_values
                    }
                }
            }

            if (config_meta.validation_error !== 0) {
                config_meta.validation_error = 0
                setConfigMeta(config_meta)
                setReloadForm(!reloadForm)
            }

            if (fieldType === "afe") {
                setReloadForm(!reloadForm)
            }
        }

        // console.log("in ontext change asset_detail")
        // console.log(asset_detail)
        props.onAssetMetaUpdate(asset_detail)

    }

    const onFiltering = (args, data) => {
        // console.log("--->args")
        // console.log(data)
        let query = new Query();
        // frame the query based on search string with filter type.
        query = (args.text !== "") ? query.where("label", "contains", args.text, true) : query;
        // pass the filter data source, filter query to updateData method.
        args.updateData(data, query);
    }

    const openURL = (e) => {
        window.open(e, '_blank', 'noreferrer');
    }

    const [selStartDate, setSelStartDate] = useState(null)

    const [selEndDate, setSelEndDate] = useState(null)

    const onDateRangeChange = (v) => {
        console.log("onDateRangeChange")
        console.log(v)

        setSelStartDate(v.startDate)
        setSelEndDate(v.endDate)
        // if (v.value !== null && v.value.length > 1 && v.endDate === null) {
        //     v.value[0] = v.startDate
        //     if (v.endDate === null) {

        //     }
        //     v.value[1] = v.endDate
        // }

    }

    const onDateRangeClose = (v, f) => {
        console.log(v)
        // console.log(f)
        // v.cancel = true
        if (v.event !== null) {
            console.log(v.event.target)
            if (v.event.target.innerHTML === 'Apply') {
                console.log("Apply Select Date Range: " + v.date)
            } else if (v.event.target.innerHTML === 'Cancel') {
                console.log("Cancel Select Date Range: " + v.date)
            }
        } else {
            v.cancel = true
            v.model.endValue = selEndDate
            v.model.startValue = selStartDate
        }
    }




    var validation_error = false

    console.log("------>config_meta<------")
    console.log(config_meta)

    if (config_meta === null) {
        return <div>'meta not found'</div>;
    }
    switch (config_meta.data_type) {
        case "url":
            return (<FormGroup>
                <Row form>
                    <Col md={(isDisscusionFormStyle ? 3 : (config_format_name === "single_column" ? "1" : (metaLength === 1 ? "2" : "4")))}
                        hidden={config_meta.meta_name === "" || config_meta.meta_id === "blank"}>
                        <Label hidden={config_meta.meta_name === ""}>{config_meta.meta_name}</Label>
                    </Col>
                    <Col md={(config_meta.meta_name === "" ? "12" :
                        (isDisscusionFormStyle ? 9 : (config_format_name == "single_column" ? 11 : (metaLength === 1 ? "10" : "8"))))}>
                        <InputGroup>
                            <Input type={config_meta.data_type}
                                name={config_meta.meta_name}
                                hidden={config_meta.meta_id === "blank"}
                                id={config_meta.meta_name}
                                readOnly={(config_meta.is_readonly === 1 || isReadOnly)}
                                //value={get_meta_value(config_meta.meta_id)}
                                defaultValue={get_meta_value(config_meta.meta_id)}
                                onChange={(e) => onTextChange(config_meta,
                                    config_meta.meta_id, e.target.value, e)} />
                            <Button onClick={() => openURL(get_meta_value(config_meta.meta_id))}>
                                Open
                            </Button>
                        </InputGroup>
                    </Col>
                </Row>
            </FormGroup>)
        case "decimal":
        case "currency":
        case "number":
            validation_error = (config_meta.validation_error === undefined || config_meta === null ? false :
                config_meta.validation_error !== 0)
            return (<FormGroup>
                <Row form>
                    <Col md={(isDisscusionFormStyle ? "3" : "4")}>
                        <Label hidden={config_meta.meta_name === ""}>{config_meta.meta_name}</Label>
                    </Col>
                    <Col md={(isDisscusionFormStyle ? "9" : "8")}>

                        <NumericTextBoxComponent
                            style={{
                                color: (((config_meta.is_readonly === 1 || isReadOnly)) ? 'black' : 'black')
                            }}
                            value={get_meta_value(config_meta.meta_id)}
                            decimals={(config_meta.data_type === "decimal" ? 2 : 0)}
                            format={(config_meta.data_type === "currency" ? 'c2' : '')}
                            disabled={(config_meta.is_readonly === 1 || isReadOnly)}
                            className={(validation_error ? "e-input-group e-error" : "e-input")}
                            change={(e) => onTextChange(config_meta,
                                config_meta.meta_id,
                                e.value,
                                e)}
                        />
                        {/* <NumberFormat
                            value={get_meta_value(config_meta.meta_id)}
                            className={(validation_error ? "e-input-group e-error" : "e-input form-control text-md-left")}
                            style={{ textAlign: 'left' }}
                            fixedDecimalScale={true}
                            decimalScale={(config_meta.data_type === "decimal" ? 2 : 0)}
                            displayType={((config_meta.is_readonly === 1 || isReadOnly) ? 'text' : '')}
                            onValueChange={(e) => onTextChange(config_meta,
                                                                config_meta.meta_id,
                                                                e.value,
                                                                e)}
                            thousandSeparator={true}
                            prefix={(config_meta.data_type === "currency" ? "$" : "")}

                        /> */}

                    </Col>
                </Row>
            </FormGroup >)
        case "currency":
            validation_error = (config_meta.validation_error === undefined || config_meta === null ? false :
                config_meta.validation_error !== 0)
            return (<FormGroup>
                <Row form>
                    <Col md={(isDisscusionFormStyle ? "3" : "4")}>
                        <Label hidden={config_meta.meta_name === ""}>{config_meta.meta_name}</Label>
                    </Col>
                    <Col md={(isDisscusionFormStyle ? "9" : "8")}>
                        <NumberFormat
                            value={get_meta_value(config_meta.meta_id)}
                            //className={'form-error'}
                            className={(validation_error ? 'e-input-group e-error' : 'e-input form-control text-md-left')}
                            fixedDecimalScale={true}
                            decimalScale={2}
                            displayType={((config_meta.is_readonly === 1 || isReadOnly) ? 'text' : '')}
                            onValueChange={(e) => onTextChange(config_meta,
                                config_meta.meta_id,
                                e.value,
                                e)}
                            thousandSeparator={true}
                            prefix={"$"}

                        />
                    </Col>
                </Row>
            </FormGroup >)
        case "gpscoord":
            return (<FormGroup>
                <Row form>
                    <Col md={(isDisscusionFormStyle ? "3" : "4")}>
                        <Label hidden={config_meta.meta_name === ""}>{config_meta.meta_name}</Label>
                    </Col>
                    <Col md={(isDisscusionFormStyle ? "9" : "8")}>
                        <NumberFormat
                            value={get_meta_value(config_meta.meta_id)}
                            className={'e-input form-control'}
                            fixedDecimalScale={true}
                            decimalScale={6}
                            displayType={((config_meta.is_readonly === 1 || isReadOnly) ? 'text' : '')}
                            onValueChange={(e) => onTextChange(config_meta,
                                config_meta.meta_id,
                                e.value,
                                e)}
                            thousandSeparator={true}
                        //suffix={'%'}
                        />
                    </Col>
                </Row>
            </FormGroup >)
        case "daterange":
            return (<FormGroup>
                <Row form>
                    <Col md={(isDisscusionFormStyle ? "3" : "4")}>
                        <Label hidden={config_meta.meta_name === ""}>{config_meta.meta_name}</Label>
                    </Col>
                    <Col md={(isDisscusionFormStyle ? "9" : "8")}>
                        <DateRangePickerComponent
                            value={get_meta_value(config_meta.meta_id)}
                            change={(v, f) => onTextChange(config_meta,
                                config_meta.meta_id, v.text, v)}
                            disabled={(config_meta.is_readonly === 1 || isReadOnly)}
                            className={(validation_error ? "e-input-group e-error" : "e-input")}
                            close={onDateRangeClose}
                            delayUpdate={true}
                            select={onDateRangeChange}
                        />
                    </Col>
                </Row>
            </FormGroup>)
        case "date":
            validation_error = (config_meta.validation_error === undefined || config_meta === null ? false :
                config_meta.validation_error !== 0)
            return (<FormGroup hidden={((config_meta.meta_options & 64) === 64) && asset_detail.assetkey < 0}>
                <Row form>
                    <Col md={(isDisscusionFormStyle ? "3" : "4")}>
                        <Label hidden={config_meta.meta_name === ""}>{config_meta.meta_name}</Label>
                    </Col>
                    <Col md={(isDisscusionFormStyle ? "9" : "8")}>
                        <DatePickerComponent
                            value={get_meta_value(config_meta.meta_id)}
                            disabled={(config_meta.is_readonly === 1 || isReadOnly)}
                            className={(validation_error ? "e-input-group e-error" : "e-input")}
                            change={(v, f) => onTextChange(config_meta,
                                config_meta.meta_id, v.value, v)}
                        //disabled={this.isFormReadOnly() || statusKey === 4}
                        />
                    </Col>
                </Row>
            </FormGroup>)
        case "datetime":
            validation_error = (config_meta.validation_error === undefined || config_meta === null ? false :
                config_meta.validation_error !== 0)
            return (<FormGroup hidden={((config_meta.meta_options & 64) === 64) && asset_detail.assetkey < 0}>
                <Row form>
                    <Col md={(isDisscusionFormStyle ? "3" : "4")}>
                        <Label hidden={config_meta.meta_name === ""}>{config_meta.meta_name}</Label>
                    </Col>
                    <Col md={(isDisscusionFormStyle ? "9" : "8")}>
                        <DateTimePickerComponent
                            value={get_meta_value(config_meta.meta_id)}
                            disabled={(config_meta.is_readonly === 1 || isReadOnly)}
                            //className={(!validateDataItem.estStartDate ? "form-error" : "")}
                            className={(validation_error ? "e-input-group e-error" : "e-input")}
                            change={(v, f) => onTextChange(config_meta,
                                config_meta.meta_id, v.value, v)}
                        //disabled={this.isFormReadOnly() || statusKey === 4}
                        />
                    </Col>
                </Row>
            </FormGroup>)

        case "afe":
            return (<FormGroup>
                <Row form>
                    <Col md={(isDisscusionFormStyle ? "3" : "4")}>
                        <Label hidden={config_meta.meta_name === ""}>{config_meta.meta_name}</Label>
                    </Col>
                    <Col md={(isDisscusionFormStyle ? "9" : "8")}>
                        <InputGroup>



                            <Input type={config_meta.data_type}
                                className={'e-input'}
                                name={config_meta.meta_name}
                                hidden={config_meta.meta_id === "blank"}
                                id={config_meta.meta_name}
                                readOnly={(config_meta.is_readonly === 1 || isReadOnly)}
                                //disabled={true}
                                value={get_meta_text(config_meta.meta_id)}
                                defaultValue={get_meta_text(config_meta.meta_id)}
                                style={{ backgroundColor: "transparent" }}
                            />

                            <AFEAddView asset_detail={asset_detail}
                                user={props.user}
                                afeHeaderKey={get_meta_value(config_meta.meta_id)}
                                config_meta={config_meta}
                                onTextChange={onTextChange}
                                mode='view' />

                            <AFELink asset_detail={asset_detail}
                                user={props.user}
                                config_meta={config_meta}
                                isReadOnly={asset_detail.assetkey < 0}
                                onTextChange={onTextChange} />


                            <AFEAddView asset_detail={asset_detail}
                                user={props.user}
                                afeHeaderKey={get_meta_value(config_meta.meta_id)}
                                config_meta={config_meta}
                                mode='add'
                                onTextChange={onTextChange}
                                isReadOnly={asset_detail.assetkey < 0} />

                            <AFEUnlink asset_detail={asset_detail}
                                user={props.user}
                                afeHeaderKey={get_meta_value(config_meta.meta_id)}
                                mode='add'
                                config_meta={config_meta}
                                onTextChange={onTextChange} />



                        </InputGroup>
                    </Col>
                </Row>
            </FormGroup>)

        case "value_list":
            validation_error = (config_meta.validation_error === undefined || config_meta === null ? false :
                config_meta.validation_error !== 0)
            //validation_error = true
            console.log("value list ----")
            return (<FormGroup>
                <Row form>
                    <Col md={(isDisscusionFormStyle ? "3" : "4")}>
                        <Label hidden={config_meta.meta_name === ""}>{config_meta.meta_name}</Label>
                    </Col>
                    <Col md={(isDisscusionFormStyle ? "9" : "8")}>
                        <DropDownListComponent
                            dataSource={config_meta.value_list_data}
                            fields={{ text: 'label', value: 'value' }}
                            allowFiltering={true}
                            //disabled={(config_meta.is_readonly === 1 || isReadOnly)}
                            enabled={!(config_meta.is_readonly === 1 || isReadOnly)}
                            showClearButton
                            className={(validation_error ? "e-input-group e-error" : "e-input")}
                            value={get_meta_value(config_meta.meta_id)}
                            filtering={(e) => onFiltering(e, config_meta.value_list_data)}
                            change={(e) => onTextChange(config_meta,
                                config_meta.meta_id, e.itemData, e)}>

                        </DropDownListComponent>
                    </Col>
                </Row>
            </FormGroup>)
        case "multi_select":
            validation_error = (config_meta.validation_error === undefined || config_meta === null ? false :
                config_meta.validation_error !== 0)
            //validation_error = true
            console.log("Multi Select value list ----")
            console.log(isPrintMode)


            return (<FormGroup>
                <Row form>
                    <Col md={(isDisscusionFormStyle ? "3" : "4")}>
                        <Label hidden={config_meta.meta_name === ""}>{config_meta.meta_name}</Label>
                    </Col>
                    <Col md={(isDisscusionFormStyle ? "9" : "8")}>
                       
                            <MultiSelectComponent
                                dataSource={config_meta.value_list_data}
                                fields={{ text: 'label', value: 'value' }}
                                allowFiltering={true}
                                //mode={(!isPrintMode ? "CheckBox" : "Box")}
                                mode={"Box"}

                                //isDisabled={(config_meta.is_readonly === 1 || isReadOnly)}
                                enabled={!(config_meta.is_readonly === 1 || isReadOnly)}
                                className={(validation_error ? "e-input-group e-error" : "e-input")}
                                value={(asset_detail.assetkey > 0 ? (get_meta_value(config_meta.meta_id) + '').split(',') : null)}
                                filtering={(e) => onFiltering(e, config_meta.value_list_data)}
                                change={(e) => onTextChange(config_meta,
                                    config_meta.meta_id, e.value, e)}
                            >

                                <Inject services={[CheckBoxSelection]} />
                            </MultiSelectComponent >
                        
                    </Col>
                </Row>
            </FormGroup>)
        case "discussion":
            if (!isDisscusionFormStyle) {
                validation_error = (config_meta.validation_error === undefined || config_meta.validation_error === null ? false :
                    config_meta.validation_error !== 0)
                return (<FormGroup>


                    <Row form>
                        <Col md={(config_format_name === "single_column" ? "1" : (metaLength === 1 ? "2" : "4"))}
                            hidden={config_meta.meta_name === "" || config_meta.meta_id === "blank"}>
                            <Label>{config_meta.meta_name}</Label>
                        </Col>
                        <Col md={(config_meta.meta_name === "" ? "12" :
                            (config_format_name == "single_column" ? 11 : (metaLength === 1 ? "10" : "8")))}>

                            <AssetDiscussion asset_detail={asset_detail}
                                user={props.user}
                                height="250px">

                            </AssetDiscussion>

                        </Col>
                    </Row>

                </FormGroup>)
            } else {
                return (<div></div>)
            }
        case "inspItem":
            validation_error = (config_meta.validation_error === undefined || config_meta === null ? false :
                config_meta.validation_error !== 0)
            return (<FormGroup>
                <Row form style={{ paddingBottom: "0px", marginBottom: "-10px" }} className={(validation_error ? "form-error" : "")} >
                    <Table borderless size="sm" style={{ margin: '0px' }}>
                        <tbody>
                            <tr>
                                <th scope='row' style={{ width: "40px" }}>
                                    <InspectionButton
                                        isReadOnly={(config_meta.is_readonly === 1 || isReadOnly)}
                                        InspLine={{
                                            "assetkey": asset_detail.assetkey,
                                            "inspLineValue": Number(get_meta_value(config_meta.meta_id))
                                        }}

                                        onChange={(e) => onTextChange(config_meta,
                                            config_meta.meta_id,
                                            e, e)}>

                                    </InspectionButton>
                                </th>
                                <th>
                                    {config_meta.meta_desc}
                                </th>

                                <td style={{ width: "40px" }}>

                                    <ConfigMetaAttachment asset_detail={asset_detail}
                                        config_meta={config_meta} />

                                </td>

                            </tr>

                        </tbody>
                    </Table>


                </Row>
            </FormGroup>)
        default:
            // console.log('---asset_detail---')
            // console.log(asset_detail)
            // console.log(config_meta)

            validation_error = (config_meta.validation_error === undefined || config_meta.validation_error === null ? false :
                config_meta.validation_error !== 0)
            return (<FormGroup hidden={((config_meta.meta_options & 64) === 64) && asset_detail.assetkey < 0}>
                <Row form>
                    <Col md={(isDisscusionFormStyle ? 3 : (config_format_name === "single_column" ? "1" : (metaLength === 1 ? "2" : "4")))}
                        hidden={config_meta.meta_name === "" || config_meta.meta_id === "blank"}>
                        <Label>{config_meta.meta_name}</Label>
                    </Col>
                    <Col md={(config_meta.meta_name === "" ? "12" :
                        (isDisscusionFormStyle ? "9" : (config_format_name == "single_column" ? 11 : (metaLength === 1 ? "10" : "8"))))}>
                        <Input type={config_meta.data_type}
                            name={config_meta.meta_name}
                            hidden={config_meta.meta_id === "blank"}
                            id={config_meta.meta_name}
                            readOnly={(config_meta.is_readonly === 1 || isReadOnly)}
                            style={{
                                backgroundColor: (((config_meta.is_readonly === 1 || isReadOnly)) ? '#F4F4F4' : 'transparent'),
                                color: (((config_meta.is_readonly === 1 || isReadOnly)) ? 'grey' : 'black')
                            }}
                            defaultValue={get_meta_value(config_meta.meta_id)}
                            onChange={(e) => onTextChange(config_meta,
                                config_meta.meta_id, e.target.value, e)}
                            className={(validation_error ? "e-input-group e-error" : (config_meta.data_type === "textarea" ? "e-input-group" : "e-input"))}
                            rows={((config_meta.data_type === "textarea" &&
                                config_meta.value_list_id !== null &&
                                isNumber(config_meta.value_list_id)) ? Number(config_meta.value_list_id) : 1)}
                        //placeholder={config_meta.meta_name} 
                        />
                    </Col>
                </Row>
            </FormGroup>)
    }




}

const ConfigMetaAttachment = (props) => {

    const {
        asset_detail,
        buttonLabel,
        className,
        formName,
        config_meta
    } = props

    const [modal, setModal] = useState(false);
    const toggle = () => setModal(!modal);
    // console.log("-------------------------------------asset_detail")
    // console.log(asset_detail)
    // console.log(config_meta)

    return (
        <div>
            <Button outline color="secondary"
                className="btn-no-border"
                onClick={toggle} style={{ margin: "0 5px 0 5px" }}
            >
                <img
                    src={EditIcon}
                    alt="Edit"
                    style={{ width: "25px", height: "25px", margin: "0 0 0 150" }} />
                {buttonLabel}
            </Button>
            <Modal isOpen={modal}
                toggle={toggle}
                className={className}
                size="lg">
                <ModalHeader toggle={toggle}> {formName}</ModalHeader>
                <ModalBody>
                    <FileManager
                        assetkey={asset_detail.assetkey}
                        controlid={asset_detail.asset_type_id + Math.abs(asset_detail.assetkey) + config_meta.meta_id}
                        folderPath={"tasset/" + asset_detail.asset_type_id + '/'
                            + (asset_detail.assetkey < 0 ? Math.abs(asset_detail.assetkey) : asset_detail.asset_blob_id)
                            + '/' + config_meta.meta_id
                        }

                    //folderPath={"tasset/FAC_INSP_LI/INSPXXXX/fgr_3"}
                    >

                    </FileManager>
                </ModalBody>
                <ModalFooter>
                    <Button color="primary" onClick={toggle}>OK</Button>
                    <Button color="primary" onClick={toggle}>Cancel</Button>
                </ModalFooter>
            </Modal>
        </div>
    );
}


const AssetSectionControls = (props) => {
    const { meta_datas, company_id, asset_detail, isReadOnly, isDisscusionFormStyle, isPrintMode } = props
    let odd = null
    //const [config_format_name, setConfig_format_name] = useState(props.config_format_name)
    // console.log("------>meta_datas<--------")
    // console.log(meta_datas)
    var _config_format_name = ((props.config_format_name === undefined ||
        props.config_format_name === null) ? "" : props.config_format_name)

    return (
        meta_datas.map((e, index) => {

            if ((bitwise((e.meta_options === null ? 0 : e.meta_options), 4) === 4) || isDisscusionFormStyle) {
                _config_format_name = "single_column"
            } else {
                _config_format_name = ""
            }

            if (_config_format_name === "single_column") {
                return (
                    <Row form >
                        <Col xs={"12"}>
                            <AssetControl config_meta={e}
                                user={props.user}
                                company_id={company_id}
                                asset_detail={asset_detail}
                                onAssetMetaUpdate={props.onAssetMetaUpdate}
                                onValueListChange={props.onValueListChange}
                                onTextChange={props.onTextChange}
                                metaLength={meta_datas.length}
                                config_format_name={_config_format_name}
                                isReadOnly={isReadOnly}
                                isDisscusionFormStyle={isDisscusionFormStyle}
                            />
                        </Col>
                    </Row>
                )
            } else if ((index % 2) === 0 || meta_datas.length == 1) {
                odd = e
                if (index === (meta_datas.length - 1)) {
                    return (
                        <Row form >
                            <Col xs={(meta_datas.length === 1 ? "12" : "6")}>
                                <AssetControl config_meta={e}
                                    user={props.user}
                                    company_id={company_id}
                                    asset_detail={asset_detail}
                                    onAssetMetaUpdate={props.onAssetMetaUpdate}
                                    onValueListChange={props.onValueListChange}
                                    onTextChange={props.onTextChange}
                                    metaLength={meta_datas.length}
                                    isReadOnly={isReadOnly}
                                    isDisscusionFormStyle={isDisscusionFormStyle}
                                    isPrintMode={isPrintMode}

                                />
                            </Col>
                        </Row>
                    )
                }
            }
            else {
                return (
                    <Row form>
                        <Col xs="6">
                            <AssetControl config_meta={odd}
                                user={props.user}
                                company_id={company_id}
                                asset_detail={asset_detail}
                                onAssetMetaUpdate={props.onAssetMetaUpdate}
                                onValueListChange={props.onValueListChange}
                                onTextChange={props.onTextChange}
                                metaLength={meta_datas.length}
                                isReadOnly={isReadOnly}
                                isDisscusionFormStyle={isDisscusionFormStyle}
                                key={Random()}
                                isPrintMode={isPrintMode}
                            />
                        </Col>
                        <Col xs="6">
                            <AssetControl config_meta={e}
                                user={props.user}
                                company_id={company_id}
                                asset_detail={asset_detail}
                                onAssetMetaUpdate={props.onAssetMetaUpdate}
                                onValueListChange={props.onValueListChange}
                                onTextChange={props.onTextChange}
                                metaLength={meta_datas.length}
                                isReadOnly={isReadOnly}
                                isDisscusionFormStyle={isDisscusionFormStyle}
                                isPrintMode={isPrintMode}
                            />
                        </Col>
                    </Row>
                )

            }

            _config_format_name = (props.config_format_name === undefined ||
                props.config_format_name === null ? "" : props.config_format_name)
        })


    )
}


const AssetSection = (props) => {
    const { asset_config,
        company_id,
        asset_detail,
        isReadOnly,
        assetType,
        isDisscusionFormStyle,
        isPrintMode
    } = props

    // console.log("----->asset_config<------")
    // console.log(assetType)

    const [modalMapView, setModalMapView] = useState(false)

    const openSimpleMap = () => {
        //props.toggle()
        setModalMapView(!modalMapView)
    }

    const openInspInGooleMap = () => {
        console.log("asset_detail")
        console.log(asset_detail)
        var lat = 0
        var long = 0

        if (asset_detail !== undefined) {
            asset_detail.meta_datas.map(a => {
                if (a.config_name === 'Coordinate' && a.meta_id === "latitude") {
                    lat = Number(a.meta_value)
                }
                if (a.config_name === 'Coordinate' && a.meta_id === "longitude") {
                    long = Number(a.meta_value)
                }

                if (lat !== 0 && long !== 0) {
                    return
                }
            })
        }

        if (lat !== 0.0 && long !== 0.0) {
            const url = "https://www.google.com/maps/search/?api=1&query=" + lat + "," + long
            window.open(url, '_blank')
        }

    }

    return (
        asset_config
            .sort((a, b) => a.config_order >= b.config_order ? 1 : -1)
            .map(f => {
                if (f.config_id === "document") {
                    //  if (asset_detail.assetkey > 0)
                    return (
                        <Media>
                            <Media body>
                                <Media heading hidden={(f.config_name === "" || f.config_name === "_")}>
                                    {f.config_name}
                                </Media>
                                <FileManager
                                    assetkey={asset_detail.assetkey}
                                    controlid={asset_detail.asset_type_id + Math.abs(asset_detail.assetkey)}
                                    //folderPath={"tasset/" + asset_detail.asset_type_id + '/' + asset_detail.asset_blob_id}
                                    folderPath={"tasset/" + (asset_detail.assetkey < 0 ? asset_detail.asset_type_id  + '/' + Math.abs(asset_detail.assetkey) : asset_detail.asset_blob_id)}
                                >


                                </FileManager>
                            </Media>
                        </Media>
                    )

                } else if (f.config_id === "discussion" && !isDisscusionFormStyle) {
                    if (asset_detail.assetkey > 0)
                        return (

                            <Media>
                                <Media body>
                                    <Media heading hidden={(f.config_name === "" || f.config_name === "_")}>
                                        {f.config_name}
                                    </Media >

                                    <AssetDiscussion asset_detail={asset_detail}
                                        user={props.user}
                                        height="250px">
                                    </AssetDiscussion>

                                </Media>
                            </Media >

                        )

                } else if (f.meta_datas.length === 0) {
                    return
                }
                else
                    return (
                        <div>
                            <Media hidden={f.meta_datas.length === 0 ||
                                (isDisscusionFormStyle && f.config_id === "discussion")}>
                                <Media body >
                                    <Media heading hidden={(f.config_name === "" || f.config_name === "_")}>
                                        {f.config_name}
                                        {(f.config_id === 'coordinate' ?
                                            <Button style={{
                                                marginRight: "5px", marginLeft: "15px",
                                                width: "25px", padding: "0px",
                                                marginBottom: "5px",
                                            }}
                                                outline={false}
                                                onClick={() => openInspInGooleMap()}
                                                id="gpsCoord"
                                                //disabled={props.isReadOnly}
                                                hidden={false} /**hide button for now having issue with view google map  
                                                                on top of another popup */
                                                color="link">
                                                <img
                                                    src={globebw}
                                                    alt="Edit"
                                                    style={{ width: "25px", height: "25px", padding: "0px" }} />

                                            </Button> : '')}
                                    </Media>

                                    <AssetSectionControls meta_datas={f.meta_datas}
                                        company_id={company_id}
                                        asset_detail={asset_detail}
                                        config_format_name={f.config_format_name}
                                        user={props.user}
                                        onAssetMetaUpdate={props.onAssetMetaUpdate}
                                        onValueListChange={props.onValueListChange}
                                        onTextChange={props.onTextChange}
                                        isReadOnly={props.isReadOnly}
                                        isDisscusionFormStyle={isDisscusionFormStyle}
                                        isPrintMode={isPrintMode} />

                                </Media>
                            </Media>

                            <div>
                                <Modal isOpen={modalMapView}
                                    // className="alway-on-top"
                                    contentClassName="full-screen-modal"
                                    toggle={() => setModalMapView()}
                                    style={{ margin: 0, flex: 1 }}
                                //backdrop={"static"}
                                >
                                    <ModalHeader toggle={() => setModalMapView()}>
                                        {"Map View"}
                                    </ModalHeader>
                                    <ModalBody>

                                        <AssetMapViewSimple
                                            //toggle={setModalMapView}
                                            user={props.user}
                                            //key={Math.random()}
                                            companyID={company_id}
                                            showPipeline={false}
                                            showLabel={true}
                                            showSubsystem={false}
                                            basinKMLFile={'https://wbrstorageaccountprod.blob.core.windows.net/kml/WBRNDBRoutes.kml'}
                                            subSystemKMLFile={null}
                                            assetCoor={[{
                                                asset_name: "Hellow",
                                                lat: 29.740700,
                                                lng: -95.463600
                                            }]}
                                        >

                                        </AssetMapViewSimple>

                                    </ModalBody>

                                </Modal>
                            </div>
                        </div>
                    )
            })
    )


}

const AssetDetails = (props) => {

    const { assetConfigList, assetKey, assetTypeID, companyID,
        parentAssetKey, assetStatus, assetCatID } = props

    const [assetType, setAssetType] = useState(props.assetType)
    const [isDisscusionFormStyle, setIsDisscusionFormStyle] = useState((props.assetType === undefined || props.assetType.length <= 0) ? false :
        bitwise(((props.assetType[0].form_options === null) ? 0 : props.assetType[0].form_options), 64) === 64)
    const [isAssetTypeLoaded, setIsAssetTypeLoaded] = useState((props.assetType === undefined || props.assetType.length <= 0) ? false : true)
    const [isReadOnly, setIsReadOnly] = useState(props.isReadOnly)
    const [isAssetLoaded, setIsAssetLoaded] = useState(false);
    const [assetDetails, setAssetDetails] = useState([])

    const [msgBody, setMsgBody] = useState('')
    const [msgHeader, setMsgHeader] = useState('')
    const [modalMsg, setModalMsg] = useState(false)

    const [assetConfig, setAssetConfig] = useState([])

    const [selAssetStatus, setSelAssetStatus] = useState([])
    const [isAssetConfigLoaded, setIsAssetConfigLoaded] = useState(false)
    const [reloadForm, setFormReload] = useState(0)

    const [showValidateAlert, setShowValidateAlert] = useState(false)
    const [validateMsg, setValidateMsg] = useState('')

    const [isPrintMode, setIsPrintMode] = useState(false)

    const onAssetMetaUpdate = (asset_detail) => {
        assetDetails[0].meta_datas = asset_detail.meta_datas
        setShowValidateAlert(false)
        setAddtionalMetaValues(asset_detail)
        setAssetDetails(assetDetails)
    }

    const toggleSaveAlert = () => {
        setShowValidateAlert({ showValidateAlert: !showValidateAlert },
            () => { window.setTimeout(() => { setShowValidateAlert(false) }, 3000) })
    }

    const setAddtionalMetaValues = (data) => {

        // console.log("data")
        // console.log(data)

        if (data.auto_fill_meta_ids !== undefined && data.additional_values !== undefined) {
            if (data.auto_fill_meta_ids !== "") {
                if (data.additional_values !== "") {

                    //"asset_id||A0001725@@qr_code||1900090"
                    const add_values = data.additional_values.split("@@")

                    //"dvi_qrcode@qr_code|dvi_truckid@asset_id"
                    data.auto_fill_meta_ids.split("|").map(e => {
                        const fills_meta = e.split("@")
                        // console.log("meta_id: " + fills_meta[0])
                        // console.log("field_id: " + fills_meta[1])
                        var bMetaExist = false
                        const fieldName = fills_meta[0]

                        add_values.filter(k => k.includes(fills_meta[1] + '||'))
                            .map(g => {
                                const meta_value = g.split("||")
                                // console.log("---->meta_value<----")
                                // console.log(meta_value[1])

                                const fieldValue = meta_value[1]

                                assetConfig.map(e => {
                                    e.asset_config.map(f => {
                                        // console.log("f")
                                        // console.log(f)
                                        f.meta_datas.filter(g => g.meta_id === fills_meta[0]).map(k => {
                                            // console.log("k.asset_config_meta_key")
                                            // console.log(k.asset_config_meta_key)
                                            // console.log(data)

                                            bMetaExist = false
                                            let fieldType = k.data_type


                                            if (data.meta_datas === null) {
                                                data.meta_datas = []
                                            }

                                            data.meta_datas.map(l => {
                                                if (l.meta_id === fieldName) {
                                                    l.asset_meta_key = -1
                                                    l.meta_value = (fieldValue)
                                                    l.meta_text = (fieldValue)

                                                    bMetaExist = true
                                                    return
                                                }
                                            })



                                            if (!bMetaExist) {
                                                ///console.log("AssetControl--->fieldName ----  here")

                                                const meta_data = {
                                                    "asset_meta_key": -1,
                                                    "meta_id": fieldName,
                                                    "meta_value": fieldValue,
                                                    //"meta_text": (fieldType === "value_list" ? meta_value.label : null),
                                                    "meta_text": fieldValue,
                                                    "meta_name": k.meta_name,
                                                    "asset_config_meta_key": k.asset_config_meta_key,
                                                    "assetkey": data.assetkey,
                                                    "created_by": getUserADAccount(props.user.account.userName)
                                                }

                                                data.meta_datas.push(meta_data)

                                            }



                                        })
                                    })


                                })

                                // var bMetaExist = false
                                // let fieldType = config_meta.data_type


                                // if (asset_detail.meta_datas === null) {
                                //     asset_detail.meta_datas = []
                                // }

                                // asset_detail.meta_datas.map(e => {
                                //     if (e.meta_id === fieldName) {
                                //         e.asset_meta_key = -1
                                //         e.meta_value = (fieldType === "value_list" ? fieldValue.value : fieldValue)
                                //         e.meta_text = (fieldType === "value_list" ? fieldValue.label :
                                //             (fieldType === "inspItem" ? getInspItemText(fieldValue) : (null)))

                                //         bMetaExist = true

                                //         if (fieldType === "value_list") {
                                //             props.onValueListChange(config_meta.asset_config_key, fieldName, fieldValue)
                                //         }
                                //         return
                                //     }
                                // })

                                // if (!bMetaExist) {
                                //     console.log("AssetControl--->fieldName ----  here")

                                //     let new_meta = create_meta_data(fieldType, fieldName, fieldValue)
                                //     asset_detail.meta_datas.push(new_meta)

                                //     if (fieldType === "value_list") {
                                //         asset_detail.meta_datas.map(e => {
                                //             if (e.meta_id === fieldName) {
                                //                 e.asset_meta_key = -1
                                //                 e.meta_value = (fieldType === "value_list" ? fieldValue.value : fieldValue)
                                //                 e.meta_text = (fieldType === "value_list" ? fieldValue.label :
                                //                     (fieldType === "inspItem" ? getInspItemText(fieldValue) : (null)))
                                //                 bMetaExist = true

                                //                 if (fieldType === "value_list") {
                                //                     props.onValueListChange(config_meta.asset_config_key, fieldName, fieldValue)
                                //                 }
                                //             }
                                //         })
                                //     }
                                // }





                            })
                    })
                }
            }

        }

    }

    const getAssetConfig = () => {
        return new Promise((res, rej) => {


            var bLoadAsset = 0

            // console.log("->getAssetConfig  - " + assetTypeID)
            // console.log(assetConfigList)


            if (assetConfigList === undefined || assetConfigList === null) {
                bLoadAsset = 1
            } else if (assetConfigList.length === 0) {
                bLoadAsset = 1
            } else {

                assetConfigList.map(a => {
                    if (a.asset_type_id === assetTypeID) {

                        // console.log(` -> AssetDetails - found asset config ` + assetTypeID)
                        // console.log(a.assetConfig)

                        /* need to reset validation error */

                        //a.assetConfig.map(b => b.assetConfig.map(c => c.asset_config.map(d => d.meta_datas.map(e => e.validation_error = 0))))
                        a.assetConfig.map(b => b.asset_config.map(c => c.meta_datas.map(d => d.validation_error = 0)))

                        setAssetConfig(a.assetConfig)
                        setIsAssetConfigLoaded(true)
                        // getAssetDetails()
                        bLoadAsset = 2;
                    }
                })

                if (bLoadAsset = 0) {
                    // console.log(` --> not found asset config ` + assetTypeID)
                    // console.log(assetConfigList)
                    bLoadAsset = 1
                }

            }

            if (bLoadAsset === 1) {
                //                let myURI = apiURL + 'Asset/' + companyID + '/AssetConfig/ByAssetType/' + assetTypeID
                let myURI = apiURL + 'Asset/' + companyID + '/OP/1/AssetConfig/ByAssetType/' + assetTypeID

                // console.log(`--> AssetDetails - load asset config ` + assetTypeID)
                // console.log(assetDetails[0])

                fetch(myURI, { modes: 'no-cors' })
                    .then(res => res.json())
                    .then(json => {
                        //console.log(json)
                        getValueList(companyID, json, isReadOnly).then(data => {

                            setAssetConfig(data)
                            setIsAssetConfigLoaded(true)
                            // getAssetDetails()
                            // console.log(`AssetDetails ------RELOADDDDDDDDDD------>Load assetConfig`)

                            //updateAssetConfigList(assetTypeID, data)
                        })

                    });
            }

            res(true)
        })

    }


    const onValueListChange = (asset_config_key, fieldName, fieldValue) => {

        if (assetConfig !== undefined) {
            console.log("---------->assetConfig")
            console.log(assetConfig)
            assetConfig.filter(a => a.company_id === companyID)
                .map(b => {
                    b.asset_config.filter(e => e.asset_config_key === asset_config_key)
                        .map(f => {
                            f.meta_datas.filter(e => e.parent_meta_id === fieldName)
                                .map(f => {
                                    console.log('child value list')
                                    console.log(f)
                                    if (f.value_list_data_org === undefined) {
                                        f.value_list_data_org = f.value_list_data
                                    }

                                    let filterValue = (fieldValue.value + '')
                                    let filterLabel = (fieldValue.field_label + '')
                                    // console.log('------filterValue')
                                    // console.log(fieldValue)
                                    // console.log(f.value_list_data_org)

                                    f.value_list_data = []

                                    f.value_list_data = f.value_list_data_org.filter(k => {

                                        if (k.field_parent.includes(',')) {
                                            return (k.field_parent.includes(filterValue) || k.field_parent.includes(filterLabel))
                                        } else {
                                            return (k.field_parent === filterValue || k.field_parent === filterLabel)
                                        }
                                    })
                                    //f.value_list_data = f.value_list_data_org.filter(k => k.field_parent.includes(fieldValue.value))

                                })
                        })

                })

            //Hau Nguyen comment out on 05/08/2024 - this code cause form validate issue when a value_list item
            //is selected - uncomment this code if have data loading issue
            //setFormReload(Math.random)
        }
    }

    const onTextChange = (fieldName, fieldValue, e) => {

        if (fieldName === "asset_name") {
            assetDetails[0].asset_name = fieldValue
        } else if (fieldName === "asset_status") {
            assetDetails[0].status_id = e.label
            setSelAssetStatus(e)
        }

    }

    const newAsset = () => {
        // console.log("assetConfig")
        // console.log(assetConfig)

        const tempDTO = {
            "company_id": companyID,
            "asset_name": "",
            "assetkey": assetKey,
            "created_by": props.user.account.name,
            "created_at": new Date().toISOString(),
            "status_id": "ACT",
            "parent_assetkey": (parentAssetKey > 0 ? parentAssetKey : null),
            "asset_id": "XXXXXX",
            "asset_type_key": assetConfig[0].asset_type_key,
            "meta_datas": null,
            "asset_type_id": assetConfig[0].asset_type_char,
            "allow_actions": (isAllow(assetType[0].form_options, "32") ? "Submit" : "")
        }

        return [tempDTO]
    }

    const getAssetDetails = () => {

        // console.log("assetKey")
        // console.log(assetKey)

        if (assetKey > 0) {
            let myURI = apiURL + 'Asset/' + companyID + '/Detail/ByAssetKey/' + assetKey
            //console.log(myURI)

            fetch(myURI, { modes: 'no-cors' })
                .then(res => res.json())
                .then(json => {

                    // console.log("getAssetDetails")
                    // console.log(json)

                    setAssetDetails(json)
                    setIsAssetLoaded(true)
                    setConfigForm(json)
                    setSelAssetStatus(assetStatus.filter(e => e.label === json[0].status_id))
                    setIsReadOnly(json[0].allow_actions === "Readonly" || isReadOnly)
                });
        } else {
            const temp = newAsset()
            setAssetDetails(temp)
            setIsAssetLoaded(true)
            setConfigForm(temp)
            setIsDisscusionFormStyle(false)
        }
    }

    const refreshData = () => {
        if (assetKey > 0) {
            let myURI = apiURL + 'Asset/' + companyID + '/Detail/ByAssetKey/' + assetKey
            // console.log(myURI)

            fetch(myURI, { modes: 'no-cors' })
                .then(res => res.json())
                .then(json => {
                    // console.log("refreshData")
                    // console.log(json)
                    setAssetDetails(json)
                    setIsReadOnly(json[0].allow_actions === "Readonly" || isReadOnly)
                    setFormReload(Math.random)

                });
        }
    }

    const closeForm = () => {
        props.toggle()
    }

    const printForm = () => {
        let id = 'id_assetdetails'
        const prtContent = document.getElementById(id)

        const iframe = document.frames
            ? document.frames["iframe"]
            : document.getElementById("iframe");
        iframe.srcdoc = prtContent.innerHTML;

        const iframeWindow = iframe.contentWindow || iframe;


        iframe.focus();
        iframeWindow.print();

        return false;
    }

    /**This function to preset value_list if any on the form.
     * some value list may depend on each other.
     * need to call after asset_detaisl is loaded
     */
    const setConfigForm = (asset_details) => {
        var parent_assetkey = 0
        if (assetConfig !== undefined) {
            assetConfig.filter(a => a.company_id === companyID)
                .map(b => {
                    b.asset_config.map(f => {
                        f.meta_datas.filter(e => e.data_type === "value_list")
                            .map(f => {
                                console.log('child value list')
                                console.log(f)

                                if (f.parent_meta_id !== null) {
                                    //need to find parent meta value to filter
                                    asset_details.map(g => {
                                        //console.log("KKKKKKK")
                                        if (g.meta_datas !== null) {
                                            //console.log(g.meta_datas)
                                            let parent_meta = g.meta_datas.filter(p => p.meta_id === f.parent_meta_id)

                                            console.log("paret_meta")
                                            console.log(parent_meta)

                                            if (parent_meta.length > 0) {
                                                f.parent_meta_value = parent_meta[0].meta_value
                                                f.parent_meta_label = parent_meta[0].meta_text
                                            }

                                        } else if (g.assetkey < 0 && f.parent_meta_id === "parent_assetkey") {
                                            parent_assetkey = g.parent_assetkey
                                        }
                                    })


                                    if (f.value_list_data_org === undefined) {
                                        f.value_list_data_org = f.value_list_data
                                    }

                                    if (f.value_list_data !== undefined) {
                                        if (parent_assetkey > 0) {
                                            f.value_list_data = f.value_list_data_org.filter(k => k.value === (parent_assetkey + ''))

                                        } else {
                                            //f.value_list_data = f.value_list_data_org.filter(k => k.field_parent === f.parent_meta_value)


                                            //f.value_list_data = f.value_list_data_org.filter(k => k.field_parent === f.parent_meta_value)

                                            f.value_list_data = f.value_list_data_org.filter(k => {
                                                if (k.field_parent.includes(',')) {
                                                    return (k.field_parent.includes(f.parent_meta_value) || k.field_parent.includes(f.parent_meta_label))
                                                } else {
                                                    return (k.field_parent === f.parent_meta_value || k.field_parent === f.parent_meta_label)
                                                }
                                            })

                                        }
                                    }


                                }

                            })
                    })

                })
        }
        setIsAssetConfigLoaded(true);
    }


    const createUpdateAssetMetaDTO = () => {

        var temp_metas = []

        if (assetDetails[0].meta_datas !== undefined) {
            assetDetails[0].meta_datas
                .filter(a => a.asset_meta_key < 0)
                .map(b => {
                    const meta = {
                        "asset_meta_key": -1,
                        "meta_id": b.meta_id,
                        "meta_value": b.meta_value,
                        "meta_text": b.meta_text,
                        "meta_name": b.meta_name,
                        "asset_config_meta_key": b.asset_config_meta_key,
                        "assetkey": b.assetkey,
                        "created_by": getUserADAccount(props.user.account.userName)
                    }

                    temp_metas.push(meta)
                })
        }

        return temp_metas;
    }

    const createUpdateAssetDTO = () => {
        const updateDTO = {
            "company_id": companyID,
            "assetkey": assetDetails[0].assetkey,
            "modified_by": getUserADAccount(props.user.account.userName),
            "parent_assetkey": assetDetails[0].parent_assetkey,
            "asset_name": assetDetails[0].asset_name,
            "status_id": assetDetails[0].status_id,
            "meta_datas": createUpdateAssetMetaDTO()
        }

        return updateDTO
    }

    const createAssetActionDTO = (action) => {
        const updateDTO = {
            "assetkey": assetDetails[0].assetkey,
            "action_by": getUserADAccount(props.user.account.userName),
            "action": action
        }

        return updateDTO
    }

    const assetAction = (action) => {
        try {
            dataValidation().then((data) => {
                if (data.ok) {
                    //OK save or add data
                    if (assetDetails[0].assetkey > 0) {
                        /* perform action */
                        updateAsset(action)
                    } else {
                        /* Add action then peform action */
                        addAsset(action)
                    }
                } else {
                    // console.log("Missing data: " + data.message)
                    setValidateMsg('Missing Data - Please complete highlight red items below')
                    setShowValidateAlert(true)
                }
            })
        } catch (error) {

        }


    }

    const spasset_action = (action) => {
        let myURI = apiURL + 'Asset/AssetAction'
        const u_meta_datas = createAssetActionDTO(action)
        // console.log("assetDetails[0]")
        // console.log(u_meta_datas)

        fetch(myURI, apiPOSTHeader(u_meta_datas))
            .then(async response => {
                if (!response.ok) {
                    const rspData = response.text();
                    var error = (rspData && rspData.message) || response.status;

                    rspData.then(t => {
                        setMsgBody(t)
                        setMsgHeader("Error")
                        setModalMsg(!modalMsg)
                    })

                    return Promise.reject(error)
                } else
                    return response.json()
            }).then(json => {

                props.refreshData();
                props.toggle();

            }).catch(error => {
                setMsgBody("PUT error: " + error)
                setMsgHeader("Error")
                setModalMsg(!modalMsg)
            })
    }

    const updateAsset = (action = "") => {
        //let myURI = apiURL + 'Asset/MetaAdd'      
        let myURI = apiURL + 'Asset/AssetUpdate'

        const u_meta_datas = createUpdateAssetDTO()

        fetch(myURI, apiPOSTHeader(u_meta_datas))
            .then(async response => {
                if (!response.ok) {
                    const rspData = response.text();
                    var error = (rspData && rspData.message) || response.status;

                    rspData.then(t => {
                        setMsgBody(t)
                        setMsgHeader("Error")
                        setModalMsg(!modalMsg)

                    })

                    return Promise.reject(error)
                } else
                    return response.json()
            }).then(json => {
                if (action === "") {
                   // props.refreshData(); /*Comment out by HN 11/14/2024 by not refresh when update data will not remove group for users.*/
                                            /*requested by Zoe for Water Connect. user can hit refresh if user want to refresh.  */
                    props.toggle();
                } else {
                    spasset_action(action)
                }

            }).catch(error => {
                setMsgBody("PUT error: " + error)
                setMsgHeader("Error")
                setModalMsg(!modalMsg)
            })
    }

    const createAddAssetDTO = () => {
        const addDTO = {
            "company_id": companyID,
            "parent_assetkey": assetDetails[0].parent_assetkey,
            "asset_name": assetDetails[0].asset_name,
            "asset_type_key": assetConfig[0].asset_type_key,
            "created_by": getUserADAccount(props.user.account.userName),
            "asset_id": "",
            "assetkey": assetDetails[0].assetkey,
            "meta_datas": createUpdateAssetMetaDTO(),

        }

        return addDTO
    }


    const addAsset = (action = "") => {
        let myURI = apiURL + 'Asset/AssetAdd'

        const u_meta_datas = createAddAssetDTO()

        console.log("assetDetails[0]")
        console.log(u_meta_datas)

        fetch(myURI, apiPOSTHeader(u_meta_datas))
            .then(async response => {
                if (!response.ok) {
                    const rspData = response.text();
                    var error = (rspData && rspData.message) || response.status;

                    rspData.then(t => {
                        setMsgBody(t)
                        setMsgHeader("Error")
                        setModalMsg(!modalMsg)

                    })

                    return Promise.reject(error)
                } else
                    return response.json()
            }).then(json => {
                // console.log("----->json new asset,----")
                // console.log(json)

                if (action === "") {
                    props.refreshData();
                    props.toggle();
                } else {
                    assetDetails[0].assetkey = json[0].assetkey
                    spasset_action(action)
                }


            }).catch(error => {
                setMsgBody("PUT error: " + error)
                setMsgHeader("Error")
                setModalMsg(!modalMsg)
            })
    }

    const dataValidation = () => {

        return new Promise((resolve, reject) => {
            const returnVal = {
                "ok": true,
                "message": "",
                "validatedItem": []
            }

            var bOk = true, missing_data = ''

            assetConfig.map(a => {
                //console.log("dataValidation")
                let assetDetail = assetDetails[0]
                a.asset_config.map(b => {
                    b.meta_datas.map(c => {
                        missing_data = ''
                        if (Number(c.is_required) === 1) {
                            if (assetDetail.meta_datas !== null) {

                                let meta_data = assetDetail.meta_datas.filter(d => d.asset_config_meta_key === c.asset_config_meta_key)
                                if (meta_data.length === 0) {
                                    bOk = false
                                } else {
                                    //console.log(meta_data)
                                    if (c.data_type === "inspItem" &&
                                        ((meta_data[0].meta_value + '') === "0" || (meta_data[0].meta_text + '').trim() === '')) {
                                        bOk = false
                                    } else if ((meta_data[0].meta_value + '').trim() === '') {
                                        bOk = false

                                    }
                                }
                            } else {
                                bOk = false
                            }

                            if (!bOk) {
                                returnVal.ok = false
                                if (returnVal.message !== '') {
                                    returnVal.message = returnVal.message + '; '
                                }
                                returnVal.message = returnVal.message + c.meta_name
                                returnVal.validatedItem.push(c)
                                c.validation_error = 1
                                bOk = true
                            }
                        }
                        // console.log("assetDetails[0]")
                        // console.log(assetDetails[0])
                    })
                })
            })

            if (!returnVal.ok) {
                // console.log("assetConfig----->")
                // console.log(assetConfig)
                setAssetConfig(assetConfig)
            }
            resolve(returnVal)
        })

    }

    const saveData = () => {

        try {
            dataValidation().then((data) => {
                if (data.ok) {
                    //OK save or add data
                    if (assetDetails[0].assetkey > 0) {
                        updateAsset()
                    } else {
                        addAsset()
                    }
                } else {
                    //console.log("Missing data: " + data.message)
                    setValidateMsg('Missing Data - Please complete highlight red items below')
                    setShowValidateAlert(true)
                }
            })
        } catch (error) {

        }
    }



    const isAllow = (e, value) => {

        var rntVal
        value.split(',').some(a => {

            const temp = Number(a)
            rntVal = ((e & temp) === temp)
            if (rntVal) {
                return true
            }
        })


        return rntVal

    }

    const isVisible = (e, value) => {

        return ((Number(e) === 1) || (Number(e) & Number(value)) === Number(value))

    }



    const button_action_label = (e, button_type) => {
        var btnLabel = ""
        // console.log(e)

        if (e.allow_actions !== undefined && e.allow_actions !== "") {
            const allow_actions = e.allow_actions

            allow_actions.split(',').map(e => {
                if (button_type.includes(e)) {
                    btnLabel = e
                    return true
                }
            })
        }

        return btnLabel;
    }

    const beforeContentPrint = () => {
        console.log("beforeContentPrint")
        setIsPrintMode(true)
    }

    const afterContentPrint = () => {
        console.log("aftercontenPrint")
        setIsPrintMode(false)
    }
    useEffect(() => {
        // console.log("--------->useEffect ---> assetConfig")
        // console.log(assetConfig)

        if (assetConfig === undefined || assetConfig === null) {
            // console.log("--------->useEffect ---> undefined")
            getAssetConfig()
        } else if (assetConfig.length > 0) {
            //console.log("--------->useEffect ---> config.legth > 0")
            getAssetDetails()
        } else {
            // console.log("--------->useEffect ---> else")
            getAssetConfig()
        }


        // console.log( '-------------hdhdhd-----------')
        // console.log( assetType)
        if (assetType === undefined || assetType.length <= 0) {
            // console.log('-------------hdhdhd-----------')
            getAssetType()
        }

    }, [assetConfig])


    const getAssetType = () => {

        // console.log('getAssetType')
        //assetTypeID, companyID

        if (assetTypeID !== "") {
            let myURI = apiURL + 'Asset/' + companyID + '/' + assetCatID
                + '/AssetType/ByAssetType/' + assetTypeID

            fetch(myURI, { modes: 'no-cors' })
                .then(res => res.json())
                .then(json => {

                    // console.log("assettype")
                    // console.log(json)
                    setAssetType(json)
                    setIsAssetTypeLoaded(true)

                });
        }
    }

    let componentRef = useRef();

    if (!isAssetLoaded) {
        return (<div>Loadding asset details ......</div>)
    } else if (!isAssetConfigLoaded) {
        return (<div> Loading asset configuration ......</div>)
    } else if (!isAssetTypeLoaded) {
        //getAssetType()
        return (<div> Loading asset type ......</div>)
    }
    return (
        assetConfig.map(e => {
            // console.log("selAssetStatus")
            // //console.log(selAssetStatus)
            // console.log(assetDetails[0])

            return (
                <div>
                    <FormGroup>

                        <FormGroup>
                            <Row form style={{ marginTop: "-10px", marginBottom: "-10px" }}>
                                <Col sm={5}>

                                    <Button color="secondary" className="float-left"
                                        //hidden={isDataChange}
                                        onClick={() => closeForm()}
                                    >Close</Button>

                                    <Button color="primary" className="float-left"
                                        style={{ marginLeft: "5px", marginRight: "5px" }}
                                        //ref={ref}
                                        hidden={isReadOnly}
                                        onClick={() => saveData()}
                                    >Save</Button>

                                    {/* <Button color="primary" outline className="float-left"
                                        // disabled={isFormReadOnly}
                                        hidden={(assetKey <= 0 || assetConfig === undefined || assetConfig === null)}
                                        style={{ height: "40px", marginLeft: "5px", marginRight: "5px" }}
                                        //hidden={this.setIsReadOnly(statusKey)}
                                        onClick={() => refreshData()}
                                    >Refresh</Button> */}

                                    {/* <Button color="secondary" className="float-left"
                                        //hidden={isDataChange}
                                        onClick={() => printForm()}
                                    >Print</Button> */}

                                    <ReactToPrint
                                        trigger={() => <Button color="primary"
                                            outline className="float-left"
                                            hidden={(assetKey <= 0 || assetConfig === undefined || assetConfig === null)}
                                            style={{ height: "40px", marginLeft: "5px", marginRight: "5px" }}>Print</Button>}
                                        documentTitle={assetDetails[0].asset_type_desc}
                                        onBeforeGetContent={() => beforeContentPrint()}
                                        onAfterPrint={() => afterContentPrint()}
                                        content={() => componentRef}
                                    />

                                    {/* This button have action: Submit, Complete */}
                                    <ConfirmModal outline={true}
                                        color="success"
                                        className="float-left"
                                        buttonLabel={button_action_label(assetDetails[0], "Submit,Complete")}
                                        hidden={isReadOnly
                                            || (assetKey <= 0 && !isAllow(assetType[0].form_options, "32"))
                                            || (assetType !== undefined && assetType.length > 0 ? !isAllow(assetType[0].form_options, "4,8,32") : true)
                                            || button_action_label(assetDetails[0], "Submit,Complete") === ''
                                        }
                                        formName="Confirm"
                                        id={assetKey}
                                        message={"Are you sure you want to " + button_action_label(assetDetails[0], "Submit,Complete") + " this record? "}
                                        onClickYes={(e) => assetAction(button_action_label(assetDetails[0], "Submit,Complete"))}
                                        image={<img
                                            src={EditIcon}
                                            id={assetKey}
                                            alt="Edit"
                                            style={{ width: "20px", margin: "0 5px 0 0" }} />}>
                                    </ConfirmModal>
                                </Col>

                                <Col sm={5}>
                                    <Alert color="danger" isOpen={showValidateAlert}
                                        style={{ margin: "0px", paddingTop: "8px", height: "40px" }}>
                                        {validateMsg}
                                    </Alert>

                                </Col>
                                <Col sm={2}>
                                    <ConfirmModal outline={true}
                                        color="danger"
                                        className="float-right"
                                        buttonLabel={button_action_label(assetDetails[0], "Delete,Cancel")}
                                        hidden={isReadOnly
                                            || assetKey <= 0
                                            || (assetType !== undefined && assetType.length > 0 ? !isAllow(assetType[0].form_options, "4,8,16") : false)
                                            || button_action_label(assetDetails[0], "Delete,Cancel") === ''}
                                        formName="Confirm"
                                        id={assetKey}
                                        message={"Are you sure you want to " + button_action_label(assetDetails[0], "Delete,Cancel") + " this record? "}
                                        onClickYes={(e) => assetAction(button_action_label(assetDetails[0], "Delete,Cancel"))}

                                        image={<img
                                            src={TrashBin}
                                            id={assetKey}
                                            alt="Edit"
                                            style={{ width: "20px", margin: "0 5px 0 0" }} />}>
                                    </ConfirmModal>
                                </Col>
                            </Row>
                        </FormGroup>

                        <div ref={(refer) => (componentRef = refer)}>
                            <table className="print-component">
                                <thead>
                                    <tr>
                                        <th>
                                            {/* <img src={logo} 
                                                 height={"40px"} width={"40px"} alt="logo" /> */}
                                            <div>
                                                {assetDetails[0].asset_type_desc}
                                            </div>
                                        </th>
                                    </tr>
                                </thead>
                            </table>

                            <ColoredLine color="grey" style={{ marginTop: "-10px", marginBottom: "5px" }} />
                            <Row form id="id_assetdetails">
                                <Col md={(isDisscusionFormStyle ? 7 : 12)}>


                                    <FormGroup hidden={(assetType !== undefined && assetType.length > 0 ?
                                        (assetType[0].show_header === 0 ||
                                            (assetKey <= 0 && isAllow(assetType[0].show_header, "64"))) :
                                        false)}>
                                        <Row xs="2" form style={{ marginTop: "5px" }}>
                                            {/* hidden={!isVisible(assetType[0].show_header,2)} */}
                                            <Col md={(isDisscusionFormStyle ? "12" : "")}>
                                                <Row form xs="1">
                                                    <Col hidden={!isVisible(assetType[0].show_header, 2)}>
                                                        <Row form>
                                                            <Col md={(isDisscusionFormStyle ? "3" : "4")}>
                                                                <Label>{"ID"}</Label>
                                                            </Col>
                                                            <Col md={(isDisscusionFormStyle ? "9" : "8")}>
                                                                <Input type="text"
                                                                    name={"asset_id"}
                                                                    id={"asset_id"}
                                                                    className='e-input'
                                                                    style={{ backgroundColor: "transparent", color: 'grey' }}
                                                                    readOnly={true}
                                                                    value={assetDetails[0].asset_id}
                                                                    defaultValue={assetDetails[0].asset_id}
                                                                    placeholder={"ID"} />
                                                            </Col>
                                                        </Row>
                                                    </Col>

                                                    {/* </Row>

                        <Row form style={{ marginTop: "5px", marginBottom: "10px" }}> */}
                                                    <Col hidden={!isVisible(assetType[0].show_header, 8)}>
                                                        <Row form style={{ marginTop: "5px", marginBottom: "5px" }}>

                                                            <Col md={(isDisscusionFormStyle ? "3" : "4")}>
                                                                <Label>{"Name"}</Label>
                                                            </Col>
                                                            <Col md={(isDisscusionFormStyle ? "9" : "8")}>
                                                                <Input type="text"
                                                                    name={"name"}
                                                                    id={"name"}
                                                                    readOnly={isReadOnly}
                                                                    className='e-input'
                                                                    style={{ backgroundColor: "transparent", color: "grey" }}
                                                                    //value={assetDetails[0].asset_name}
                                                                    defaultValue={assetDetails[0].asset_name}
                                                                    onChange={(e) => onTextChange("asset_name", e.target.value, e)}
                                                                    placeholder={"Name"} />
                                                            </Col>
                                                        </Row>
                                                    </Col>

                                                    <Col hidden={!isVisible(assetType[0].show_header, 16)}>
                                                        <Row form style={{ marginTop: "5px", marginBottom: "5px" }}>
                                                            <Col md={(isDisscusionFormStyle ? "3" : "4")}>
                                                                <Label>{"Status"}</Label>
                                                            </Col>

                                                            <Col md={(isDisscusionFormStyle ? "9" : "8")}
                                                                hidden={isAllow(assetType[0].form_options, "4,8")}>

                                                                <DropDownListComponent
                                                                    dataSource={assetStatus}
                                                                    fields={{ text: 'label', value: 'value' }}
                                                                    allowFiltering={true}
                                                                    className={("e-input")}
                                                                    enabled={!isReadOnly} //isAllow(assetType[0].form_options, "4,8")}
                                                                    //enabled={false}

                                                                    value={(selAssetStatus !== undefined && selAssetStatus !== null
                                                                        && selAssetStatus.length > 0 ? selAssetStatus[0].value : "")}
                                                                    // value={get_value_List_meta_value(config_meta.meta_id,
                                                                    //     config_meta.value_list_data,
                                                                    //     config_meta.parent_meta_id)}
                                                                    change={(e) => onTextChange("asset_status", e.itemData, e.itemData)}>

                                                                </DropDownListComponent>

                                                            </Col>
                                                            <Col md={(isDisscusionFormStyle ? "9" : "8")} hidden={!isAllow(assetType[0].form_options, "4,8")}>
                                                                <Input type='text'
                                                                    readOnly={true}
                                                                    className={("e-input")}
                                                                    style={{ backgroundColor: "transparent", color: 'grey' }}
                                                                    defaultValue={assetDetails[0].status_id}
                                                                >
                                                                </Input>
                                                            </Col>
                                                        </Row>

                                                    </Col>
                                                </Row>
                                            </Col>
                                            <Col md={(isDisscusionFormStyle ? "12" : "")}>
                                                <Row form xs="1">
                                                    <Col hidden={!isVisible(assetType[0].show_header, 4)}>
                                                        <Row form>
                                                            <Col md={(isDisscusionFormStyle ? "3" : "4")}>
                                                                <Label>{"Created By"}</Label>
                                                            </Col>
                                                            <Col md={(isDisscusionFormStyle ? "5" : "4")}>
                                                                <Input type="text"
                                                                    className='e-input'
                                                                    style={{ backgroundColor: "transparent", color: 'grey' }}
                                                                    name={"created_by"}
                                                                    id={"created_by"}
                                                                    readOnly={true}
                                                                    value={assetDetails[0].created_by}
                                                                    defaultValue={assetDetails[0].created_by}
                                                                    placeholder={"Create By"} />
                                                            </Col>
                                                            <Col md={(isDisscusionFormStyle ? "4" : "4")}>
                                                                <DatePickerComponent
                                                                    value={assetDetails[0].created_at}
                                                                    readOnly={true} />
                                                            </Col>
                                                        </Row>
                                                    </Col>
                                                    <Col hidden={!isVisible(assetType[0].show_header, 32)}>
                                                        <Row form style={{ marginTop: "5px", marginBottom: "5px" }}>
                                                            <Col md={(isDisscusionFormStyle ? "3" : "4")}>
                                                                <Label>{"Modified By"}</Label>
                                                            </Col>
                                                            <Col md={(isDisscusionFormStyle ? "5" : "4")}>
                                                                <Input className='e-input'
                                                                    style={{ backgroundColor: "transparent", color: 'grey' }}
                                                                    type="text"
                                                                    name={"modified_by"}
                                                                    id={"modified_by"}
                                                                    readOnly={true}
                                                                    value={assetDetails[0].modified_by}
                                                                    defaultValue={assetDetails[0].modified_by}
                                                                    placeholder={"Modify By"} />
                                                            </Col>
                                                            <Col md={(isDisscusionFormStyle ? "4" : "4")}>

                                                                <DatePickerComponent
                                                                    value={assetDetails[0].created_at}
                                                                    readOnly={true}
                                                                />

                                                                {/* <DatePicker
                                                className='e-input'
                                                    value={assetDetails[0].created_at}
                                                    disabled /> */}
                                                            </Col>
                                                        </Row>
                                                    </Col>

                                                </Row>

                                            </Col>


                                        </Row>

                                    </FormGroup >


                                    <FormGroup style={{ marginTop: "10px" }}>


                                        <AssetSection asset_config={e.asset_config}
                                            company_id={companyID}
                                            asset_detail={assetDetails[0]}
                                            user={props.user}
                                            onTextChange={onTextChange}
                                            onAssetMetaUpdate={onAssetMetaUpdate}
                                            onValueListChange={onValueListChange}
                                            key={reloadForm}
                                            isReadOnly={isReadOnly}
                                            assetType={assetType}
                                            isDisscusionFormStyle={isDisscusionFormStyle}
                                            toggle={props.toggle}
                                            isPrintMode={isPrintMode}
                                        />
                                    </FormGroup>

                                </Col>
                                <Col md={(isDisscusionFormStyle ? 5 : 0)} hidden={!isDisscusionFormStyle}>
                                    <Media>
                                        <Media body>
                                            <Media heading>
                                                {"Discussion"}
                                            </Media >
                                            <AssetDiscussion asset_detail={assetDetails[0]}
                                                user={props.user}>
                                            </AssetDiscussion>
                                        </Media>
                                    </Media >

                                </Col>
                            </Row>
                        </div>
                    </FormGroup>
                    < div >
                        <Modal isOpen={modalMsg}
                            toggle={() => setModalMsg(!modalMsg)}
                            size="lg">
                            <ModalHeader toggle={() => setModalMsg(!modalMsg)}>{msgHeader}
                            </ModalHeader>
                            <ModalBody>
                                {msgBody}
                            </ModalBody>
                            <ModalFooter>
                                <Button
                                    color="primary"
                                    onClick={() => setModalMsg(!modalMsg)}
                                >
                                    OK
                                </Button>
                                {' '}

                            </ModalFooter>
                        </Modal>
                    </div >

                </div >


            )
        })

    )




}

export default AssetDetails;

